import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { CdkDrag, CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProjectTaskDetailComponent } from '../../../../project-task-detail/project-task-detail.component';
import { ProjectTask } from 'src/app/models/project_task.model';
import { ProjectPhaseBoardCol } from 'src/app/models/project_phase_board_col.model';
import { ProjectPhaseBoardColRoleDialogComponent } from './project-phase-board-col-role-dialog/project-phase-board-col-role-dialog.component';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { ProjectPhaseBoard } from 'src/app/models/project_phase_board.model';
import { ProjectPhase } from 'src/app/models/project_phase.model';
import { ProjectTaskBoard } from 'src/app/models/project_task_board.model';
import { ProjectTaskBoardTimeBudget } from 'src/app/models/project_task_board_time_budget.model';
import { TaskFromTodoToProgressDialogComponent } from '../../../../project-phase-board-column-changes/task-from-todo-to-progress-dialog/task-from-todo-to-progress-dialog.component';
import { TaskToDeadlockDialogComponent } from '../../../../project-phase-board-column-changes/task-to-deadlock-dialog/task-to-deadlock-dialog.component';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';


export interface Columns {
  column: ProjectPhaseBoardCol,
  project_task_boards:ProjectTaskBoardCustom[]
}


@Component({
  selector: 'app-project-phase-boards',
  templateUrl: './project-phase-boards.component.html',
  styleUrls: ['./project-phase-boards.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class ProjectPhaseBoardsComponent implements OnInit {

  creatingColumn: boolean = false;
  creatingTask: boolean = false;
  creatingTaskColumnId:any = null;
  creatingTaskSelectedType:string = 'task';
  creatingTaskIsHotfix:boolean = false;
  creatingTaskNeedsTesting:boolean = false;
  creatingTaskName:string = '';
  hoveringColumnId:any = null;
  hoveringTaskId:any = null;
  newColumnName: string = '';
  dialogAlreadyOpened:boolean = false;

  mode:'all'|'assigned'|'listening' = 'all';

  data:{project_phase_board:ProjectPhaseBoard, columns: Columns[]};
  columnNames:string[] = [];

  editingColumnTitleId:any = null;
  editingColumnTitle:any = null;
  user:User = null as any;
  phase:ProjectPhase = null as any;

  private board_id:number;
  private phase_id:number;
  private project_id:number;

  private show_archived_tasks:boolean = false;
  isProjectManagerOrProductManager:boolean = false;
  isWorker:boolean = false;
  isClientDeveloper:boolean = false;
  imDeveloping: boolean = false;

  private withString:string = 'project_task..tags,project_task..project_task_listeners,project_task_board_time_budgets..budgetable,project_task_board_time_budgets..working_times,project_task_board_time_budgets..assignee,active_project_task_board_time_budget..assignee,active_project_task_board_time_budget..budgetable,active_project_task_board_time_budget..working_times';

  //Autofocus on new task when creating
  @ViewChild('creatingTaskInput', { static: false })
  set creatingTaskInput(element: ElementRef<HTMLTextAreaElement>) {
    if(element) {
      element.nativeElement.focus()
    }
  }

  //Close creating task on press ESC
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeCreateTask();
  }

  constructor(private activatedRoute:ActivatedRoute,
    private router:Router,
    private dialog: MatDialog,
    private api:ApiService,
    private permissions:PermissionsService,
    private userService:UserService
  ) {
    this.canMoveColumns = this.canMoveColumns.bind(this);
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(routeParams => {
      this.initRouteParameters();
      this.fetchPhase();
      this.fetchBoard();
    });

    this.activatedRoute.queryParams.subscribe(params => {
      const old_mode = this.mode;
      this.mode = (this.activatedRoute.snapshot.queryParamMap.get('mode')!=null ? this.activatedRoute.snapshot.queryParamMap.get('mode') ?? 'all' : 'all') as 'all'|'assigned'|'listening';

      if(this.mode != old_mode) {
        this.fetchBoard();
      }
    });

    this.listenQueryParameters();
    this.initIsPMorPO();
    this.listenUser();
  }

  private initRouteParameters() {
    this.board_id = this.activatedRoute.snapshot.paramMap.get('board_id')!=null ? +(this.activatedRoute.snapshot.paramMap.get('board_id') as string) : null as any;
    this.project_id = this.activatedRoute.snapshot.parent?.paramMap.get('project_id')!=null ? +(this.activatedRoute.snapshot.parent?.paramMap.get('project_id') as string) : (this.activatedRoute.snapshot.parent?.parent?.paramMap.get('project_id')!=null ? this.activatedRoute.snapshot.parent?.parent?.paramMap.get('project_id') : null as any);
    this.phase_id = this.activatedRoute.snapshot.parent?.paramMap.get('phase_id')!=null ? +(this.activatedRoute.snapshot.parent?.paramMap.get('phase_id') as string) : null as any;
  }

  dropGridOnColumnOrderChange(event: CdkDragDrop<string[]>): void {
    if(event.previousIndex != event.currentIndex) {
      moveItemInArray(this.data.columns, event.previousIndex, event.currentIndex);
      this.changeColumnOrder(this.data.columns);
    }
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(params.show_archived_tasks != null && (params.show_archived_tasks == 'true') != this.show_archived_tasks) {
          this.show_archived_tasks = params.show_archived_tasks == 'true';
          this.fetchBoard();
        }
      }
    );
  }

  canMoveColumns(item: CdkDrag, list: CdkDropList): boolean {
    return this.isProjectManagerOrProductManager;
  }

  editColumn(column:any) {
    if(this.isProjectManagerOrProductManager) {
      this.editingColumnTitleId = column.column.id;
      this.editingColumnTitle = column.column.title
    }
  }

  isWorkingTimeCategoryUnderReview(time_budget:ProjectTaskBoardTimeBudgetCustom): boolean {
    return time_budget.budgetable_type === "App\\Models\\WorkingTimeCategory" && (time_budget.budgetable as WorkingTimeCategory).slug === "review-task";
  }

  async dropTask(event: CdkDragDrop<ProjectTaskBoardCustom[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      this.changeColumnTasksOrder(event.container);
    } else {
      const new_column_id = +(event.container.element.nativeElement.getAttribute('data-column-id') as string);
      const old_column_id = +(event.previousContainer.element.nativeElement.getAttribute('data-column-id') as string);
      const project_task_board_id = +(event.item.element.nativeElement.getAttribute('data-project-task-board-id') as string);
      const project_task_id = event.item.element.nativeElement.getAttribute('data-project-task-id');
      const newIndex = event.currentIndex;

      const colDestination:ProjectPhaseBoardCol = this.data.columns.find(col => col.column.id == new_column_id)!.column;
      const colOrigin:ProjectPhaseBoardCol = this.data.columns.find(col => col.column.id == old_column_id)!.column;
      const project_task_board:ProjectTaskBoard = this.findProjectTaskBoardOnStructure(project_task_board_id) as ProjectTaskBoard;

      if(project_task_board_id != null && project_task_id != null) {
        const valid:boolean = ProjectPhaseBoardsComponent.checkAndAskIfTaskMoveIsValid(project_task_board, colOrigin, colDestination);
        if(valid) {
          transferArrayItem(event.previousContainer.data,event.container.data,event.previousIndex,event.currentIndex);
          this.moveTaskFromColumnToColumn(+project_task_board_id, colDestination, colOrigin, newIndex, +project_task_id);
        }
      }
    }

  }

  private fetchBoard() {
    let params:any = {
      with: this.withString
    };
    if (!this.show_archived_tasks) {
      params['project_task-is_archived'] = this.show_archived_tasks.toString();
    }
    this.api.getProjectPhaseBoardStructure(this.board_id, params).subscribe(
      data => {
        this.data = data as {project_phase_board:ProjectPhaseBoard, columns: {column: ProjectPhaseBoardCol, project_task_boards:ProjectTaskBoardCustom[]}[]};
        this.data.columns.forEach(column => {
          column.project_task_boards = column.project_task_boards.filter(ptb => this.filterByMode(ptb)).map(ptb => this.projectTaskBoardToCustom(ptb));
        });
        this.columnNames = data.columns.map((column:any) => column.column.title);
        this.checkOpenedTaskInParams();
      }
    );
  }

  private changeColumnOrder(columns:Columns[]) {
    const columnsOrder = columns.map((column:any) => column.column.id);
    this.api.changeBoardColumnsOrder(this.board_id, columnsOrder).subscribe();
  }

  private changeColumnTasksOrder(column:any) {
    const tasksOrder = column.data.map((task:any) => task.id);
    this.api.changeBoardColumnTasksOrder(this.board_id, tasksOrder).subscribe();
  }

  private moveTaskFromColumnToColumn(project_task_board_id:number, colDestination:ProjectPhaseBoardCol, colOrigin:ProjectPhaseBoardCol, newIndex:number, project_task_id:number) {
    this.api.moveTaskFromColumnToColumn(this.board_id, project_task_board_id, colDestination, newIndex, { with: this.withString }).subscribe(
      data => {
        for(let column of this.data.columns) {
          for(let ptb of column.project_task_boards) {
            if(ptb.id == project_task_board_id) {
              // active
              ptb = this.projectTaskBoardToCustom(data);
            }
          }
        }
      }
    );

    const dialogRef = ProjectPhaseBoardsComponent.handleTaskFromColumnToColumnDialogs(this.dialog, project_task_board_id, colOrigin, colDestination, this.project_id, project_task_id);

    if(dialogRef) {
      dialogRef.afterClosed().subscribe(
        data => {
          if(data) {
            this.fetchBoard();
          }
        }
      );
    }
  }

  createColumn() {
    this.creatingColumn = true;
    const column = {
      title: this.newColumnName,
      project_phase_board_id: this.board_id
    }
    this.api.createBoardColumn(column).subscribe(
      data => {
        this.creatingColumn = false;
        this.newColumnName = '';
        this.fetchBoard();
      }
    )
  }

  deleteColumn(column:any) {
    if(confirm(`¿Estás seguro que quieres eliminar la columna "${column.title}"?`)) {
      this.api.deleteBoardColumn(column.id).subscribe(
        data => {
          this.fetchBoard();
        }
      );
    }
  }

  archiveTasksFromColumn(column:any) {
    const new_status_archived:boolean = true;
    if(confirm(`¿Estás seguro que quieres archivar todas las tareas de la columna "${column.title}"?`)) {
      this.api.archiveTasks({ project_phase_board_col_id: column.id, new_status_archived: new_status_archived }).subscribe(
        data => {
          this.fetchBoard();
        }
      );
    }
  }

  deleteTask(task:ProjectTaskBoardCustom) {
    if(confirm(`¿Estás seguro que quieres eliminar la tarea "${task.project_task.title}" de este tablero?`)) {
      this.api.deleteProjectTaskBoard(task.id).subscribe(
        data => {
          this.fetchBoard();
        }
      );
    }
  }

  editColumnName() {
    if(this.editingColumnTitle.length >= 3){
      const column = {
        title: this.editingColumnTitle,
        id: this.editingColumnTitleId
      }
      this.api.updateBoardColumn(column).subscribe(
        data => {
          this.editingColumnTitleId = null;
          this.editingColumnTitle = '';
          this.fetchBoard();
        }
      );
    }
  }

  initCreateTask(column:any) {
    this.creatingTask = true;
    this.creatingTaskColumnId = column.id;
  }

  closeCreateTask() {
    this.creatingTask = false;
    this.creatingTaskColumnId = null;
    this.creatingTaskSelectedType = 'task';
    this.creatingTaskIsHotfix = false;
    this.creatingTaskNeedsTesting = false;
    this.creatingTaskName = '';
  }

  createTask() {
    //Manage the case when the createTask is executed with the enter from the textarea
    if(this.creatingTaskName.length > 2) {
      const task = {
        title: this.creatingTaskName,
        project_phase_board_col_id: this.creatingTaskColumnId,
        type: this.creatingTaskSelectedType,
        is_hotfix: this.creatingTaskIsHotfix,
        needs_testing: this.creatingTaskNeedsTesting,
        priority: 'medium',
        project_phase_id: this.phase_id
      }
      this.api.createTask(task).subscribe(
        data => {
          this.creatingTask = false;
          this.creatingTaskName = '';
          this.creatingTaskColumnId = null;
          this.fetchBoard();
        }
      );
    }
  }

  private checkOpenedTaskInParams() {
    this.activatedRoute.queryParamMap.subscribe(queryParams => {
      if(queryParams.get('selectedIssue')!=null) {
        const selectedIssue = queryParams.get('selectedIssue') as string;
        this.openTask(selectedIssue);
      }
    });
  }

  displayTaskDetail($event:any, task:ProjectTaskBoard) {
    $event.preventDefault();
    const eventId = this.getAttributeIdFromEvent($event);
    if(eventId != 'task-menu' && eventId != 'task-menu-icon') {
      const queryParams: Params = { selectedIssue: task.project_task.code };
      this.router.navigate([],
      {
        relativeTo: this.activatedRoute,
        queryParams: queryParams,
        queryParamsHandling: 'merge', // remove to replace all query params by provided
      });

      this.openTask(task.project_task.code);
    }
  }

  private async openTask(task_code:string) {
    if(!this.dialogAlreadyOpened) {
      this.dialogAlreadyOpened = true;
      const dialogRef = this.dialog.open(ProjectTaskDetailComponent, {
        width: '1150px',
        maxHeight: '90vh',
        autoFocus: false,
        data: {task_code_id: task_code, project_phase_board_id: this.board_id, project_id: this.project_id}
      });

      const returned_value_from_promise = await dialogRef.afterClosed().toPromise();
      this.dialogAlreadyOpened = false;

      const current_task_board = this.findProjectTaskBoardOnStructure(task_code);

      if(current_task_board != undefined) {
        const updated_task:ProjectTaskBoard|undefined = await this.api.getProjectTaskBoard(current_task_board.id, { with: this.withString }).toPromise();
        if(updated_task) {
          this.findAndUpdateProjectTaskBoardOnStructure(this.projectTaskBoardToCustom(updated_task));
        }
        else this.fetchBoard();
      }
      else this.fetchBoard();

      this.router.navigate([], {
        queryParams: {
          'selectedIssue': null,
        },
        queryParamsHandling: 'merge'
      });

    }
  }

  private getAttributeIdFromEvent(event:any) {
    var target = event.target || event.srcElement || event.currentTarget;
    var idAttr = target?.attributes?.id;
    var value = idAttr ? idAttr.nodeValue : null;
    return value;
  }

  allTasksArchived(column:any) {
    return column.project_tasks.filter((task:any) => !task.is_archived).length == 0;
  }

  archiveTask(task: ProjectTaskBoard) {
    this.api.archiveTasks({ tasks_ids: [task.project_task.id], new_status_archived: !task.project_task.is_archived }).subscribe(
      data => {
        this.fetchBoard();
      }
    );
  }

  changeRoleColumn(column: ProjectPhaseBoardCol) {
    const dialogRef = this.dialog.open(ProjectPhaseBoardColRoleDialogComponent, {
      width: '650px',
      data: {
        'projectPhaseBoardCol' : column,
        'phase_id': this.phase_id
      }
    });

    dialogRef.afterClosed().toPromise().then(
      data => {
        this.fetchBoard();
      }
    );
  }

  getPtbtbProgressSpinnerValue(ptbtb:ProjectTaskBoardTimeBudgetCustom):number {
    if(ptbtb.time_in_seconds != null && ptbtb.time_in_seconds >= 0) {
      const prcnt = ptbtb.ro_time_registered / ptbtb.time_in_seconds;
      if(prcnt > 1) return 100;
      else return prcnt * 100;
    }
    else return 100;
  }

  getPtbtbProgressSpinnerClass(ptbtb:ProjectTaskBoardTimeBudgetCustom, ptb:ProjectTaskBoardCustom):any {
    const completed:boolean = ptbtb.completed_by_user_id != null;
    const active:boolean = ptb.active_project_task_board_time_budget_id != null && ptb.active_project_task_board_time_budget_id == ptbtb.id;

    return {
      disabled: !completed && !active,
      inprogress: !completed && active,
      finished: completed && (ptbtb.time_in_seconds == null || ptbtb.ro_time_registered <= ptbtb.time_in_seconds),
      overtime: completed && ptbtb.time_in_seconds != null && ptbtb.ro_time_registered > ptbtb.time_in_seconds
    };
  }

  getPtbProgressSpinnerValue(ptb:ProjectTaskBoardCustom): number {
    if(ptb.ro_time_estimation != null && ptb.ro_time_estimation >= 0) {
      const prcnt = ptb.ro_time_registered / ptb.ro_time_estimation;
      if(prcnt > 1 || prcnt == 0) return 100;
      else return prcnt * 100;
    }
    else return 100;
  }

  getPtbProgressSpinnerClass(ptb:ProjectTaskBoardCustom, column:ProjectPhaseBoardCol): any {
    return {
      disabled: ptb.ro_time_estimation <= 0,
      start: ptb.ro_time_estimation > 0 && ptb.ro_time_registered <= ptb.ro_time_estimation && (column.project_phase_board_col_role == null || !['test', 'done'].includes(column.project_phase_board_col_role.slug)) && ptb.ro_time_registered == 0,
      inprogress: ptb.ro_time_estimation > 0 && ptb.ro_time_registered <= ptb.ro_time_estimation && (column.project_phase_board_col_role == null || !['test', 'done'].includes(column.project_phase_board_col_role.slug)) && ptb.ro_time_registered > 0,
      finished: ptb.ro_time_estimation > 0 && ptb.ro_time_registered <= ptb.ro_time_estimation && (column.project_phase_board_col_role != null && ['test', 'done'].includes(column.project_phase_board_col_role.slug)),
      overtime: ptb.ro_time_estimation > 0 && ptb.ro_time_registered > ptb.ro_time_estimation
    };
  }

  private initIsPMorPO() {
    this.isProjectManagerOrProductManager = this.permissions.isWorkerWithProjectRole(this.project_id.toString(), 'tech-lead') || this.permissions.isWorkerWithProjectRole(this.project_id.toString(), 'product-manager');
    this.isWorker = this.permissions.isWorker();
    this.imDeveloping = this.permissions.isProductionTeam(this.project_id.toString());
    this.isClientDeveloper = this.permissions.isClientDeveloper();
  }

  private listenUser() {
    this.userService.currentUser.subscribe(
      user => {
        this.user = user;
      }
    );
  }

  private fetchPhase() {
    this.api.getProjectPhase(this.phase_id).subscribe(
      data => {
        this.phase = data;
      }
    );
  }

  private projectTaskBoardToCustom(project_task_board:ProjectTaskBoard): ProjectTaskBoardCustom {
    const ptb = { ...project_task_board } as ProjectTaskBoardCustom;
    ptb.ro_time_registered = 0;
    ptb.project_task_board_time_budgets.forEach(ptbtb => {
      ptbtb.ro_time_registered = 0;
      ptbtb.working_times.forEach(wt => {
        ptb.ro_time_registered += wt.duration_in_seconds_for_client;
        ptbtb.ro_time_registered += wt.duration_in_seconds_for_client;
      });
    });
    if(ptb.active_project_task_board_time_budget != null) {
      ptb.active_project_task_board_time_budget.ro_time_registered = 0;
      ptb.active_project_task_board_time_budget.working_times.forEach(wt => {
        ptb.active_project_task_board_time_budget!.ro_time_registered += wt.duration_in_seconds_for_client;
      });
    }
    return ptb;
  }

  public static checkAndAskIfTaskMoveIsValid(project_task_board: ProjectTaskBoard, colOrigin:ProjectPhaseBoardCol, colDestination:ProjectPhaseBoardCol): boolean {
    if(colOrigin.project_phase_board_col_role != null && colDestination.project_phase_board_col_role != null && colOrigin.id != colDestination.id) {
      if(colOrigin.project_phase_board_col_role.slug === 'under-review' && ['test', 'done'].includes(colDestination.project_phase_board_col_role.slug)) {
        // si s'ha acceptat i es vol donar per finalitzada...

        // 1. Mirem a veure si té budgets pendents
        const budgets_pending:ProjectTaskBoardTimeBudget[] = project_task_board.project_task_board_time_budgets.filter(
          time_budget =>  time_budget.completed_by_user_id == null && // no ha d'estar completat
                          (project_task_board.active_project_task_board_time_budget_id == null || time_budget.id != project_task_board.active_project_task_board_time_budget_id) && // no ha de ser l'actiu que marcarem com a completat a continuació
                          (time_budget.budgetable_type==="App\\Models\\ProjectTeam" || (time_budget.budgetable as WorkingTimeCategory).slug != "review-task")
        );

        if(budgets_pending.length) return confirm(`Aún queda trabajo por realizar en este sprint de "${budgets_pending.map(b => b.budgetable.name).join(", ")}" que no se ha marcado como completado. ¿Estás seguro que quieres mover la tarea a "${colDestination.title}"?`);
      }
      else if(colOrigin.project_phase_board_col_role.slug === 'under-review' && colDestination.project_phase_board_col_role.slug === 'in-progress') {
        return confirm("¿Quieres rechazar la tarea?");
      }
      else if(colOrigin.project_phase_board_col_role.slug === 'under-review' && colDestination.project_phase_board_col_role.slug === 'todo') {
        return confirm("¿Quieres marcar la tarea como aceptada pero que aun le falta trabajo por hacer?");
      }
    }

    return true;
  }

  public static handleTaskFromColumnToColumnDialogs(dialog:MatDialog, project_task_board_id:number, colOrigin:ProjectPhaseBoardCol, colDestination:ProjectPhaseBoardCol, project_id:number, project_task_id: number):MatDialogRef<any,any>|undefined {
    if(colOrigin.project_phase_board_col_role != null && colDestination.project_phase_board_col_role != null && colOrigin.id != colDestination.id) {
      if(['todo', 'test', 'done'].includes(colOrigin.project_phase_board_col_role.slug) && colDestination.project_phase_board_col_role.slug === 'in-progress') {
        // ha mogut de todo a progress
        return dialog.open(TaskFromTodoToProgressDialogComponent, {
          data: { project_task_board_id: project_task_board_id, project_id: project_id },
          disableClose: true,
          width: '400px'
        });
      }
      else if(colOrigin.project_phase_board_col_role.slug === 'in-progress' && colDestination.project_phase_board_col_role.slug === 'todo') {
        // torna de in progress a to do

      }
      else if(colDestination.project_phase_board_col_role.slug === 'deadlock') {
        // l'ha posat en deadlock
        return dialog.open(TaskToDeadlockDialogComponent, {
          data: { project_task_board_id: project_task_board_id, project_id: project_id, project_task_id: project_task_id, direction: 'in' },
          disableClose: true,
          width: '400px'
        });
      }
      else if(colOrigin.project_phase_board_col_role.slug === 'deadlock' && ['in-progress', 'todo', 'under-review'].includes(colDestination.project_phase_board_col_role.slug)) {
        // l'ha tret de deadlock
        return dialog.open(TaskToDeadlockDialogComponent, {
          data: { project_task_board_id: project_task_board_id, project_id: project_id, project_task_id: project_task_id, direction: 'out' },
          disableClose: true,
          width: '400px'
        });
      }
      else if(colOrigin.project_phase_board_col_role.slug === 'in-progress' && colDestination.project_phase_board_col_role.slug === 'under-review') {
        // l'ha posat en under review

      }
      else if(colOrigin.project_phase_board_col_role.slug === 'under-review' && colDestination.project_phase_board_col_role.slug === 'in-progress') {
        // l'ha rechazat

      }
      else if(colOrigin.project_phase_board_col_role.slug === 'under-review' && colDestination.project_phase_board_col_role.slug === 'todo') {
        // l'ha acceptat pero queda feina per fer

      }
      else if(colOrigin.project_phase_board_col_role.slug === 'under-review' && ['test', 'done'].includes(colDestination.project_phase_board_col_role.slug)) {
        // s'ha acceptat

      }
      else if(['test', 'done'].includes(colOrigin.project_phase_board_col_role.slug) && colDestination.project_phase_board_col_role.slug === 'in-progress') {
        // estava feta pero la tornem a passar a in progress

      }
      else if(['test', 'done'].includes(colOrigin.project_phase_board_col_role.slug) && colDestination.project_phase_board_col_role.slug === 'todo') {
        // estava feta pero la tornem a passar a to do

      }

      return null as any;
    }

    return undefined;
  }

  private findProjectTaskBoardOnStructure(id_or_code:number|string):ProjectTaskBoardCustom|undefined {
    for(let col of this.data.columns) {
      for(let task of col.project_task_boards) {
        if((typeof id_or_code === 'string' && task.project_task.code === id_or_code) || task.id === id_or_code) {
          return task;
        }
      }
    }

    return undefined;
  }

  private findAndUpdateProjectTaskBoardOnStructure(updated_project_task_board:ProjectTaskBoardCustom): void {
    for(let col_idx in Object.keys(this.data.columns)) {
      let updated:boolean = false;
      for(let task_idx in Object.keys(this.data.columns[col_idx].project_task_boards)) {
        if(this.data.columns[col_idx].project_task_boards[task_idx].id === updated_project_task_board.id) {
          if(this.data.columns[col_idx].project_task_boards[task_idx].project_phase_board_col_id == updated_project_task_board.project_phase_board_col_id) {
            // actualitzar-la
            this.data.columns[col_idx].project_task_boards[task_idx] = updated_project_task_board;
            updated = true;
          }
          else {
            // treure-la
            this.data.columns[col_idx].project_task_boards.splice(+task_idx, 1);
            updated = true;
          }
        }
      }

      if(!updated && this.data.columns[col_idx].column.id === updated_project_task_board.project_phase_board_col_id) {
        // insertar
        this.data.columns[col_idx].project_task_boards.unshift(updated_project_task_board);
      }
    }
  }

  private filterByMode(ptb:ProjectTaskBoard): boolean {
    if(this.mode === "all") return true;
    else if(this.mode === "assigned") {
      return ptb.project_task_board_time_budgets.find(ptbtb => ptbtb.assignee_id == this.user.id) !== undefined;
    }
    else if(this.mode === "listening") {
      return ptb.project_task.project_task_listeners.find(listener => listener.id == this.user.id) !== undefined;
    }
    else return false;
  }
}

interface ProjectTaskBoardCustom extends ProjectTaskBoard {
  ro_time_registered:number;
  project_task_board_time_budgets:ProjectTaskBoardTimeBudgetCustom[];
  active_project_task_board_time_budget:ProjectTaskBoardTimeBudgetCustom|undefined;
}

interface ProjectTaskBoardTimeBudgetCustom extends ProjectTaskBoardTimeBudget {
  ro_time_registered:number;
}
