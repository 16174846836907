<div class="page-title">
  <h1>{{ domain!=null ? domain.domain : 'Cargando...' }}</h1>
  <button mat-flat-button color="accent" (click)="openDetail()" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin', 'project-manager'])">
    <mat-icon matPrefix>add</mat-icon>
    Añadir credencial
  </button>
</div>

<div class="bisual-table">
  <div class="bisual-table-container">
    <table mat-table [dataSource]="dataSource">

      <ng-container matColumnDef="protocol">
        <th mat-header-cell *matHeaderCellDef>Protocolo</th>
        <td mat-cell *matCellDef="let element">{{ element.protocol | uppercase }}</td>
      </ng-container>

      <ng-container matColumnDef="username">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td mat-cell *matCellDef="let element">{{ element.username }}</td>
      </ng-container>

      <ng-container matColumnDef="password">
        <th mat-header-cell *matHeaderCellDef>Contraseña</th>
        <td mat-cell *matCellDef="let element">
          {{ element.password | password }}
          @if (element.password != null) {
            <button mat-icon-button style="margin-left: 10px;" (click)="copyPassword(element)"><mat-icon style="font-size: 18px;">content_copy</mat-icon></button>
          }
        </td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef>Tipo</th>
        <td mat-cell *matCellDef="let element">{{ element.type | uppercase }}</td>
      </ng-container>

      <ng-container matColumnDef="host">
        <th mat-header-cell *matHeaderCellDef>Host</th>
        <td mat-cell *matCellDef="let element">{{ element.host }}</td>
      </ng-container>

      <ng-container matColumnDef="port">
        <th mat-header-cell *matHeaderCellDef>Puerto</th>
        <td mat-cell *matCellDef="let element">{{ element.port }}</td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element; let i = index;">
          <button mat-icon-button [matMenuTriggerFor]="menu">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            @if (element.type == 'ssh' && element.ssh_public_key != null) {
              <button mat-menu-item (click)="downloadPublicKey(element)">
                <mat-icon matPrefix>download</mat-icon>
                Descargar clave pública
              </button>
            }
            @if (element.type == 'ssh' && element.ssh_private_key != null) {
              <button mat-menu-item (click)="downloadPrivateKey(element)">
                <mat-icon matPrefix>vpn_key</mat-icon>
                Descargar clave privada
              </button>
            }
            <button mat-menu-item (click)="openDetail(i)" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
              <mat-icon matPrefix>edit</mat-icon>
              Editar credencial
            </button>
            <button mat-menu-item (click)="delete(i)" [disabled]="!permissions.validateDepartment(['direction', 'sysadmin'])">
              <mat-icon matPrefix>delete</mat-icon>
              Eliminar credencial
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
  </div>
</div>
