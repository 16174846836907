import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeEs from '@angular/common/locales/es';
import localeEsExtra from '@angular/common/locales/extra/es';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './components/auth/auth.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RestorePasswordComponent } from './components/auth/restore-password/restore-password.component';
import { MainComponent } from './components/main/main.component';
import { DashboardComponent } from './components/main/employees/dashboard/dashboard.component';
import { SupportComponent } from './components/main/employees/support/support.component';
import { ProjectsComponent } from './components/main/shared/projects/projects.component';
import { ServicesComponent } from './components/main/employees/project-management/services/services.component';
import { BillingComponent } from './components/main/employees/direction/billing/billing.component';
import { UsersComponent } from './components/main/employees/direction/users-management/users/users.component';
import { ConfigurationComponent } from './components/main/employees/direction/configuration/configuration.component';
import { LegalDocumentationComponent } from './components/main/employees/direction/legal-documentation/legal-documentation.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatTableModule} from '@angular/material/table';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import {MatChipsModule} from '@angular/material/chips';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { LoggedGuard } from './guards/logged.guard';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { SnackbarInterceptor } from './interceptors/snackbar.interceptor';
import { LoadingInterceptor } from './interceptors/loading.interceptor';
import { CurrencyPipe, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import { UsersDetailComponent } from './components/main/employees/direction/users-management/users/users-detail/users-detail.component';
import {MatTabsModule} from '@angular/material/tabs';
import { ChangePasswordDialogComponent } from './components/main/employees/direction/users-management/users/users-detail/change-password-dialog/change-password-dialog.component';
import {MatDialogModule} from '@angular/material/dialog';
import { SafeUrlPipe } from './pipes/safe-url.pipe';
import { AddUserDialogComponent } from './components/main/employees/direction/users-management/users/add-user-dialog/add-user-dialog.component';
import { ProjectDetailComponent } from './components/main/shared/projects/project-detail/project-detail.component';
import { HostingsComponent } from './components/main/employees/project-management/services/hostings/hostings.component';
import { DomainsComponent } from './components/main/employees/project-management/services/domains/domains.component';
import { BusinessMailsComponent } from './components/main/employees/project-management/services/business-mails/business-mails.component';
import { DaysToPeriodPipe } from './pipes/days-to-period.pipe';
import {MatRadioModule} from '@angular/material/radio';
import {MatTooltipModule} from '@angular/material/tooltip';
import { AddHostingDialogComponent } from './components/main/employees/project-management/services/hostings/add-hosting-dialog/add-hosting-dialog.component';
import { HostingDetailComponent } from './components/main/employees/project-management/services/hostings/hosting-detail/hosting-detail.component';
import { HostingDetailResumeComponent } from './components/main/employees/project-management/services/hostings/hosting-detail/hosting-detail-resume/hosting-detail-resume.component';
import { HostingDetailBillingComponent } from './components/main/employees/project-management/services/hostings/hosting-detail/hosting-detail-billing/hosting-detail-billing.component';
import { HostingDetailCredentialsComponent } from './components/main/employees/project-management/services/hostings/hosting-detail/hosting-detail-credentials/hosting-detail-credentials.component';
import { BytesPipe } from './pipes/bytes.pipe';
import { ProviderOrderFilesDialogComponent } from './components/main/employees/direction/billing/provider-order-files-dialog/provider-order-files-dialog.component';
import { PasswordPipe } from './pipes/password.pipe';
import { CredentialDetailDialogComponent } from './components/utils/credential-detail-dialog/credential-detail-dialog.component';
import { HostingDetailEditComponent } from './components/main/employees/project-management/services/hostings/hosting-detail/hosting-detail-edit/hosting-detail-edit.component';
import { AddDomainDialogComponent } from './components/main/employees/project-management/services/domains/add-domain-dialog/add-domain-dialog.component';
import { DomainDetailComponent } from './components/main/employees/project-management/services/domains/domain-detail/domain-detail.component';
import { DomainDetailResumeComponent } from './components/main/employees/project-management/services/domains/domain-detail/domain-detail-resume/domain-detail-resume.component';
import { DomainDetailEditComponent } from './components/main/employees/project-management/services/domains/domain-detail/domain-detail-edit/domain-detail-edit.component';
import { DomainDetailCredentialsComponent } from './components/main/employees/project-management/services/domains/domain-detail/domain-detail-credentials/domain-detail-credentials.component';
import { DomainDetailBillingComponent } from './components/main/employees/project-management/services/domains/domain-detail/domain-detail-billing/domain-detail-billing.component';
import { DomainDetailDnsComponent } from './components/main/employees/project-management/services/domains/domain-detail/domain-detail-dns/domain-detail-dns.component';
import { RegexServiceInitiatedGuard } from './guards/regex-service-initiated.guard';
import { ConcatPipe } from './pipes/concat.pipe';
import { AddBusinessMailDialogComponent } from './components/main/employees/project-management/services/business-mails/add-business-mail-dialog/add-business-mail-dialog.component';
import { BusinessMailDetailComponent } from './components/main/employees/project-management/services/business-mails/business-mail-detail/business-mail-detail.component';
import { BusinessMailDetailResumeComponent } from './components/main/employees/project-management/services/business-mails/business-mail-detail/business-mail-detail-resume/business-mail-detail-resume.component';
import { BusinessMailDetailEditComponent } from './components/main/employees/project-management/services/business-mails/business-mail-detail/business-mail-detail-edit/business-mail-detail-edit.component';
import { BusinessMailDetailCredentialsComponent } from './components/main/employees/project-management/services/business-mails/business-mail-detail/business-mail-detail-credentials/business-mail-detail-credentials.component';
import { BusinessMailDetailBillingComponent } from './components/main/employees/project-management/services/business-mails/business-mail-detail/business-mail-detail-billing/business-mail-detail-billing.component';
import { BusinessMailDetailOvhComponent } from './components/main/employees/project-management/services/business-mails/business-mail-detail/business-mail-detail-ovh/business-mail-detail-ovh.component';
import { ProviderOrderAttachmentsDialogComponent } from './components/utils/provider-order-attachments-dialog/provider-order-attachments-dialog.component';
import { TestComponent } from './components/main/employees/quick-actions/test/test.component';
import { BillingResumeComponent } from './components/main/employees/direction/billing/billing-resume/billing-resume.component';
import { BillingOrdersComponent } from './components/main/employees/direction/billing/billing-orders/billing-orders.component';
import { BillingProviderOrdersComponent } from './components/main/employees/direction/billing/billing-provider-orders/billing-provider-orders.component';
import { BillingContasimpleDashboardComponent } from './components/main/employees/direction/billing/billing-contasimple-dashboard/billing-contasimple-dashboard.component';
import { BillingAlertsComponent } from './components/main/employees/direction/billing/billing-alerts/billing-alerts.component';
import { UsersManagementComponent } from './components/main/employees/direction/users-management/users-management.component';
import { ProvidersComponent } from './components/main/employees/direction/users-management/providers/providers.component';
import { CompaniesComponent } from './components/main/employees/direction/users-management/companies/companies.component';
import { ProviderDetailComponent } from './components/main/employees/direction/users-management/providers/provider-detail/provider-detail.component';
import { CompanyDetailComponent } from './components/main/employees/direction/users-management/companies/company-detail/company-detail.component';
import { AddCompanyDialogComponent } from './components/main/employees/direction/users-management/companies/add-company-dialog/add-company-dialog.component';
import { AddProviderDialogComponent } from './components/main/employees/direction/users-management/providers/add-provider-dialog/add-provider-dialog.component';
import { AddNewOrderDialogComponent } from './components/utils/add-new-order-dialog/add-new-order-dialog.component';
import { ProviderOrderDialogComponent } from './components/utils/provider-order-dialog/provider-order-dialog.component';
import { BillingAlertDetailDialogComponent } from './components/main/employees/direction/billing/billing-alerts/billing-alert-detail-dialog/billing-alert-detail-dialog.component';
import { WorkingTimesResumeComponent } from './components/main/employees/my-zone/working-times-resume/working-times-resume.component';
import { WorkingTimesHistoryComponent } from './components/main/employees/my-zone/working-times-history/working-times-history.component';
import { WorkingTimesCalendarComponent } from './components/main/employees/my-zone/working-times-calendar/working-times-calendar.component';
import { SecondsToStringTimePipe } from './pipes/seconds-to-string-time.pipe';
import { WorkingTimeHistoryDayDetailDialogComponent } from './components/main/employees/my-zone/working-times-history/working-time-history-day-detail-dialog/working-time-history-day-detail-dialog.component';
import { AddNewWorkingTimeDialogComponent } from './components/main/employees/my-zone/working-times-history/add-new-working-time-dialog/add-new-working-time-dialog.component';
import { BlogpostsComponent } from './components/main/employees/marketing/blogposts/blogposts.component';
import { SupportGuard } from './guards/roles/support.guard';
import { ClockRegistersComponent } from './components/main/employees/my-zone/clock-registers/clock-registers.component';
import { MyAbsencesComponent } from './components/main/employees/my-zone/my-absences/my-absences.component';
import { MyPayrollsComponent } from './components/main/employees/my-zone/my-payrolls/my-payrolls.component';
import { AbsencesManagementComponent } from './components/main/employees/rrhh/absences-management/absences-management.component';
import { VacationsComponent } from './components/main/employees/rrhh/absences-management/vacations/vacations.component';
import { MedicalSickLeavesComponent } from './components/main/employees/rrhh/absences-management/medical-sick-leaves/medical-sick-leaves.component';
import { PayrollsComponent } from './components/main/employees/rrhh/payrolls/payrolls.component';
import { TicketsComponent } from './components/main/employees/support/tickets/tickets.component';
import { ProjectManagementComponent } from './components/main/employees/project-management/project-management.component';
import { MarketingComponent } from './components/main/employees/marketing/marketing.component';
import { RrhhComponent } from './components/main/employees/rrhh/rrhh.component';
import { DirectionComponent } from './components/main/employees/direction/direction.component';
import { ProductionTeamGuard } from './guards/roles/production_team.guard';
import { DirectionGuard } from './guards/roles/direction.guard';
import { MarketingGuard } from './guards/roles/marketing.guard';
import { RrhhGuard } from './guards/roles/rrhh.guard';
import { VacationRequestsComponent } from './components/main/employees/rrhh/absences-management/vacation-requests/vacation-requests.component';
import { ClockRegistersManagementComponent } from './components/main/employees/rrhh/clock-registers-management/clock-registers-management.component';
import { WorkersComponent } from './components/main/employees/rrhh/workers/workers.component';
import { DepartmentsInitiatedGuard } from './guards/departments-initiated.guard';
import { ManageVacationRequestComponent } from './components/main/employees/rrhh/absences-management/vacation-requests/manage-vacation-request/manage-vacation-request.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ModalMyAbsencesComponent } from './components/main/employees/my-zone/my-absences/modal-my-absences/modal-my-absences.component';
import { ProjectResumeComponent } from './components/main/shared/projects/project-detail/project-resume/project-resume.component';
import { ProjectReportDialogComponent } from './components/main/shared/projects/project-detail/project-resume/project-report-dialog/project-report-dialog.component';
import { HourDecimalToHoursMinutesPipe } from './pipes/hour-decimal-to-hours-minutes.pipe';
import { AddNewClockRegisterReviewRequestDialogComponent } from './components/main/employees/my-zone/clock-registers/add-new-clock-register-review-request-dialog/add-new-clock-register-review-request-dialog.component';
import { ForceClockRegisterDialogComponent } from './components/main/employees/main-dialogs/force-clock-register-dialog/force-clock-register-dialog.component';
import { ClockRegisterReviewRequestsComponent } from './components/main/employees/rrhh/clock-registers-management/clock-register-review-requests/clock-register-review-requests.component';
import { ClockRegisterResumeComponent } from './components/main/employees/rrhh/clock-registers-management/clock-register-resume/clock-register-resume.component';
import { ClockRegistersManagementTableDetailDialogComponent } from './components/main/employees/rrhh/clock-registers-management/clock-registers-management-table-detail-dialog/clock-registers-management-table-detail-dialog.component';
import { DepartmentsComponent } from './components/main/employees/rrhh/departments/departments.component';
import { DepartmentDetailComponent } from './components/main/employees/rrhh/departments/department-detail/department-detail.component';
import { DepartmentCategoryDetailComponent } from './components/main/employees/rrhh/departments/department-detail/department-category-detail/department-category-detail.component';
import { AddDepartmentDialogComponent } from './components/main/employees/rrhh/departments/add-department-dialog/add-department-dialog.component';
import { AddDepartmentCategoryDialogComponent } from './components/main/employees/rrhh/departments/department-detail/add-department-category-dialog/add-department-category-dialog.component';
import { SecondsToHourStringPipe } from './pipes/seconds-to-hour-string.pipe';
import { CalendarDateToCustomFormatPipe } from './pipes/calendar-date-to-custom-formt.pipe';
import { WorkingTimesCalendarDialogComponent } from './components/main/employees/my-zone/working-times-calendar/working-times-calendar-dialog/working-times-calendar-dialog.component';
import { ClipboardModule } from 'ngx-clipboard';
import { WorkersPlanningComponent } from './components/main/employees/rrhh/workers-planning/workers-planning.component';
import { EmployeeMonthWorkingCapacityFieldPipe } from './pipes/employee-month-working-capacity-field.pipe';
import { PlanningRoadmapComponent } from './components/main/employees/project-management/planning-roadmap/planning-roadmap.component';
import { LateralMenuEmployeeComponent } from './components/main/employees/lateral-menu-employee/lateral-menu-employee.component';
import { LateralMenuClientComponent } from './components/main/clients/lateral-menu-client/lateral-menu-client.component';
import { TopMenuClientComponent } from './components/main/clients/top-menu-client/top-menu-client.component';
import { TopMenuEmployeeComponent } from './components/main/employees/top-menu-employee/top-menu-employee.component';
import { EmployeeGuard } from './guards/employee.guard';
import { ClientsGuard } from './guards/clients.guard';
import { ProjectPhasesComponent } from './components/main/shared/projects/project-detail/project-phases/project-phases.component';
import { ContractToStatePipe } from './pipes/contract-to-state.pipe';
import { AddContractToRoadmapDialogComponent } from './components/main/employees/project-management/planning-roadmap/add-contract-to-roadmap-dialog/add-contract-to-roadmap-dialog.component';
import { ProjectAssignmentComponent } from './components/main/employees/project-management/project-assignment/project-assignment.component';
import { VacationsAndHolidaysDialogComponent } from './components/main/employees/rrhh/workers-planning/vacations-and-holidays-dialog/vacations-and-holidays-dialog.component';
import { NonProductiveHoursPlanningDialogComponent } from './components/main/employees/rrhh/workers-planning/non-productive-hours-planning-dialog/non-productive-hours-planning-dialog.component';
import { CalendarAssignmentDialogComponent } from './components/main/employees/project-management/project-assignment/calendar-assignment-dialog/calendar-assignment-dialog.component';
import { ProjectPhasesDetailComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-detail.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { ProjectPhaseBacklogComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-backlog/project-phase-backlog.component';
import { ProjectPhaseBoardsComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-boards/project-phase-boards.component';
import { SecondsToHourWithDecimalPipe } from './pipes/seconds-to-hour-with-decimal.pipe';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatCardModule } from '@angular/material/card';
import { ProjectTaskDetailComponent } from './components/main/shared/projects/project-task-detail/project-task-detail.component';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { StripHtmlPipePipe } from './pipes/strip-html.pipe';
import { ProjectTaskHistoryLogFieldPipePipe } from './pipes/project-task-history-log-field.pipe';
import { ProjectTaskPriorityFieldPipePipe } from './pipes/project-task-priority-field.pipe';
import { NgxDropzoneModule } from 'ngx-dropzone';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { BillingPlanningComponent } from './components/main/employees/direction/billing/billing-planning/billing-planning.component';
import { AddBillingPlanningConceptDialogComponent } from './components/main/employees/direction/billing/billing-planning/add-billing-planning-concept-dialog/add-billing-planning-concept-dialog.component';
import { ProjectTaskTypeFieldPipePipe } from './pipes/project-task-type-field.pipe';
import { EditProjectPhaseBoardDialogComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-boards/edit-project-phase-board-dialog/edit-project-phase-board-dialog.component';
import { CompleteProjectPhaseBoardDialogComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-boards/complete-project-phase-board-dialog/complete-project-phase-board-dialog.component';
import { PotentialClientsComponent } from './components/main/employees/comercial/potential-clients/potential-clients.component';
import { PotentialClientsStatusChangesComponent } from './components/main/employees/comercial/potential-clients-status-changes/potential-clients-status-changes.component';
import { ComercialComponent } from './components/main/employees/comercial/comercial.component';
import { ComercialGuard } from './guards/roles/comercial.guard';
import { AddPotentialClientDialogComponent } from './components/main/employees/comercial/potential-clients/add-potential-client-dialog/add-potential-client-dialog.component';
import { PotentialClientDetailComponent } from './components/main/employees/comercial/potential-clients/potential-client-detail/potential-client-detail.component';
import { PotentialClientChangeStatusDialogComponent } from './components/main/employees/comercial/potential-clients/potential-client-detail/potential-client-change-status-dialog/potential-client-change-status-dialog.component';
import { PotentialClientMeetingRecordComponent } from './components/main/employees/comercial/potential-clients/potential-client-detail/potential-client-meeting-record/potential-client-meeting-record.component';
import { CommercialDashboardComponent } from './components/main/employees/comercial/commercial-dashboard/commercial-dashboard.component';
import { BisualSettingSectionKeyToTitlePipe } from './pipes/bisual-setting-section-key-to-title.pipe';
import { ProjectTaskTypeImagePipe } from './pipes/project-task-type-image.pipe';
import { PriorityImgPipe } from './pipes/priority-img.pipe';
import { ImportFileDialogComponent } from './components/utils/import-file-dialog/import-file-dialog.component';
import { HourStringToSecondsPipe } from './pipes/hour-string-to-seconds.pipe';
import { ProjectPhaseEditDialogComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-edit-dialog/project-phase-edit-dialog.component';
import { ProjectManageMembersDialogComponent } from './components/main/shared/projects/project-detail/project-resume/project-manage-members-dialog/project-manage-members-dialog.component';
import { ProgressBarCardComponent } from './components/utils/progress-bar-card/progress-bar-card.component';
import { GeneralSearchDialogComponent } from './components/utils/general-search-dialog/general-search-dialog.component';
import { WikiComponent } from './components/main/employees/wiki/wiki.component';
import { WikiNodeComponent } from './components/main/employees/wiki/wiki-node/wiki-node.component';
import { WikiArticleComponent } from './components/main/employees/wiki/wiki-article/wiki-article.component';
import { MoveWikiItemDialogComponent } from './components/utils/move-wiki-item-dialog/move-wiki-item-dialog.component';
import { MatTreeModule } from '@angular/material/tree';
import { MeetingRecordComponent } from './components/main/shared/meeting-record/meeting-record.component';
import { ProjectMeetingRecordsComponent } from './components/main/shared/projects/project-detail/project-meeting-records/project-meeting-records.component';
import { ProjectMeetingRecordCreditComponent } from './components/main/shared/projects/project-detail/project-meeting-records/project-meeting-record-credit/project-meeting-record-credit.component';
import { ProjectPhaseBoardColRoleDialogComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-boards/project-phase-board-col-role-dialog/project-phase-board-col-role-dialog.component';
import { ProjectManagerGuard } from './guards/roles/department-categories/project-manager.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { ProjectApplicationsComponent } from './components/main/shared/projects/project-detail/project-applications/project-applications.component';
import { ProjectApplicationCreditComponent } from './components/main/shared/projects/project-detail/project-applications/project-application-credit/project-application-credit.component';
import { EditWeeklyWorkingTimeBudgetDistributionDistributableDialogComponent } from './components/main/employees/project-management/planning-roadmap/edit-weekly-working-time-budget-distribution-distributables-dialog/edit-weekly-working-time-budget-distribution-distributables-dialog.component';
import { ProjectDistributionTimeDialogComponent } from './components/main/shared/projects/project-detail/project-resume/project-distribution-time-dialog/project-distribution-time-dialog.component';
import { ProjectDistributionTimeDialogLevelComponent } from './components/main/shared/projects/project-detail/project-resume/project-distribution-time-dialog/project-distribution-time-dialog-level/project-distribution-time-dialog-level.component';
import { DateFromToDialogComponent } from './components/utils/date-from-to-dialog/date-from-to-dialog.component';
import { CalendarEventDetailDialogComponent } from './components/main/employees/project-management/project-assignment/calendar-assignment-dialog/calendar-event-detail-dialog/calendar-event-detail-dialog.component';
import { ProfilePicWithRoleComponent } from './components/utils/profile-pic-with-role/profile-pic-with-role.component';
import { DepartmentCategoryToColorPipe } from './pipes/department-category-to-color.pipe';
import { DepartmentCategoryToIconPipe } from './pipes/department-category-to-icon.pipe';
import { HolidaysComponent } from './components/main/employees/rrhh/holidays/holidays.component';
import { ProjectAssignmentTableComponent } from './components/main/employees/project-management/project-assignment/project-assignment-table/project-assignment-table.component';
import { ProjectAssignmentDialogComponent } from './components/main/employees/project-management/planning-roadmap/project-assignment-dialog/project-assignment-dialog.component';
import { AddCompanyNotificationChannelDialogComponent } from './components/main/employees/direction/users-management/companies/add-company-notification-channel-dialog/add-company-notification-channel-dialog.component';
import { ProjectPhaseDeployConfigurationDialogComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-deploy-configuration-dialog/project-phase-deploy-configuration-dialog.component';
import { DayOfWeekPluralPipe } from './pipes/day-of-week-plural.pipe';
import { SendBoardStatusDialogComponent } from './components/main/shared/projects/project-detail/project-phases/send-board-status-dialog/send-board-status-dialog.component';
import { ProjectGuardianScheduledWeeksComponent } from './components/main/shared/projects/project-detail/project-guardian-scheduled-weeks/project-guardian-scheduled-weeks.component';
import * as moment from 'moment';
import { AssignNewGuardDialogComponent } from './components/main/shared/projects/project-detail/project-guardian-scheduled-weeks/assign-new-guard-dialog/assign-new-guard-dialog.component';
import { ProjectTeamsComponent } from './components/main/shared/projects/project-detail/project-teams/project-teams.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProjectTeamCreditDialogComponent } from './components/main/shared/projects/project-detail/project-teams/project-team-credit-dialog/project-team-credit-dialog.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ProjectTaskBudgetDetailDialogComponent } from './components/main/shared/projects/project-task-detail/project-task-budget-detail-dialog/project-task-budget-detail-dialog.component';
import { HoursToHourStringPipe } from './pipes/hours-to-hour-string.pipe';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import { SelectorDialogComponent } from './components/main/shared/selector-dialog/selector-dialog.component';
import { TaskFromTodoToProgressDialogComponent } from './components/main/shared/projects/project-phase-board-column-changes/task-from-todo-to-progress-dialog/task-from-todo-to-progress-dialog.component';
import { TaskToDeadlockDialogComponent } from './components/main/shared/projects/project-phase-board-column-changes/task-to-deadlock-dialog/task-to-deadlock-dialog.component';
import { OptionsDialogComponent } from './components/main/shared/options-dialog/options-dialog.component';
import { DatePickerDialogComponent } from './components/main/shared/date-picker-dialog/date-picker-dialog.component';
import { EmployeeCalendarFestivitiesDialogComponent } from './components/main/employees/my-zone/my-absences/employee-calendar-festivities-dialog/employee-calendar-festivities-dialog.component';
import { NewTrainingFormationRequestDialogComponent } from './components/main/employees/top-menu-employee/new-training-formation-request-dialog/new-training-formation-request-dialog.component';
import { ComputersComponent } from './components/main/employees/rrhh/computers/computers.component';
import { CreditComputerDialogComponent } from './components/main/employees/rrhh/computers/credit-computer-dialog/credit-computer-dialog.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { HtmlDialogComponent } from './components/main/shared/html-dialog/html-dialog.component';
import { MyCareerPlanComponent } from './components/main/employees/my-zone/my-career-plan/my-career-plan.component';
import { PlatformService } from './services/platform.service';
import { ForceSetupDoNotDisturbShortcutsDialogComponent } from './components/main/shared/force-setup-do-not-disturb-shortcuts-dialog/force-setup-do-not-disturb-shortcuts-dialog.component';
import {MatStepperModule} from '@angular/material/stepper';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { BisualSettingInitializedGuard } from './guards/bisual-setting-initialized.guard';
import { DownloadNewVersionElectronAppDialogComponent } from './components/main/shared/download-new-version-electron-app-dialog/download-new-version-electron-app-dialog.component';

registerLocaleData(localeEs, 'es', localeEsExtra);

export function initializeApp(platformService: PlatformService): () => void {
  return () => {

  };
}

@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    AuthComponent,
    LoginComponent,
    RestorePasswordComponent,
    DashboardComponent,
    SupportComponent,
    ProjectsComponent,
    ServicesComponent,
    BillingComponent,
    UsersComponent,
    ConfigurationComponent,
    LegalDocumentationComponent,
    UsersDetailComponent,
    ChangePasswordDialogComponent,
    SafeUrlPipe,
    AddUserDialogComponent,
    ProjectDetailComponent,
    HostingsComponent,
    DomainsComponent,
    BusinessMailsComponent,
    DaysToPeriodPipe,
    AddHostingDialogComponent,
    HostingDetailComponent,
    HostingDetailResumeComponent,
    HostingDetailBillingComponent,
    HostingDetailCredentialsComponent,
    BytesPipe,
    ProviderOrderFilesDialogComponent,
    PasswordPipe,
    CredentialDetailDialogComponent,
    HostingDetailEditComponent,
    AddDomainDialogComponent,
    DomainDetailComponent,
    DomainDetailResumeComponent,
    DomainDetailEditComponent,
    DomainDetailCredentialsComponent,
    DomainDetailBillingComponent,
    DomainDetailDnsComponent,
    ConcatPipe,
    AddBusinessMailDialogComponent,
    BusinessMailDetailComponent,
    BusinessMailDetailResumeComponent,
    BusinessMailDetailEditComponent,
    BusinessMailDetailCredentialsComponent,
    BusinessMailDetailBillingComponent,
    BusinessMailDetailOvhComponent,
    ProviderOrderAttachmentsDialogComponent,
    TestComponent,
    BillingResumeComponent,
    BillingOrdersComponent,
    BillingProviderOrdersComponent,
    BillingContasimpleDashboardComponent,
    BillingAlertsComponent,
    UsersManagementComponent,
    ProvidersComponent,
    CompaniesComponent,
    ProviderDetailComponent,
    CompanyDetailComponent,
    AddCompanyDialogComponent,
    AddProviderDialogComponent,
    AddNewOrderDialogComponent,
    ProviderOrderDialogComponent,
    BillingAlertDetailDialogComponent,
    WorkingTimesResumeComponent,
    WorkingTimesHistoryComponent,
    WorkingTimesCalendarComponent,
    SecondsToStringTimePipe,
    ProjectTaskTypeImagePipe,
    WorkingTimeHistoryDayDetailDialogComponent,
    AddNewWorkingTimeDialogComponent,
    BlogpostsComponent,
    ClockRegistersComponent,
    ManageVacationRequestComponent,
    MyAbsencesComponent,
    MyPayrollsComponent,
    AbsencesManagementComponent,
    VacationsComponent,
    MedicalSickLeavesComponent,
    PayrollsComponent,
    TicketsComponent,
    ProjectManagementComponent,
    MarketingComponent,
    RrhhComponent,
    DirectionComponent,
    VacationRequestsComponent,
    ClockRegistersManagementComponent,
    WorkersComponent,
    ModalMyAbsencesComponent,
    ProjectResumeComponent,
    ProjectReportDialogComponent,
    HourDecimalToHoursMinutesPipe,
    AddNewClockRegisterReviewRequestDialogComponent,
    ForceClockRegisterDialogComponent,
    ClockRegisterReviewRequestsComponent,
    ClockRegisterResumeComponent,
    ClockRegistersManagementTableDetailDialogComponent,
    DepartmentsComponent,
    DepartmentDetailComponent,
    DepartmentCategoryDetailComponent,
    AddDepartmentDialogComponent,
    AddDepartmentCategoryDialogComponent,
    SecondsToHourStringPipe,
    HourStringToSecondsPipe,
    WorkersPlanningComponent,
    EmployeeMonthWorkingCapacityFieldPipe,
    LateralMenuEmployeeComponent,
    LateralMenuClientComponent,
    TopMenuClientComponent,
    TopMenuEmployeeComponent,
    CalendarDateToCustomFormatPipe,
    ProjectPhasesComponent,
    ContractToStatePipe,
    PlanningRoadmapComponent,
    AddContractToRoadmapDialogComponent,
    WorkingTimesCalendarDialogComponent,
    ProjectAssignmentComponent,
    VacationsAndHolidaysDialogComponent,
    ProjectPhasesDetailComponent,
    NonProductiveHoursPlanningDialogComponent,
    CalendarAssignmentDialogComponent,
    DateFromToDialogComponent,
    ProjectPhaseBacklogComponent,
    ProjectPhaseBoardsComponent,
    ProjectTaskDetailComponent,
    DateAgoPipe,
    StripHtmlPipePipe,
    ProjectTaskHistoryLogFieldPipePipe,
    ProjectTaskPriorityFieldPipePipe,
    BillingPlanningComponent,
    AddBillingPlanningConceptDialogComponent,
    ProjectTaskTypeFieldPipePipe,
    SecondsToHourWithDecimalPipe,
    EditProjectPhaseBoardDialogComponent,
    CompleteProjectPhaseBoardDialogComponent,
    SecondsToHourWithDecimalPipe,
    PotentialClientsComponent,
    PotentialClientsStatusChangesComponent,
    ComercialComponent,
    AddPotentialClientDialogComponent,
    PotentialClientDetailComponent,
    PotentialClientChangeStatusDialogComponent,
    PotentialClientMeetingRecordComponent,
    CommercialDashboardComponent,
    BisualSettingSectionKeyToTitlePipe,
    PriorityImgPipe,
    DepartmentCategoryToColorPipe,
    DepartmentCategoryToIconPipe,
    ImportFileDialogComponent,
    ProjectPhaseEditDialogComponent,
    ProjectManageMembersDialogComponent,
    ProgressBarCardComponent,
    GeneralSearchDialogComponent,
    WikiComponent,
    WikiNodeComponent,
    WikiArticleComponent,
    MoveWikiItemDialogComponent,
    MeetingRecordComponent,
    ProjectMeetingRecordsComponent,
    ProjectMeetingRecordCreditComponent,
    ProjectPhaseBoardColRoleDialogComponent,
    ProjectApplicationsComponent,
    ProjectApplicationCreditComponent,
    EditWeeklyWorkingTimeBudgetDistributionDistributableDialogComponent,
    ProjectDistributionTimeDialogComponent,
    ProjectDistributionTimeDialogLevelComponent,
    CalendarEventDetailDialogComponent,
    ProfilePicWithRoleComponent,
    HolidaysComponent,
    ProjectAssignmentTableComponent,
    ProjectAssignmentDialogComponent,
    AddCompanyNotificationChannelDialogComponent,
    ProjectPhaseDeployConfigurationDialogComponent,
    DayOfWeekPluralPipe,
    SendBoardStatusDialogComponent,
    ProjectGuardianScheduledWeeksComponent,
    AssignNewGuardDialogComponent,
    ProjectTeamsComponent,
    ProjectTeamCreditDialogComponent,
    ProjectTaskBudgetDetailDialogComponent,
    SelectorDialogComponent,
    OptionsDialogComponent,
    DatePickerDialogComponent,
    TaskFromTodoToProgressDialogComponent,
    TaskToDeadlockDialogComponent,
    EmployeeCalendarFestivitiesDialogComponent,
    NewTrainingFormationRequestDialogComponent,
    ComputersComponent,
    CreditComputerDialogComponent,
    HtmlDialogComponent,
    MyCareerPlanComponent,
    ForceSetupDoNotDisturbShortcutsDialogComponent,
    DownloadNewVersionElectronAppDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatPaginatorModule,
    MatTableModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatSnackBarModule,
    HttpClientModule,
    MatCheckboxModule,
    MatSelectModule,
    MatTabsModule,
    MatDialogModule,
    MatRadioModule,
    MatTooltipModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    ClipboardModule,
    NgbModule,
    DragDropModule,
    MatCardModule,
    NgxDropzoneModule,
    MatButtonToggleModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatTreeModule,
    MatChipsModule,
    EditorModule,
    HoursToHourStringPipe,
    MatSlideToggleModule,
    NgApexchartsModule,
    MatStepperModule,
    CdkStepperModule
  ],
  providers: [
    // Modules
    MatDatepickerModule,

    // Guards
    LoggedGuard,
    RegexServiceInitiatedGuard,
    SupportGuard,
    ProductionTeamGuard,
    DirectionGuard,
    MarketingGuard,
    RrhhGuard,
    SupportGuard,
    DepartmentsInitiatedGuard,
    EmployeeGuard,
    ClientsGuard,
    ComercialGuard,
    ProjectManagerGuard,
    BisualSettingInitializedGuard,

    //Pipes
    DatePipe,
    DecimalPipe,
    SecondsToStringTimePipe,
    PendingChangesGuard,
    HourStringToSecondsPipe,
    CurrencyPipe,

    // Interceptors
    { provide: HTTP_INTERCEPTORS, useClass: SnackbarInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },

    // Other providers
    { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' },
    { provide: LOCALE_ID, useValue: 'es-ES' },

    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [PlatformService],
      multi: true
    },

    // third party providers
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    moment.locale('es');
    moment.updateLocale('es', {
      week: {
        dow: 1, // Lunes es el primer día de la semana
        doy: 4  // El primer día del año que debe estar en la primera semana del año
      }
    });
  }
}
