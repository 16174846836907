<div id="content" class="extensible">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Mi carrera</h1>
        </div>
      </div>
    </div>

    @if(!noCareerFound) {
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-title">
              <h2>Evolución de mi carrera</h2>
            </div>

            @if(chartOptions) {
              <div id="chart">
                <apx-chart
                  [series]="chartOptions.series"
                  [chart]="chartOptions.chart"
                  [xaxis]="chartOptions.xaxis"
                  [yaxis]="chartOptions.yaxis"
                  [stroke]="chartOptions.stroke"
                  [tooltip]="chartOptions.tooltip"
                  [colors]="chartOptions.colors"
                  [dataLabels]="chartOptions.dataLabels"
                  [legend]="chartOptions.legend"
                  [annotations]="chartOptions.annotations"
                ></apx-chart>
              </div>
            }
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-xl-8">
          @if(department_categories) {
            <div class="card">
              <div class="card-title">
                <h2>Calculadora de retribuciones</h2>
              </div>

              <div class="container">
                <form class="row" [formGroup]="calculatorFormGroup">
                  @if(!simulationCalculatorData) {
                    <div class="col-12 col-xl-6">
                      <p>Con esta calculadora podrás simular lo que podrías estar cobrando en Bisual en el futuro. Simplemente introduce la categoría de nivel a la que aspiras, los años de antigüedad para calcular los trienios, y en qué momento quieres que se aplique esas condiciones (por ejemplo puede que en el futuro hayan salarios más altos que ahora).</p>
                    </div>
                  }

                  <div class="col-12 col-xl-6">
                    <mat-form-field appearance="outline">
                      <mat-label>Escoge categoría</mat-label>
                      <mat-select formControlName="department_category_id">
                        @for(dc of department_categories; track dc;) {
                          <mat-option [value]="dc.id">{{ dc.name }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>Años de antigüedad</mat-label>
                      <input matInput placeholder="Por ejemplo 25..." type="number" formControlName="years_in_bisual" min="0" />
                    </mat-form-field>

                    <mat-form-field appearance="outline">
                      <mat-label>¿Cuándo?</mat-label>
                      <input matInput [matDatepicker]="dp" formControlName="when" [matDatepickerFilter]="calculatorWhenDatepickerFilter">
                      <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                      <mat-datepicker #dp startView="multi-year" (yearSelected)="onYearSelectedOnCalculator($event, dp)"></mat-datepicker>
                    </mat-form-field>

                    <button mat-flat-button color="accent" style="width: 100%;" [disabled]="!calculatorFormGroup.valid" (click)="submitCalculator()">
                      <mat-icon>check_circle</mat-icon>
                      Calcular
                    </button>
                  </div>
                  @if(simulationCalculatorData) {
                    <div class="col-12 col-xl-6">
                      @if(simulationCalculatorData.data) {
                        <ul>
                          <li><b>Salario bruto base anual</b>: {{ simulationCalculatorData.data.dcsc.brute_anual_base_salary | currency }}</li>
                          <li><b>Plus por antigüedad anual</b>: {{ simulationCalculatorData.data.plus_for_seniority| currency }}</li>
                          <li><b>Retribución flexible mensual (Payflow)</b>: {{ simulationCalculatorData.data.dcsc.flexible_remuneration_monthly_quantity > 0 ? (simulationCalculatorData.data.dcsc.flexible_remuneration_monthly_quantity| currency) : 'No' }}</li>
                          <li><b>Seguro médico</b>: {{ simulationCalculatorData.data.dcsc.health_insurance_plan_id != null ? 'Sí' : 'No' }}</li>
                          <li><b>Posibilidad de prejubilación</b>: {{ simulationCalculatorData.data.dcsc.possible_future_early_retirement ? 'Sí' : 'No' }}</li>
                          <li><b>Posibilidad de plan de pensiones</b>: {{ simulationCalculatorData.data.dcsc.possible_future_retirement_plan ? 'Sí' : 'No' }}</li>
                          <li><b>Política activa desde</b>: {{ simulationCalculatorData.data.dcsc.start_date | date:'shortDate' }}</li>
                        </ul>
                      }
                      @else {
                        <p>No se han encontrado condiciones para estos salarios.</p>
                      }
                    </div>
                  }
                </form>
              </div>
            </div>
          }
          <div class="card">
            <div class="card-title">
              <h2>¿Como progresar en la carrera?</h2>
            </div>

            <p>Año a año, se irá analizando tu progreso en función de como hayan ido los últimos 365 días. En esa reunión, se determinará el progreso que se ha hecho hacia el objetivo. Dependerá de: </p>
            <ul class="check-list">
              <li>
                <mat-icon>check_circle</mat-icon>
                Objetivos de equipo en los proyectos
              </li>
              <li>
                <mat-icon>check_circle</mat-icon>
                Prcnt. de producción progresando hacia seniority
              </li>
              <li>
                <mat-icon>check_circle</mat-icon>
                Evaluación de tus compañeros de equipo
              </li>
              <li>
                <mat-icon>check_circle</mat-icon>
                Autoevaluación
              </li>
              <li>
                <mat-icon>check_circle</mat-icon>
                Formación realizada y habilidades controladas
              </li>
            </ul>
          </div>
        </div>

        <div class="col-12 col-xl-4">
          <div class="card" style="margin-bottom: 20px;">
            <div class="card-title">
              <h2>Objetivo de la carrera</h2>
            </div>

            @if(working_contract_career_plans.length == 0) {
              <p>No hay objetivos de carrera establecidos. Te animamos a comentarlo en las reuniones mensuales.</p>
            }
            @else {
              <ul>
                @for(wccp of working_contract_career_plans; track wccp;) {
                  <li>
                    <b>{{ wccp.created_at | date:'shortDate' }}</b>: {{ wccp.administrators_annotations }}
                  </li>
                }
              </ul>
            }
          </div>

          <div class="card">
            <div class="card-title">
              <h2>Futuro ascenso en la carrera</h2>
            </div>

            @if(!next_upgrade) {
              <p>No hay planificado un próximo ascenso en tu carrera. Te animamos a comentarlo en las reuniones mensuales para alinear tus objetivos con los de Bisual. ¡Gracias!</p>
            }
            @else {
              <p>
                Durante <b>{{ next_upgrade.upgraded_department_category_salary_condition.start_date | date:'yyyy' }}</b> pasarías a <b>{{ next_upgrade.department_category.name }}</b>.
              </p>

              <ul class="check-list">
                <li>
                  <mat-icon>check_circle</mat-icon>
                  Quedan {{ next_upgrade.days_remaining }} días
                </li>
                <li>
                  <mat-icon>check_circle</mat-icon>
                  Sueldo bruto previsto (con pluses): {{ (next_upgrade.upgraded_department_category_salary_condition.brute_anual_base_salary + next_upgrade.plus_for_seniority_then)/12 | currency }}
                </li>
                @if(next_upgrade.upgraded_department_category_salary_condition.health_insurance_plan_id != null) {
                  <li>
                    <mat-icon>check_circle</mat-icon>
                    Incluye seguro médico
                  </li>
                }
                @if(next_upgrade.upgraded_department_category_salary_condition.flexible_remuneration_monthly_quantity > 0) {
                  <li>
                    <mat-icon>check_circle</mat-icon>
                    Incluye {{ next_upgrade.upgraded_department_category_salary_condition.flexible_remuneration_monthly_quantity | currency }} de Payflow
                  </li>
                }
              </ul>

              <p style="font-size: 10px; font-style: italic; margin-top: 10px;">
                * Evaluado anualmente. Deben cumplirse objetivos de proyectos, adquirir conocimientos y habilidades técnicas, y el % de producción debe ser acorde al nuevo rol.
              </p>
            }
          </div>

        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-4">
        </div>
        <div class="col-12 col-xl-4">
        </div>
      </div>
    }
    @else {
      <div class="row">
        <div class="col-12">
          <div class="card">
            <p>No se han encontrado planes de carrera para ti.</p>
          </div>
        </div>
      </div>
    }
  </div>
</div>
