import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { MoveWikiItemDialogComponent } from 'src/app/components/utils/move-wiki-item-dialog/move-wiki-item-dialog.component';
import { ApplicationEnvironment } from 'src/app/models/application_environment.model';
import { WikiNode } from 'src/app/models/wiki-node.model';
import { ApiService } from 'src/app/services/api.service';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-wiki-node',
  templateUrl: './wiki-node.component.html',
  styleUrls: ['./wiki-node.component.css']
})
export class WikiNodeComponent implements OnInit {
  wikiNodeId: string = null as any;
  wikiNode: WikiNode = null as any;

  menuTopLeftPosition =  {x: '0', y: '0'}
  // reference to the MatMenuTrigger in the DOM
  @ViewChild(MatMenuTrigger) contextMenu: MatMenuTrigger;

  environments:ApplicationEnvironment[] = null as any
  environment_selected:number = null as any

  constructor(private activatedRoute: ActivatedRoute,
              private api: ApiService,
              private dialog: MatDialog,
              private employee:EmployeeService) {

  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(params => {
      this.environments = null as any;
      this.wikiNodeId = params.id;
      this.fetchWikiTree();
    });
  }

  getPathGithub(element:any) {
    const url = new URL(element.git_url);
    const url_arr = url.pathname.split("/")
    if(url_arr[1]==="repos") {
      const org = url_arr[2];
      const repo = url_arr[3];
      const filename = element.name;
      return org + "/" + repo + "/" + filename;
    }
    else return "";
  }

  createNode() {
    const newWikiNodeName = {
      name: prompt('Escribe el nombre de la nueva carpeta'),
      wiki_node_id: this.wikiNode.id,
    }
    this.api.createWikiNode(newWikiNodeName).subscribe((data: any) => {
      this.fetchWikiTree();
    });
  }

  createArticle() {
    const newWikiNodeName = {
      title: prompt('Escribe el nombre del nuevo documento'),
      wiki_node_id: this.wikiNode.id,
    }
    this.api.createWikiArticle(newWikiNodeName).subscribe((data: any) => {
      this.fetchWikiTree();
    });
  }

  onRightClick(event: MouseEvent, item: any, type: string) {
    // preventDefault avoids to show the visualization of the right-click menu of the browser
    event.preventDefault();

    // we record the mouse position in our object
    this.menuTopLeftPosition.x = event.clientX + 'px';
    this.menuTopLeftPosition.y = event.clientY + 'px';

    // we open the menu
    // we pass to the menu the information about our object
    item.type = type;
    this.contextMenu.menuData = {item: item}

    // we open the menu
    this.contextMenu.openMenu();
  }

  deleteNode () {
    if (!confirm('¿Estas seguro que quieres borrar esta carpeta? Se borraran todos los documentos que contenga.')) return;
    this.api.deleteWikiNode(this.contextMenu.menuData.item.id).subscribe((data: any) => {
      this.fetchWikiTree();
    });
  }

  changeNameNode () {
    const newName = prompt('Escribe el nuevo nombre');
    if(!newName) return;
    this.api.updateWikiNode(this.contextMenu.menuData.item.id, { name: newName }).subscribe((data: any) => {
      this.fetchWikiTree();
    });
  }

  deleteArticle () {
    if (!confirm('¿Estas seguro que quieres borrar este documento?')) return;
    this.api.deleteWikiArticle(this.contextMenu.menuData.item.id).subscribe((data: any) => {
      this.fetchWikiTree();
    });
  }

  showMoveWikiItemDialog() {
    const dialogRef = this.dialog.open(MoveWikiItemDialogComponent, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(
      body => {
        this.moveWikiItem(body);
      }
    );
  }

  moveWikiItem(wikiNode: WikiNode) {
    if(!this.contextMenu.menuData.item) return;
    if(this.contextMenu.menuData.item.type == 'WikiNode') {
      this.api.updateWikiNode(this.contextMenu.menuData.item.id, { wiki_node_id: wikiNode.id }).subscribe((data: any) => {
        this.fetchWikiTree();
      });
    } else {
      this.api.updateWikiArticle(this.contextMenu.menuData.item.id, { wiki_node_id: wikiNode.id }).subscribe((data: any) => {
        this.fetchWikiTree();
      });
    }
  }

  onEnvironmentChanged(env:number) {
    this.environment_selected = env;
    this.fetchWikiTree();
  }

  private fetchWikiTree() {
    this.api.getWikiNode(parseInt(this.wikiNodeId), { with: 'children..project,wiki_articles,parent,project', append: 'all_parents', application_environment_id: this.environment_selected }).subscribe(
      data => {
        if(this.employee.isMeetingOngoing()) {
          data.children = data.children.filter(child => child.project == null || this.employee.isMeetingOngoing(child.project.id))
        }

        this.wikiNode = data;
        if(this.wikiNode.project != null && this.environments == null) {
          this.fetchProjectEnvironments(this.wikiNode.project.id)
        }
      }
    );
  }

  private fetchProjectEnvironments(project_id:number) {
    this.api.getProjectApplicationEnvironments(project_id.toString()).subscribe(
      data => {
        this.environments = data
      }
    );
  }
}
