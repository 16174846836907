import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-new-training-formation-request-dialog',
  templateUrl: './new-training-formation-request-dialog.component.html',
  styleUrl: './new-training-formation-request-dialog.component.css'
})
export class NewTrainingFormationRequestDialogComponent implements OnInit {
  form:FormGroup;

  constructor(private fb:FormBuilder,
    private dialogRef: MatDialogRef<NewTrainingFormationRequestDialogComponent>,
    private api:ApiService) {

    this.form = this.fb.group({
      title: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
      price: ['', Validators.compose([Validators.required])],
      observations: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
    });

  }

  ngOnInit(): void {

  }

  submit() {
    this.api.createTrainingFormationRequest(this.form.value).subscribe(
      data => {
        this.close();
      }
    );
  }

  close() {
    this.dialogRef.close();
  }
}
