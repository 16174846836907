<div id="content">
  <div class="page-title">
    <h1>{{ isCreate ? 'Crear nueva acta de reunión' : 'Editar acta de reunión' }}</h1>

    <button mat-flat-button routerLink="{{ onBackRoute ? onBackRoute : (isCreate ? '..' : '../..') }}">
      <mat-icon>arrow_back</mat-icon>
      Volver
    </button>
    <button mat-flat-button color="accent" (click)="process()" [disabled]="!meetingRecordFormGroup.valid">
      <mat-icon>save</mat-icon>
      {{ isCreate ? 'Crear acta de reunión' : 'Actualizar acta de reunión' }}
    </button>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title">
              <h2>Formulario de acta</h2>
              <p class="subtitle">Introduce los detalles del acta de reunión</p>
            </div>
            <div class="card-actions">
              <button mat-icon-button (click)="downloadPdf()">
                <mat-icon>download</mat-icon>
              </button>
          </div>

          <div class="container" [formGroup]="meetingRecordFormGroup">
            <div class="row">
              <div class="col-12 col-lg-9">
                <mat-form-field appearance="outline" class="nobottom">
                  <mat-label>Título</mat-label>
                  <input matInput formControlName="title">
                </mat-form-field>
              </div>
              <div class="col-12 col-lg-3">
                <mat-form-field appearance="outline" class="nobottom">
                  <mat-label>Fecha de reunión</mat-label>
                  <input matInput [matDatepicker]="picker" formControlName="date">
                  <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                  <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>

              </div>
              <div class="col-12 col-lg-6">
                <mat-form-field appearance="outline" class="nobottom">
                  <mat-label>Asistentes cliente</mat-label>
                  <input matInput formControlName="assistants_client">
                </mat-form-field>
              </div>
              <div class="col-12 col-lg-6">
                <mat-form-field appearance="outline" class="nobottom">
                  <mat-label>Asistentes Bisual</mat-label>
                  <input matInput formControlName="assistants_provider">
                </mat-form-field>
              </div>
              <div class="col-12">
                <editor
                  formControlName="content"
                  [apiKey]="tinymce.getKey()"
                  cloudChannel="6"
                  [disabled]="false"
                  id="description"
                  [init]="tinyMceInit"
                  initialValue=""
                  [inline]="false"
                  tagName="div"
                  read
                  plugins= "anchor autolink charmap codesample directionality help image insertdatetime link lists media nonbreaking pagebreak searchreplace table visualblocks visualchars wordcount advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table emoticons codesample"
      toolbar= "undo redo spellcheckdialog | blocks fontfamily fontsize | codesample | bold italic underline strikethrough | forecolor backcolor | insertfile link image | align lineheight checklist bullist numlist | indent outdent | removeformat typography | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol">
                </editor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
