@if (phase != null && data != null) {
  <section class="board column">
    <div class="column">
      <div cdkDropList [cdkDropListEnterPredicate]="canMoveColumns" cdkDropListOrientation="horizontal" class="board-columns" (cdkDropListDropped)="dropGridOnColumnOrderChange($event)">
        @for (column of data.columns; track column) {
          <div
            (mouseenter)="hoveringColumnId = column.column.id"
            cdkDrag
            cdkDragLockAxis="x"
            [cdkDragDisabled]="!isProjectManagerOrProductManager"
            class="column-container board-column"
            >
            @if (editingColumnTitleId !== column.column.id) {
              <div class="column-title" cdkDragHandle>
                <div>
                  <h3 (click)="editColumn(column)" [matTooltip]="column.column.title">
                    {{column.column.title}}
                  </h3>
                  @if (column.column.project_phase_board_col_role_id!=null) {
                    <mat-chip color="accent" class="column-role" [matTooltip]="column.column.project_phase_board_col_role.name">{{column.column.project_phase_board_col_role.name}}</mat-chip>
                  }
                  @if (column.column.project_phase_board_col_role_id!=null && column.column.environment_id != null) {
                    <mat-icon>rocket</mat-icon>
                  }
                </div>
                @if ((isProjectManagerOrProductManager || (isClientDeveloper && data.project_phase_board.can_client_dev_manage_board) || (isWorker && data.project_phase_board.can_dev_manage_board)) && hoveringColumnId === column.column.id) {
                  <section class="columnActions">
                    <button mat-icon-button class="small-icon-button" [matMenuTriggerFor]="menu">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                      <button mat-menu-item (click)="deleteColumn(column.column)" [disabled]="!isProjectManagerOrProductManager">
                        <mat-icon style="margin-right: 10px">delete</mat-icon>
                        Eliminar
                      </button>
                      <button mat-menu-item (click)="changeRoleColumn(column.column)" [disabled]="!isProjectManagerOrProductManager">
                        <mat-icon style="margin-right: 10px">settings</mat-icon>
                        Configurar columna
                      </button>
                      <button mat-menu-item (click)="archiveTasksFromColumn(column.column)">
                        <mat-icon style="margin-right: 10px">archive</mat-icon>
                        Archivar todo
                      </button>
                    </mat-menu>
                  </section>
                }
              </div>
            }
            @if (editingColumnTitleId === column.column.id) {
              <div class="column-title editing-column-title">
                <input (keydown.enter)="$event.preventDefault(); editColumnName()" [(ngModel)]="editingColumnTitle">
                <button mat-icon-button [disabled]="editingColumnTitle.length < 3" (click)="editColumnName()">
                  <mat-icon>done</mat-icon>
                </button>
              </div>
            }
            <div id="{{column.column.title}}"
              cdkDropList [cdkDropListConnectedTo]="columnNames"
              [cdkDropListData]="column.project_task_boards" class="column-list" (cdkDropListDropped)="dropTask($event)" [attr.data-column-id]="column.column.id">
              @for (item of column.project_task_boards; track item) {
                <div class="task-box" (click)="displayTaskDetail($event, item);"
                  (mouseenter)="hoveringTaskId = item.id"
                  [attr.data-project-task-board-id]="item.id"
                  [attr.data-project-task-id]="item.project_task_id"
                  [cdkDragDisabled]="
                    !(isWorker && data.project_phase_board.can_dev_manage_board) &&
                    (!imDeveloping ||
                    (
                      column.column.project_phase_board_col_role!=null &&
                      column.column.project_phase_board_col_role.slug === 'todo' &&
                      (
                        (item.project_task.type === 'story' && phase.needs_hours_story && (item.ro_time_estimation == null || item.ro_time_estimation == 0)) ||
                        (item.project_task.type === 'task' && phase.needs_hours_task && (item.ro_time_estimation == null || item.ro_time_estimation == 0)) ||
                        (item.project_task.type === 'issue' && phase.needs_hours_issue && (item.ro_time_estimation == null || item.ro_time_estimation == 0))
                      )
                    ) ||
                    (
                      !isProjectManagerOrProductManager &&
                      column.column.project_phase_board_col_role != null &&
                      column.column.project_phase_board_col_role.slug !== 'todo' &&
                      column.column.project_phase_board_col_role.slug !== 'under-review' &&
                      column.column.project_phase_board_col_role.slug !== 'deadlock' &&
                      (item.active_project_task_board_time_budget==null || (item.active_project_task_board_time_budget.assignee_id!=null && item.active_project_task_board_time_budget.assignee_id!=user.id))
                    ))"
                  cdkDrag>
                  <section class="title">{{item.project_task.title}}</section>
                  <section class="task-footer">
                    @if (item.project_task.tags.length) {
                      <div class="w-100 tag-list">
                        @for (tag of item.project_task.tags; track tag) {
                          <mat-chip class="preview-tag">#{{ tag.tag.title }}</mat-chip>
                        }
                      </div>
                    }
                    <!-- task-tag-categories -->
                    <div class="task-code">
                      <img class="task-type" [src]="item.project_task.type | projectTaskTypeImage" />
                      @if (item.project_task.is_archived) {
                        <mat-icon>archive</mat-icon>
                      }
                      <span>{{item.project_task.code}}</span>
                      @if (item.project_task.is_hotfix) {
                        <span class="hotfix-indicator" [matTooltip]="'Hotfix'">
                          <mat-icon>bolt</mat-icon>
                          <!-- HOTFIX -->
                        </span>
                      }
                      @if (item.project_task.needs_testing) {
                        <span class="needs-testing-indicator" [matTooltip]="'Necesita testing'">
                          <mat-icon>science</mat-icon>
                        </span>
                      }
                      @if (item.project_task.needs_documentation) {
                        <span class="needs-documentation-indicator" [matTooltip]="'Necesita documentación'">
                          <mat-icon>library_books</mat-icon>
                        </span>
                      }
                    </div>
                    <section class="right-footer">
                      @if (item.project_task.priority !== 'medium') {
                        <div>
                          <img class="priority p-1" [src]="item.project_task.priority | priorityImg" />
                        </div>
                      }

                      <!-- Categories per fer -->
                      @for(time_budget of item.project_task_board_time_budgets; track time_budget) {
                        @if(
                          !isWorkingTimeCategoryUnderReview(time_budget) &&
                          (time_budget.assignee != null || (time_budget.time_in_seconds!=null && time_budget.time_in_seconds > 0))
                        ) {
                          <div class="user-category-progress-spinner">
                            @if(time_budget.time_in_seconds != null && time_budget.time_in_seconds > 0) {
                              <mat-progress-spinner [value]="getPtbtbProgressSpinnerValue(time_budget)" [ngClass]="getPtbtbProgressSpinnerClass(time_budget, item)" diameter="36" strokeWidth="2"></mat-progress-spinner>
                              <span class="time-in-seconds">{{ time_budget.time_in_seconds | SecondsToHourWithDecimal:0 }}</span>
                            }

                            <div class="circle"
                              [matTooltip]="(time_budget.assignee != null ? (time_budget.assignee.name + ' ' + time_budget.assignee.surnames + '. ') : '') + ('Categoría: ' + time_budget.budgetable.name + '. ') + (time_budget.time_in_seconds != null && time_budget.time_in_seconds > 0 ? ((time_budget.time_in_seconds | secondsToStringTime) + ' por hacer.') : 'Sin horas asignadas.')"
                            >
                              @if(time_budget.assignee != null) {
                                <img [src]="time_budget.assignee.profile_picture_url" [alt]="time_budget.assignee.name + ' ' + time_budget.assignee.surnames" />
                              }
                              <div class="icon-wrapper" [ngClass]="getPtbtbProgressSpinnerClass(time_budget, item)">
                                <mat-icon>{{ time_budget.budgetable.google_fonts_icon_slug }}</mat-icon>
                              </div>

                            </div>

                            @if(time_budget.completed_by_user_id != null) {
                              <mat-icon class="success-icon">check_circle</mat-icon>
                            }
                          </div>
                        }
                      }

                      <!-- Categoria final total -->
                      @if(item.ro_time_estimation > 0) {
                        <div class="user-category-progress-spinner">
                          <mat-progress-spinner [value]="getPtbProgressSpinnerValue(item)" [ngClass]="getPtbProgressSpinnerClass(item, column.column)" diameter="36" strokeWidth="2"></mat-progress-spinner>
                          <span class="time-in-seconds">{{ item.ro_time_estimation | SecondsToHourWithDecimal:0 }}</span>
                          <div class="circle" [matTooltip]="'Tiempo total a realizar en este tablero: ' + (item.ro_time_estimation | SecondsToHourString)">
                            <span>T</span>
                          </div>
                        </div>
                      }
                    </section>
                  </section>

                  @if ((isProjectManagerOrProductManager || (isClientDeveloper && data.project_phase_board.can_client_dev_manage_board) || (isWorker && data.project_phase_board.can_dev_manage_board)) && hoveringTaskId === item.id) {
                    <section class="task-actions">
                      <button id="task-menu" [matMenuTriggerFor]="menu">
                        <mat-icon id="task-menu-icon">more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu">
                        <button mat-menu-item (click)="deleteTask(item)">
                          <mat-icon style="margin-right: 10px">delete</mat-icon>
                          Quitar del tablero
                        </button>
                        <button mat-menu-item (click)="archiveTask(item)">
                          <mat-icon style="margin-right: 10px">archive</mat-icon>
                          {{ item.project_task.is_archived ? 'Desarchivar' : 'Archivar' }}
                        </button>
                      </mat-menu>
                    </section>
                  }
                </div>
              }
              @if (
                (isProjectManagerOrProductManager || (isClientDeveloper && data.project_phase_board.can_client_dev_manage_board) || (isWorker && data.project_phase_board.can_dev_manage_board)) &&
                (((column.column.project_phase_board_col_role != null && column.column.project_phase_board_col_role.slug === 'todo')) && !creatingTask)
              ) {
                <section (click)="initCreateTask(column.column)" class="create-task mb-1">
                  + Crear tarea
                </section>
              }

              @if (creatingTask && column.column.id === creatingTaskColumnId) {
                <section class="task-box creating-task">
                  <div id="create-task-form">
                    <mat-icon id="close" (click)="closeCreateTask()">close</mat-icon>
                    <textarea #creatingTaskInput type="text" (keydown.enter)="$event.preventDefault(); createTask()" [(ngModel)]="creatingTaskName" placeholder="Título de la tarea" autofocus></textarea>
                    <footer>
                      <section>
                        <mat-form-field class="task-type-select nobottom">
                          <mat-select appearance="outline" [(ngModel)]="creatingTaskSelectedType" [ngClass]="creatingTaskSelectedType">
                            @for (v of ['task','issue','story']; track v) {
                              <mat-option value="{{v}}"><img class="task-type" src="{{v | projectTaskTypeImage}}" /></mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </section>
                      <section>
                        <!-- Checbox using creatingTaskIsHotfix -->
                        <mat-checkbox id="hotfix" class="hotfix-indicator" [(ngModel)]="creatingTaskIsHotfix" color="accent"><mat-icon class="check-indicator-icon">bolt</mat-icon></mat-checkbox>
                      </section>
                      <section>
                        <mat-checkbox id="needs-testing" class="needs-testing-indicator" [(ngModel)]="creatingTaskNeedsTesting" color="accent"><mat-icon class="check-indicator-icon">science</mat-icon></mat-checkbox>
                        <!-- Checbox using creatingTaskIsHotfix -->
                      </section>
                      <section class="submit">
                        <button mat-flat-button type="submit" (click)="createTask()" [disabled]="creatingTaskName.length < 3">Crear</button>
                      </section>
                    </footer>
                  </div>
                </section>
              }
          </div>
        </div>
      }
    </div>
  </div>
  @if (!this.creatingColumn) {
    <section (click)="this.creatingColumn = true" id="add-column">
      <mat-icon>add</mat-icon>
    </section>
  }
  @if (this.creatingColumn) {
    <section class="new-column">
      <div class="column-container">
        <mat-form-field>
          <input matInput placeholder="Nombre de columna" [(ngModel)]="newColumnName" />
        </mat-form-field>
        <section class="action-buttons">
          <button [disabled]="newColumnName.length < 3" (click)="createColumn()" color="primary">
            <mat-icon>check</mat-icon>
          </button>
          <button color="warn" (click)="this.creatingColumn = false">
            <mat-icon>close</mat-icon>
          </button>
        </section>
      </div>
    </section>
  }
</section>
}
