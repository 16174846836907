import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class ClockRegisterService {

  private loading:boolean = false;

  // Schedule Time
  private statusSubject:BehaviorSubject<boolean> = new BehaviorSubject(null as any);
  public statusObservable:Observable<boolean> = this.statusSubject.asObservable();

  // Rest Time
  private restStatusSubject:BehaviorSubject<RestStatus> = new BehaviorSubject(null as any);
  public realStatusObservable:Observable<RestStatus> = this.restStatusSubject.asObservable();

  constructor(private api:ApiService,
              private user:UserService
  ) { }

  init() {
    if(!this.loading && this.user.getCurrentUser().role.slug === "worker") {
      this.loading = true;
      this.api.myClockRegisterStatus().subscribe(
        (data:any) => {
          this.loading = false;
          this.statusSubject.next(data.status==="in");
          this.restStatusSubject.next(data.rest);
        },
        error => {
          this.loading = false;
        }
      );
    }
  }

  reset() {
    this.statusSubject.next(null as any);
  }

  click(body:any = null as any) {
    if(!this.loading) {
      this.loading = true;
      return this.api.clickClockRegister(body).pipe(
        map(status => {
          this.loading = false;
          if(status.status!=="error") {
            this.statusSubject.next(status.status==='in');
            this.restStatusSubject.next(status.rest);
          }
          return status;
        }),
        catchError(error => {
          this.loading = false;
          return error;
        })
      );
    }
    else return null;
  }

  clickRest(body:any = null as any) {
    if(!this.loading) {
      return this.api.clickClockRestRegister(body).pipe(
        map(body => {
          this.loading = false;
          this.restStatusSubject.next(body.rest_status);
          return body;
        }),
        catchError(error => {
          this.loading = false;
          return error;
        })
      );
    }
    else return null;
  }
}

export interface RestStatus {
  rest_available: boolean; // et diu si poden fer o no
  rest_time_total_available_in_seconds: number; // el temps disponible. Null si no
  rest_time_consumed_in_seconds: number; // el temps consumit. Null si no
  rest_status_last_in: boolean; // si és IN l'últim status de rest. Null si no disponible
  rest_status_last_time: Date; // ultim status fitxat.
}
