import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';
import { WorkingContract } from '../models/working_contract.model';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { EmployeeCalendarRegisterWithRelationsSerialized } from '../models/employee_calendar_register.model';
import { MatDialog } from '@angular/material/dialog';
import { CalendarAssignmentDialogComponent } from '../components/main/employees/project-management/project-assignment/calendar-assignment-dialog/calendar-assignment-dialog.component';
import { EmployeeService } from './employee.service';
import { BisualSettingService } from './bisual-setting.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  // Current user session
  private localstorageUserKey:string = "bisual-user";
  private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject(null as any);
  public currentUser: Observable<User> = this.currentUserSubject.asObservable();

  constructor(
    private employee:EmployeeService,
    private bisual_setting:BisualSettingService
  ) {

  }

  public getCurrentUser(): User {
    return this.currentUserSubject.value;
  }

  public setUser(user:User, keepAlive:boolean = true) {
    if(user!=null && user!=undefined) {
      if(keepAlive) {
        localStorage.setItem(this.localstorageUserKey, JSON.stringify(user));
      }
      this.currentUserSubject.next(user);
      this.bisual_setting.init(user); // inicia employee.init()

    }
  }

  public deleteUser() {
    localStorage.removeItem(this.localstorageUserKey);
    this.currentUserSubject.next(null as any);
    this.employee.logout();
  }

}

