import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { debounceTime } from 'rxjs/operators';
import { Project } from 'src/app/models/project.model';
import { ProjectTask } from 'src/app/models/project_task.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-general-search-dialog',
  templateUrl: './general-search-dialog.component.html',
  styleUrls: ['./general-search-dialog.component.css']
})
export class GeneralSearchDialogComponent implements OnInit {
  form: UntypedFormGroup;
  suggestions: any[] = [];
  currentIndex = -1;
  me: User = null as any;
  intranetRoutes = [
    { name: 'Calendario', icon: 'calendar_today', model: 'intranet', route: ['/employees', 'my-zone', 'calendar']},
    { name: 'Control horario', icon: 'schedule', model: 'intranet', route: ['/employees', 'my-zone', 'clock-registers']},
    { name: 'Registro de trabajo', icon: 'history', model: 'intranet', route: ['/employees', 'my-zone', 'history']},
  ];

  @ViewChild('suggestionsContainer') suggestionsContainer!: ElementRef;

  constructor(public dialogRef: MatDialogRef<GeneralSearchDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private router: Router,
    private employee:EmployeeService,
    private userService: UserService) {
    this.form = this.fb.group({
      search: [''],
      intranetRoutes: [this.intranetRoutes.map((route) => route.name)]
    });
  }

  ngOnInit(): void {
    this.listenForm();
    this.customizeRoutes();
  }

  listenForm() {
    this.form.valueChanges.pipe(debounceTime(500)).subscribe((data: any) => {
      if (data.search && data.search.length >= 3) {
        this.api.generalSearch(data).subscribe((response: any) => {
          // Filtrem si s'està compartint pantalla
          if(this.employee.isMeetingOngoing()) {
            response = response.filter(
              (row:any) =>  (row.model != "project" && row.model != "task") ||
                            (row.model == "project" && this.employee.isMeetingOngoing(row.id)) ||
                            (row.model == "task" && this.employee.isMeetingOngoing(row.project_phase.project_id))
            );
          }
          this.suggestions = response;
        });
      } else {
        this.suggestions = [];
      }
    });
  }

  customizeRoutes() {
    this.me = this.userService.getCurrentUser();
    var newRoutes: { name: string; icon: string; model: string; route: string[]; }[] = [];
    if (this.me.current_working_contract.current_working_contract_variable_condition.department_category.department_name == 'Dirección') {
      newRoutes = [
        { name: 'Planificación de plantilla', icon: 'next_plan', model: 'intranet', route: ['/employees', 'rrhh', 'workers-planning']},
        { name: 'Planificación y hoja de ruta', icon: 'view_timeline', model: 'intranet', route: ['/employees', 'development', 'roadmap']},
        { name: 'Asignación de proyectos', icon: 'diversity_2', model: 'intranet', route: ['/employees', 'development', 'assignment']}
      ];
    }
    this.intranetRoutes = [...this.intranetRoutes, ...newRoutes];
    this.form.patchValue({ intranetRoutes: this.intranetRoutes.map((route) => route.name) });
  }

  onKeyDown(event: KeyboardEvent) {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      const nextIndex = Math.min(this.currentIndex + 1, this.suggestions.length - 1);
      this.scrollSuggestionIntoView(nextIndex);
      this.currentIndex = nextIndex;
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      const prevIndex = Math.max(this.currentIndex - 1, 0);
      this.scrollSuggestionIntoView(prevIndex);
      this.currentIndex = prevIndex;
    } else if (event.key === 'Enter') {
      event.preventDefault();
      if (this.currentIndex >= 0 && this.currentIndex < this.suggestions.length) {
        const suggestion = this.suggestions[this.currentIndex];
        if (suggestion.model === 'project') {
          this.goToProject(suggestion);
        } else if (suggestion.model === 'task') {
          this.goToTask(suggestion);
        } else if (suggestion.model === 'intranet') {
          this.goToIntranet(suggestion);
        } else if (suggestion.model === 'wiki') {
          this.goToWiki(suggestion);
        }
      }
    }

    // Enfoca el contenedor de sugerencias después de manejar el evento de teclado
    if (this.suggestionsContainer && this.suggestionsContainer.nativeElement && (event.key === 'ArrowDown' || event.key === 'ArrowUp')) {
      this.suggestionsContainer.nativeElement.focus();
    }
  }

  scrollSuggestionIntoView(index: number) {
    if (this.suggestionsContainer && this.suggestionsContainer.nativeElement) {
      const suggestionElements = this.suggestionsContainer.nativeElement.querySelectorAll('.suggestion');
      if (suggestionElements.length > 0 && index >= 0 && index < suggestionElements.length) {
        const selectedSuggestion = suggestionElements[index];
        selectedSuggestion.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      }
    }
  }

  goToProject(project: Project) {
    this.router.navigate(['/employees', 'development', 'projects', project.id]);
    this.dialogRef.close();
  }

  goToTask(task: ProjectTask) {
    if (task.project_task_boards.length) {
      this.router.navigate([
        '/employees',
        'development',
        'projects',
        task.project_phase.project_id,
        'phases',
        task.project_phase_id,
        'boards',
        task.project_task_boards[0].project_phase_board_col.project_phase_board_id
      ], { queryParams: { selectedIssue: task.code } });
    } else {
      this.router.navigate([
        '/employees',
        'development',
        'projects',
        task.project_phase.project_id,
        'phases',
        task.project_phase_id,
        'backlog'
      ], { queryParams: { selectedIssue: task.code } });
    }
    this.dialogRef.close();
  }

  goToIntranet(route: any) {
    route = this.intranetRoutes.find((r) => r.name === route.name);
    if (route) {
      this.router.navigate(route.route);
      this.dialogRef.close();
    }
  }

  goToWiki(route: any) {
    this.router.navigate(['/employees', 'wiki', route.wiki_node_id, 'article', route.id]);
    this.dialogRef.close();
  }


  findSuggestionIcon(route: any) {
    route = this.intranetRoutes.find((r) => r.name === route.name);
    if (route) {
      return route.icon;
    } else return 'laptop_mac';
  }
}
