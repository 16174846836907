import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ClockRegisterService } from 'src/app/services/clock-register.service';
import { CustomerService } from 'src/app/services/customer.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { ForceClockRegisterDialogComponent } from '../main-dialogs/force-clock-register-dialog/force-clock-register-dialog.component';
import { GeneralSearchDialogComponent } from 'src/app/components/utils/general-search-dialog/general-search-dialog.component';
import { AddNewWorkingTimeDialogComponent } from '../my-zone/working-times-history/add-new-working-time-dialog/add-new-working-time-dialog.component';
import { WorkingTime } from 'src/app/models/working_time.model';
import { IpServiceService } from 'src/app/services/ip-service.service';
import { NewTrainingFormationRequestDialogComponent } from './new-training-formation-request-dialog/new-training-formation-request-dialog.component';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-top-menu-employee',
  templateUrl: './top-menu-employee.component.html',
  styleUrls: ['./top-menu-employee.component.css']
})
export class TopMenuEmployeeComponent implements OnInit {
  clientSelectControl = new FormControl();
  customerSelected: Company = null as any;
  available_customers: Company[] = [];
  showHeaderMenu: boolean = false;
  me: User = null as any;

  nextTaskTitle:string = null as any;
  nextStartDate: Date = null as any;
  currentTaskTitle:string = null as any;

  // Clock Schedule Status
  clock_schedule_status:boolean = false;

  // Clock Rest Status
  clock_rest_available:boolean = false;
  clock_rest_total:number = null as any;
  clock_rest_consumed:number = null as any;
  rest_status_last_in:boolean = null as any;
  rest_status_last_time:Date = null as any;
  colorRestSpinner:string = "accent";

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private clock: ClockRegisterService,
    private customerService: CustomerService,
    private api: ApiService,
    public permissions: PermissionsService,
    public userService: UserService,
    private dialog:MatDialog,
    private ip:IpServiceService,
    public employee:EmployeeService
  ) {

  }

  ngOnInit(): void {
    this.checkStartAttribute();
    this.listenCustomerSelected();
    this.listenControlSearch();
    this.me = this.userService.getCurrentUser();
    this.subscribeCurrentTask();
    this.listenClockScheduleStatus();
    this.listenClockRestStatus();
  }

  clickClock(justification:string = null as any, clock_register_mood_id:number = null as any) {
    this.clock.click({justification: justification, clock_register_mood_id: clock_register_mood_id})?.subscribe(
      status => {
        this.handleClick(status);
      }
    );
  }

  clickClockRest() {
    this.ip.getIPAddress().subscribe((data:any)=>{
      this.clock.clickRest({ip: data.ip})?.subscribe(
        data => {

        }
      );
    });
  }

  selectCustomer(c: Company) {
    this.customerService.selectCustomer(c);
  }

  resetCustomer() {
    this.available_customers = [];
    this.clientSelectControl.setValue('');
    this.customerService.reset();
  }

  displayFn(company: Company): string {
    return company ? company.fact_name : '';
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/auth', 'login']);
  }

  valueRestTime() {
    if(this.rest_status_last_time==null || this.clock_rest_total==null || this.clock_rest_consumed == null) return 0;

    const availableSecondsSinceLastApiCall:number = this.clock_rest_consumed;
    let value = availableSecondsSinceLastApiCall;
    if(this.rest_status_last_in) {
      value -= moment(this.rest_status_last_time).diff(moment(), 'seconds');
    }

    if(value<0) {
      value = 100-value;
      this.colorRestSpinner = "warn";
    }
    else this.colorRestSpinner = "accent";

    return value / this.clock_rest_total * 100;
  }

  openGeneralSearch() {
    const dialogRef = this.dialog.open(GeneralSearchDialogComponent, {
      width: '650px',
      data: { },
      panelClass: 'custom-dialog-container'
    });
  }

  openBisualBudgetRequest() {
    this.api.getBisualSetting('bisual_budget_request_url').subscribe(data => {
      window.open(data.value, '_blank');
    });
  }

  addWorkingTime() {
    const dialogRef = this.dialog.open(AddNewWorkingTimeDialogComponent, {
      width: '650px',
      data: { }
    });

    dialogRef.afterClosed().toPromise().then(
      (data:WorkingTime) => {
        if(data!=null) {
          this.api.createWorkingTime(data).toPromise().then(
            data => {

            }
          );
        }
      }
    );
  }

  openFormationDialog() {
    const dialogRef = this.dialog.open(NewTrainingFormationRequestDialogComponent, {
      width: '700px'
    });

  }

  private checkStartAttribute() {
    const start_attribute =
      this.activatedRoute.snapshot.queryParamMap.get('start');

    if (start_attribute != null) {
      this.router.navigate(this.activatedRoute.snapshot.url, {
        queryParams: {},
      });
    }
  }

  private listenCustomerSelected() {
    this.customerService.customerObservable.subscribe((data) => {
      this.customerSelected = data;
    });
  }

  private listenControlSearch() {
    this.clientSelectControl.valueChanges.subscribe((search_string: string) => {
      if (search_string != null && search_string.length >= 3) {
        const params = {
          search: search_string,
        };
        this.api.getCompanies(params).subscribe((data) => {
          this.available_customers = data.data;
        });
      } else this.available_customers = [];
    });
  }

  private handleClick(status:any) {
    if(status.status==="error") {
      const dialogRef = this.dialog.open(ForceClockRegisterDialogComponent, { width: '500px', data: { status: status } });
      dialogRef.afterClosed().subscribe(
        body => {
          if(body!=null && body.force===true) {
            this.clickClock(body.justification, body.clock_register_mood_id);
          }
        }
      );
    }
  }

  private subscribeCurrentTask() {
    this.employee.currentTask.subscribe((data:{ current: any , next: any }) => {
      this.currentTaskTitle = data.current != null ? data.current.title : null as any;
      this.nextTaskTitle = data.next!=null ? data.next.title : null;
      this.nextStartDate = data.next!=null ? data.next.start : null as any;
    });
  }

  private listenClockScheduleStatus() {
    this.clock.statusObservable.subscribe(
      status => {
        this.clock_schedule_status = status;
      }
    );
  }

  private listenClockRestStatus() {
    this.clock.realStatusObservable.subscribe(
      data => {
        if(data != null) {
          this.clock_rest_available = data.rest_available;
          this.clock_rest_total = data.rest_time_total_available_in_seconds;
          this.clock_rest_consumed = data.rest_time_consumed_in_seconds;
          this.rest_status_last_in = data.rest_status_last_in;
          this.rest_status_last_time = data.rest_status_last_time;
        }
      }
    );
  }

  cleanShareScreen() {
    if(confirm("¿Estás seguro que quieres desactivar el modo reunión? Recuerda que si compartes pantalla debes tener este modo activado. Una vez lo desactives el programa no volverá a pedírtelo para este evento y tendrás que activarlo tú manualmente.")) {
      this.employee.stopMeetingMode();
    }
  }
}
