<h1 mat-dialog-title>{{ isCreate ? 'Crear nuevo ordenador' : 'Editar ordenador' }}</h1>

<mat-dialog-content [formGroup]="form">
  <div class="container">
    <div class="row pt-3">
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Modelo</mat-label>
          <input type="text" matInput formControlName="model" placeholder="Por ejemplo: Macbook Pro 2025" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Fecha de compra</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="buyed_at">
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Memoria RAM</mat-label>
          <input type="number" min="1" matInput formControlName="ram_memory_in_gb" />
          <span matSuffix>GB</span>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Memoria SSD</mat-label>
          <input type="number" min="1" matInput formControlName="ssd_memory_in_gb" />
          <span matSuffix>GB</span>
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Número de serie</mat-label>
          <input type="text" matInput formControlName="serial_number" />
        </mat-form-field>
      </div>
      <div class="col-12 col-lg-6">
        <mat-form-field appearance="outline">
          <mat-label>Procesador / Chip</mat-label>
          <input type="text" matInput formControlName="chip" placeholder="Por ejemplo: M1 Pro" />
        </mat-form-field>
      </div>
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Holded: ID de Activo</mat-label>
          <input type="text" matInput formControlName="holded_asset_id" />
          <mat-hint>Antes de crear el ordenador aquí, asegúrate de haber creado el <a href="https://app.holded.com/accounting/assets" target="_blank">activo en Holded</a> y sus tablas de amortización.</mat-hint>
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button (click)="close()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-flat-button color="accent" (click)="submit()" [disabled]="!form.valid" type="submit">
    <mat-icon style="margin-right: 10px">save</mat-icon>
    Guardar ordenador
  </button>
</mat-dialog-actions>
