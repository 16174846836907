<h1 mat-dialog-title>
  {{event.title}}{{(event.subtitle!=null ? (', ' + event.subtitle) : '')}}{{(event.project_phase_name!=null ? (', ' + event.project_phase_name) : '')}}
</h1>

<mat-dialog-content [formGroup]="form">
  <!-- start date and end date form -->
  <div class="row">
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Título personalizado</mat-label>
        <input matInput type="text" formControlName="custom_title" />
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Enlace de reunión</mat-label>
        <input matInput type="text" formControlName="call_link" />
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline" class="w-100">
          <mat-label>Fecha de inicio</mat-label>
          <input matInput type="datetime-local" formControlName="start">
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-6">
      <mat-form-field appearance="outline" class="w-100">
          <mat-label>Fecha final</mat-label>
          <input matInput type="datetime-local" formControlName="end">
      </mat-form-field>
    </div>
  </div>
  <div class="row">
    <div class="col d-flex justify-content-between">
      <button mat-button (click)="addMinutes(5)">+5m</button>
      <button mat-button (click)="addMinutes(10)">+10m</button>
      <button mat-button (click)="addMinutes(30)">+30m</button>
      <button mat-button (click)="addMinutes(60)">+1h</button>
      <button mat-button (click)="addMinutes(90)">+1h 30m</button>
      <button mat-button (click)="addMinutes(120)">+2h</button>
      <button mat-button (click)="addMinutes(180)">+3h</button>
    </div>
  </div>

  @if((ecr.wtc_is_shareable || ecr.ecr_wtc_is_shareable) && shareable_user_wwtbes.length) {
    <div class="row">
      <div class="col-12">
        <mat-form-field appearance="outline">
          <mat-label>Compartir evento con</mat-label>
          <mat-select formControlName="shared_users" multiple>
            <mat-select-trigger>
              {{form.value.shared_users.length + ' usuarios'}}
            </mat-select-trigger>
            @for (wwtbe of shareable_user_wwtbes; track wwtbe) {
              <mat-option [value]="wwtbe.user_id">
                {{ wwtbe.user_name + ' ' + wwtbe.user_surnames }}
                <span style="display: block; font-size: 10px; font-style: italic; margin: 0; line-height: 1;">Horas asignadas: {{ wwtbe.hours | hoursToHourString }}</span>
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  }
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial (click)="close()">
    <mat-icon>close</mat-icon>
    Cerrar
  </button>
  <button mat-flat-button color="accent" (click)="save()">
    <mat-icon>save</mat-icon>
    Actualizar
  </button>
</mat-dialog-actions>
