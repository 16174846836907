import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Department } from 'src/app/models/department.model';
import { Role } from 'src/app/models/role.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { TokenService } from 'src/app/services/token.service';
import { ChangePasswordDialogComponent } from './change-password-dialog/change-password-dialog.component';
import { RegexService } from 'src/app/services/regex.service';
import { Company } from 'src/app/models/company.model';
import { UserService } from 'src/app/services/user.service';
import { User } from 'src/app/models/user.model';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { DepartmentCategory } from 'src/app/models/department_category.model';
import * as Countries from 'src/assets/json/countries.json';
import * as moment from 'moment';
import { WorkingContractType } from 'src/app/models/working_contract_type.model';

@Component({
  selector: 'app-users-detail',
  templateUrl: './users-detail.component.html',
  styleUrls: ['./users-detail.component.css']
})
export class UsersDetailComponent implements OnInit {

  user_id:string = null as any;

  inheritIdContent:boolean = false;

  userForm:UntypedFormGroup;
  userContractForm:UntypedFormGroup;
  companyForm:UntypedFormGroup;
  activeContract:WorkingContract = null as any;

  roles:Role[] = [];
  departments:Department[] = [];
  available_customers:Company[] = [];

  clientSelectControl = new UntypedFormControl();
  customerSelected:Company = null as any;
  department_categories: DepartmentCategory[] = [];
  countries:any[] = (Countries as any).default;
  user:User = null as any;

  itsMe:boolean = false;
  me:User = null as any;
  working_contract_types:WorkingContractType[] = [];

  constructor(private activatedRoute:ActivatedRoute,
              private api:ApiService,
              private fb:UntypedFormBuilder,
              public permissions:PermissionsService,
              public router:Router,
              public dialog: MatDialog,
              private tokenService:TokenService,
              private regex:RegexService,
              private userService:UserService) {

    const regexPatterns = this.regex.getRegex();

    this.userForm = this.fb.group({
      name: ['', Validators.compose([Validators.required])],
      surnames: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required, Validators.email])],
      sex: ['', Validators.compose([Validators.required])],
      birthday: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required, Validators.pattern(regexPatterns['phone'])])],
      role_id: ['', Validators.compose([Validators.required])],
      company_id: [''],
    });

    this.userForm.controls['email'].disable();

    this.userContractForm = this.fb.group({
      department_category_id:['', Validators.compose([Validators.required])],
      working_contract_type_id: ['', Validators.compose([Validators.required])],
      start_date: ['', Validators.compose([Validators.required])],
      end_date: ['', Validators.compose([])],
      job_description: ['', Validators.compose([Validators.required])],
      number_of_payments_per_year: ['', Validators.compose([Validators.required, Validators.min(12), Validators.max(14)])],
      price_per_hour: ['', Validators.compose([])],
      brute_anual_base_salary: ['', Validators.compose([Validators.required, Validators.min(14000)])],
      monday_morning_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      monday_morning_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      monday_afternoon_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      monday_afternoon_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      tuesday_morning_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      tuesday_morning_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      tuesday_afternoon_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      tuesday_afternoon_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      wednesday_morning_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      wednesday_morning_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      wednesday_afternoon_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      wednesday_afternoon_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      thursday_morning_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      thursday_morning_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      thursday_afternoon_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      thursday_afternoon_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      friday_morning_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      friday_morning_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      friday_afternoon_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      friday_afternoon_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      saturday_morning_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      saturday_morning_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      saturday_afternoon_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      saturday_afternoon_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      sunday_morning_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      sunday_morning_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      sunday_afternoon_entry_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      sunday_afternoon_exit_time: ['', Validators.compose([Validators.min(7), Validators.max(20)])],
      guardian_weekly_salary: ['', Validators.compose([])],
      guardian_trigger_attended_salary: ['', Validators.compose([])],
      guardian_price_per_hour: ['', Validators.compose([])],
      hours_per_week_expected: ['', Validators.compose([Validators.min(20), Validators.max(40), Validators.required])],
      has_remote_work_monday: ['', Validators.compose([Validators.required])],
      has_remote_work_tuesday: ['', Validators.compose([Validators.required])],
      has_remote_work_wednesday: ['', Validators.compose([Validators.required])],
      has_remote_work_thursday: ['', Validators.compose([Validators.required])],
      has_remote_work_friday: ['', Validators.compose([Validators.required])],
      has_remote_work_saturday: ['', Validators.compose([Validators.required])],
      has_remote_work_sunday: ['', Validators.compose([Validators.required])],
      flexible_remuneration_monthly_quantity: ['', Validators.compose([])],
      health_insurance_contract_id: ['', Validators.compose([])],
      possible_future_early_retirement: ['', Validators.compose([Validators.required])],
      possible_future_retirement_plan: ['', Validators.compose([Validators.required])],
    });

    this.userContractForm.disable();

    this.companyForm = this.fb.group({
      fact_name: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      phone: ['', Validators.compose([Validators.required])],
      fact_address: '',
      fact_city: '',
      fact_zipcode: '',
      fact_county: '',
      fact_country: '',
      vat: ['', Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.inheritIdContent = this.router.url.includes("/direction/users-management/users");
    this.fetchRolesAndDepartments();
    this.user_id = (this.activatedRoute.snapshot.paramMap.get('id') ?? this.userService.getCurrentUser().id) as string;
    this.listenControlSearch();
    this.listenMe();
    this.fetchWorkingContractTypes();
    this.fetchUserAndContract();
  }

  canEdit() {
    return this.itsMe || (this.permissions.validateDepartment(['direction', 'rrhh']));
  }

  saveUser() {
    const body = { ...this.userForm.value };
    if (body.birthday != null) body.birthday = moment(body.birthday).format('YYYY-MM-DD');

    this.api.updateUser(this.user_id, body).subscribe(
      data => {

      }
    );
  }

  saveCompany() {
    this.api.updateCompany(this.customerSelected.id.toString(), this.companyForm.value).subscribe(
      data => {

      }
    );
  }

  saveContract() {
    if(confirm("¿Estás seguro que quieres actualizar este contrato? Esta acción creará una nueva VariableCondition.")) {
      this.api.updateWorkingContract(this.activeContract.id.toString(), this.userContractForm.value).subscribe(
        data => {

        }
      );
    }
  }

  delete() {
    if(confirm("¿Estás seguro que quieres eliminar este usuario?")) {
      this.api.deleteUser(+this.user_id).subscribe(
        data => {
          this.router.navigate(['/employees', 'direction', 'users-management', 'users']);
        }
      );
    }
  }

  changePassword(): void {
    const dialogRef = this.dialog.open(ChangePasswordDialogComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((password:string) => {
      if(password != null) {
        this.api.changeUserPassword(this.user_id, password).subscribe(
          data => {

          }
        );
      }
    });
  }

  selectCustomer(c:Company) {
    this.userForm.patchValue({company_id: c.id});
  }

  resetCustomer() {
    this.available_customers = [];
    this.userForm.patchValue({company_id: null});
  }

  displayFn(company:Company): string {
    return company ? company.fact_name : '';
  }

  private fetchUserAndContract() {
    this.api.getUser(this.user_id, { with: 'current_working_contract.current_working_contract_variable_condition.department_category.active_department_category_salary_condition,role' }).subscribe(
      data => {
        this.processUserData(data);
        this.processContractData(data.current_working_contract);
      }
    );
  }

  private processUserData(data:User) {
    this.user = data;
    this.userForm.patchValue({
      name: data.name,
      surnames: data.surnames,
      email: data.email,
      sex: data.sex,
      birthday: data.birthday,
      phone: data.phone,
      role_id: data.role_id,
      company_id: data.company_id,
    });

    this.refreshItsMe();

    if(data.company_id!=null) {
      this.fetchCompany();
    }
  }

  private processContractData(data:WorkingContract) {
      this.activeContract = data;
      this.userContractForm.patchValue({
        department_category_id: data.current_working_contract_variable_condition.department_category_id,
        working_contract_type_id: data.working_contract_type_id,
        start_date: data.start_date,
        end_date: data.end_date,
        job_description: data.job_description,
        number_of_payments_per_year: data.number_of_payments_per_year,
        price_per_hour: data.current_working_contract_variable_condition.price_per_hour,
        brute_anual_base_salary: data.current_working_contract_variable_condition.brute_anual_base_salary,
        monday_morning_entry_time: data.current_working_contract_variable_condition.monday_morning_entry_time,
        monday_morning_exit_time: data.current_working_contract_variable_condition.monday_morning_exit_time,
        monday_afternoon_entry_time: data.current_working_contract_variable_condition.monday_afternoon_entry_time,
        monday_afternoon_exit_time: data.current_working_contract_variable_condition.monday_afternoon_exit_time,
        tuesday_morning_entry_time: data.current_working_contract_variable_condition.tuesday_morning_entry_time,
        tuesday_morning_exit_time: data.current_working_contract_variable_condition.tuesday_morning_exit_time,
        tuesday_afternoon_entry_time: data.current_working_contract_variable_condition.tuesday_afternoon_entry_time,
        tuesday_afternoon_exit_time: data.current_working_contract_variable_condition.tuesday_afternoon_exit_time,
        wednesday_morning_entry_time: data.current_working_contract_variable_condition.wednesday_morning_entry_time,
        wednesday_morning_exit_time: data.current_working_contract_variable_condition.wednesday_morning_exit_time,
        wednesday_afternoon_entry_time: data.current_working_contract_variable_condition.wednesday_afternoon_entry_time,
        wednesday_afternoon_exit_time: data.current_working_contract_variable_condition.wednesday_afternoon_exit_time,
        thursday_morning_entry_time: data.current_working_contract_variable_condition.thursday_morning_entry_time,
        thursday_morning_exit_time: data.current_working_contract_variable_condition.thursday_morning_exit_time,
        thursday_afternoon_entry_time: data.current_working_contract_variable_condition.thursday_afternoon_entry_time,
        thursday_afternoon_exit_time: data.current_working_contract_variable_condition.thursday_afternoon_exit_time,
        friday_morning_entry_time: data.current_working_contract_variable_condition.friday_morning_entry_time,
        friday_morning_exit_time: data.current_working_contract_variable_condition.friday_morning_exit_time,
        friday_afternoon_entry_time: data.current_working_contract_variable_condition.friday_afternoon_entry_time,
        friday_afternoon_exit_time: data.current_working_contract_variable_condition.friday_afternoon_exit_time,
        saturday_morning_entry_time: data.current_working_contract_variable_condition.saturday_morning_entry_time,
        saturday_morning_exit_time: data.current_working_contract_variable_condition.saturday_morning_exit_time,
        saturday_afternoon_entry_time: data.current_working_contract_variable_condition.saturday_afternoon_entry_time,
        saturday_afternoon_exit_time: data.current_working_contract_variable_condition.saturday_afternoon_exit_time,
        sunday_morning_entry_time: data.current_working_contract_variable_condition.sunday_morning_entry_time,
        sunday_morning_exit_time: data.current_working_contract_variable_condition.sunday_morning_exit_time,
        sunday_afternoon_entry_time: data.current_working_contract_variable_condition.sunday_afternoon_entry_time,
        sunday_afternoon_exit_time: data.current_working_contract_variable_condition.sunday_afternoon_exit_time,
        guardian_weekly_salary: data.current_working_contract_variable_condition.guardian_weekly_salary,
        guardian_trigger_attended_salary: data.current_working_contract_variable_condition.guardian_trigger_attended_salary,
        guardian_price_per_hour: data.current_working_contract_variable_condition.guardian_price_per_hour,
        hours_per_week_expected: data.current_working_contract_variable_condition.hours_per_week_expected,
        has_remote_work_monday: data.current_working_contract_variable_condition.has_remote_work_monday,
        has_remote_work_tuesday: data.current_working_contract_variable_condition.has_remote_work_tuesday,
        has_remote_work_wednesday: data.current_working_contract_variable_condition.has_remote_work_wednesday,
        has_remote_work_thursday: data.current_working_contract_variable_condition.has_remote_work_thursday,
        has_remote_work_friday: data.current_working_contract_variable_condition.has_remote_work_friday,
        has_remote_work_saturday: data.current_working_contract_variable_condition.has_remote_work_saturday,
        has_remote_work_sunday: data.current_working_contract_variable_condition.has_remote_work_sunday,
        flexible_remuneration_monthly_quantity: data.current_working_contract_variable_condition.flexible_remuneration_monthly_quantity,
        health_insurance_contract_id: data.current_working_contract_variable_condition.health_insurance_contract_id,
        possible_future_early_retirement: data.current_working_contract_variable_condition.possible_future_early_retirement,
        possible_future_retirement_plan: data.current_working_contract_variable_condition.possible_future_retirement_plan,
      });

      if(this.user.role.slug === "worker" && this.permissions.validateDepartment(['direction', 'rrhh'])) {
        this.enableUserContractForm();
      }
  }

  private fetchCompany() {
    this.api.getCompany(this.userForm.value['company_id']).subscribe(
      data => {
        this.customerSelected = data;
        this.clientSelectControl.setValue(data);
        this.companyForm.patchValue(data);
      }
    );
  }

  private fetchRolesAndDepartments() {
    this.permissions.rolesObservable.subscribe(
      data => {
        if(data != null) {
          this.roles = Object.keys(data).map(k => data[k]);
        }
      }
    );

    this.permissions.departmentCategoriesObservable.subscribe(
      data => {
        if(data != null) {
          this.department_categories = Object.values(data);
        }
      }
    );
  }

  private listenControlSearch() {
    this.clientSelectControl.valueChanges.subscribe(
      (search_string:string) => {
        if(search_string != null && search_string.length >= 3) {
          const params = {
            search: search_string
          };
          this.api.getCompanies(params).subscribe(
            data => {
              this.available_customers = data.data;
            }
          );
        }
        else this.available_customers = [];
      }
    );
  }

  private listenMe() {
    this.userService.currentUser.subscribe(
      data => {
        this.me = data;
        this.refreshItsMe();
      }
    );
  }

  private refreshItsMe() {
    if(this.userForm.value['name']!='' && this.me != null) {
      this.itsMe = (+this.user_id) == this.me.id || this.activatedRoute.snapshot.url.map(seg => seg.path).indexOf('my-profile') > -1;
    }
  }

  private fetchWorkingContractTypes() {
    this.api.getWorkingContractsTypes().subscribe(
      data => {
        this.working_contract_types = data;
      }
    );
  }

  private enableUserContractForm() {
    this.userContractForm.controls['department_category_id'].enable();
    this.userContractForm.controls['end_date'].enable();
    this.userContractForm.controls['job_description'].enable();
    this.userContractForm.controls['price_per_hour'].enable();
    this.userContractForm.controls['brute_anual_base_salary'].enable();
    this.userContractForm.controls['monday_morning_entry_time'].enable();
    this.userContractForm.controls['monday_morning_exit_time'].enable();
    this.userContractForm.controls['monday_afternoon_entry_time'].enable();
    this.userContractForm.controls['monday_afternoon_exit_time'].enable();
    this.userContractForm.controls['tuesday_morning_entry_time'].enable();
    this.userContractForm.controls['tuesday_morning_exit_time'].enable();
    this.userContractForm.controls['tuesday_afternoon_entry_time'].enable();
    this.userContractForm.controls['tuesday_afternoon_exit_time'].enable();
    this.userContractForm.controls['wednesday_morning_entry_time'].enable();
    this.userContractForm.controls['wednesday_morning_exit_time'].enable();
    this.userContractForm.controls['wednesday_afternoon_entry_time'].enable();
    this.userContractForm.controls['wednesday_afternoon_exit_time'].enable();
    this.userContractForm.controls['thursday_morning_entry_time'].enable();
    this.userContractForm.controls['thursday_morning_exit_time'].enable();
    this.userContractForm.controls['thursday_afternoon_entry_time'].enable();
    this.userContractForm.controls['thursday_afternoon_exit_time'].enable();
    this.userContractForm.controls['friday_morning_entry_time'].enable();
    this.userContractForm.controls['friday_morning_exit_time'].enable();
    this.userContractForm.controls['friday_afternoon_entry_time'].enable();
    this.userContractForm.controls['friday_afternoon_exit_time'].enable();
    this.userContractForm.controls['saturday_morning_entry_time'].enable();
    this.userContractForm.controls['saturday_morning_exit_time'].enable();
    this.userContractForm.controls['saturday_afternoon_entry_time'].enable();
    this.userContractForm.controls['saturday_afternoon_exit_time'].enable();
    this.userContractForm.controls['sunday_morning_entry_time'].enable();
    this.userContractForm.controls['sunday_morning_exit_time'].enable();
    this.userContractForm.controls['sunday_afternoon_entry_time'].enable();
    this.userContractForm.controls['sunday_afternoon_exit_time'].enable();
    this.userContractForm.controls['guardian_weekly_salary'].enable();
    this.userContractForm.controls['guardian_trigger_attended_salary'].enable();
    this.userContractForm.controls['guardian_price_per_hour'].enable();
    this.userContractForm.controls['hours_per_week_expected'].enable();
    this.userContractForm.controls['has_remote_work_monday'].enable();
    this.userContractForm.controls['has_remote_work_tuesday'].enable();
    this.userContractForm.controls['has_remote_work_wednesday'].enable();
    this.userContractForm.controls['has_remote_work_thursday'].enable();
    this.userContractForm.controls['has_remote_work_friday'].enable();
    this.userContractForm.controls['has_remote_work_saturday'].enable();
    this.userContractForm.controls['has_remote_work_sunday'].enable();
    this.userContractForm.controls['possible_future_early_retirement'].enable();
    this.userContractForm.controls['possible_future_retirement_plan'].enable();
    this.userContractForm.controls['flexible_remuneration_monthly_quantity'].enable();
  }

}
