@if (status!=null) {
  <h1 mat-dialog-title style="display: flex; align-items: center;">
    <mat-icon style="margin-right: 10px;" [ngStyle]="{ 'color': status.flag=='far' ? 'orange' : 'red' }">
      {{ status.flag=='far' ? 'warning' : 'error' }}
    </mat-icon>
    Alerta al fichar
  </h1>
}
@if (status!=null) {
  <mat-dialog-content>
    @if (status.flag == 'remote-work') {
      🏠 Estás fichando desde fuera de la oficina. 🏠
      Y teoricamente hoy no tenías teletrabajo. ¿Seguro que quieres fichar?
    } @else {
      Has fichado a una hora que no teníamos previsto que ficharas (<i>Teóricamente a las {{ status.time }}</i>). ¿Seguro que quieres fichar?
    }
    @if (random==4) {
      <h5>🤡🤡 Felicidades has encontrado al bisual payaso de la suerte 🤡🤡</h5>
    }
    <br>
      <br>
        Puedes introducir una justificación:
        <mat-form-field appearance="outline" [ngStyle]="{ display: 'block' }">
          <textarea [(ngModel)]="justification" rows="2" matInput style="resize: none" placeholder="P.ej: 'Tengo que salir antes.'" cdkFocusInitial></textarea>
        </mat-form-field>
        @if (needs_mood) {
          <mat-button-toggle-group name="moods" id="moods">
            @for (mood of moods; track mood) {
              <mat-button-toggle
                [value]="mood.id"
                class="{{ mood.mood_type }}"
                [checked]="clock_register_mood_id===mood.id"
                (change)="$event.source.checked ? (clock_register_mood_id = $event.source.value) : null"
                >
                <span class="emoji">{{ mood.emoji }}</span>
                {{ mood.name }}
              </mat-button-toggle>
            }
          </mat-button-toggle-group>
        }
      </mat-dialog-content>
    }
    <mat-dialog-actions align="end">
      <button mat-button mat-dialog-close>Cancelar (No fichar)</button>
      <button mat-flat-button (click)="click()" color="accent" [disabled]="needs_mood && clock_register_mood_id==null">Forzar fichaje</button>
    </mat-dialog-actions>
