import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-force-setup-do-not-disturb-shortcuts-dialog',
  templateUrl: './force-setup-do-not-disturb-shortcuts-dialog.component.html',
  styleUrl: './force-setup-do-not-disturb-shortcuts-dialog.component.css'
})
export class ForceSetupDoNotDisturbShortcutsDialogComponent implements OnInit {

  selectedIndex:number = 0;
  @ViewChild(MatStepper, { static: true }) stepper:MatStepper;

  constructor(public dialogRef: MatDialogRef<ForceSetupDoNotDisturbShortcutsDialogComponent>,
              private employees:EmployeeService
  ) {}

  ngOnInit(): void {

  }

  recheck() {
    this.employees.recheckToForceSetupDoNotDisturbShortcutsDialog();
  }

  next() {
    this.selectedIndex++;
    this.stepper.next();
  }

  previous() {
    this.selectedIndex--;
    this.stepper.previous();
  }
}
