import packageInfo from '../../package.json';

export const environment = {
  production: true,
  laravel_url: "https://testapi.bisual.net",
  tiny_api_keys: [
    "n2e99sz8a34zy9lgpjkkuyr19mcm7ufwh6q02k0o6a2zrq9l", // developers.bisual@gmail.com
    "lt1rkkdp9iq039tqtgxk9b3lcepg8sxuq0x27gwn42o7w5nh", // consultoria.bisual@gmail.com
    "53m6sjdr3ns0zq7a57saj126abdcngq0kkk44ebkd7molrjc", // agencia.bisual@gmail.com
    "mhuo3av0ffa258l5ba81nbzowgakyl8cai8aqypedssllg9z",  // polribas14@gmail.com
  ],
  version: packageInfo.version,
};
