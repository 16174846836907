<h1 mat-dialog-title>Actualiza la App</h1>

<mat-dialog-content>
  <p>
    Estás en la versión <b>v{{ data.current_version }}</b> y ya está disponible la <b>v{{ data.new_version }}</b> desde el pasado <b>{{ data.uploaded_at.toDate() | date:'short' }}</b>.
    @if(data.block) {
      Como ha pasado ya más de 1 dia y no la habías instalado, ahora estás obligado a instalarla para seguir usando el programa.
    }
    @else {
      Tienes 24 horas desde el momento de la publicación de la release para instalarla. Sino se bloqueará el programa y te forzará a instalarlo en algún momento que quizás no vas a querer actualizar.
    }
  </p>

  <ng-container *ngIf="download$ | async as download">
    <mat-progress-bar
      [mode]="download.state == 'PENDING' ? 'buffer' : 'determinate'"
      [value]="download.progress"
    ></mat-progress-bar>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  @if(!data.block && (!download$ || ((download$ | async)!.state !== "DONE"))) {
    <button mat-stroked-button color="accent" (click)="close()">Más tarde</button>
  }

  @if(!download$) {
    <button mat-flat-button color="accent" (click)="startDownload()">
      <mat-icon>download</mat-icon>
      Descargar ahora
    </button>
  }

  <ng-container *ngIf="download$ | async as download">
    @if(download.state !== "DONE") {
      <button mat-flat-button color="accent" [disabled]="true">
        <mat-icon>download</mat-icon>
        Descargando
      </button>
    }
  </ng-container>
</mat-dialog-actions>
