<header>
  <div id="logo-container">
    <div id="logo">
      <img src="assets/img/logo/logo-bisual-black.png" alt="bisual" />
      <span id="logo-subtitle">Clients</span>
    </div>
  </div>
  <div id="header-menu-container">
    <button
      mat-icon-button
      (click)="showHeaderMenu = !showHeaderMenu"
      id="toggle-menu-header"
      >
      @if (!showHeaderMenu) {
        <mat-icon>expand_more</mat-icon>
      }
      @if (showHeaderMenu) {
        <mat-icon>expand_less</mat-icon>
      }
    </button>
    <div id="header-menu-content" [ngClass]="{ show: showHeaderMenu }">
      <button
        id="account-button"
        mat-stroked-button
        [matMenuTriggerFor]="account_menu"
        >
        <img [src]="me.profile_picture_url" />
        <span id="me-container">
          {{ me.name + " " + me.surnames }}
          @if (me.company) {
            <span class="company">
              {{ me.client_role != null ? (me.client_role | titlecase) + ' - ' : '' }}
              {{ me.company.fact_name }}
            </span>
          }
        </span>

        <mat-icon>expand_more</mat-icon>
      </button>

      <mat-menu #account_menu="matMenu" xPosition="before">
        <button
          mat-menu-item [routerLink]="['clients','my-profile']"
          >
          <mat-icon>account_circle</mat-icon>
          Mi cuenta
        </button>
        <button mat-menu-item (click)="logout()">
          <mat-icon>logout</mat-icon>
          Cerrar sesión
        </button>
      </mat-menu>
    </div>
  </div>
</header>
