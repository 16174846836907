import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as moment from 'moment';
import { ApexAnnotations, ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexForecastDataPoints, ApexLegend, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent, PointAnnotations } from 'ng-apexcharts';
import { DepartmentCategory } from 'src/app/models/department_category.model';
import { DepartmentCategorySalaryCondition } from 'src/app/models/department_category_salary_condition.model';
import { HealthInsuranceContract } from 'src/app/models/health_insurance_contract.model';
import { HealthInsurancePlan } from 'src/app/models/health_insurance_plan.model';
import { WorkingContractCareerPlan } from 'src/app/models/working_contract_career_plan.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  stroke: ApexStroke;
  tooltip: ApexTooltip;
  dataLabels: ApexDataLabels;
  colors: string[];
  legend: ApexLegend;
  annotations: ApexAnnotations;
};

class YearDateAdapter extends NativeDateAdapter {
  format(date: Date): string {
    return `${date.getFullYear()}`;
  }
}

@Component({
  selector: 'app-my-career-plan',
  templateUrl: './my-career-plan.component.html',
  styleUrl: './my-career-plan.component.css',
  providers: [
    { provide: DateAdapter, useClass: YearDateAdapter },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        parse: { dateInput: 'YYYY' },
        display: { dateInput: 'YYYY', monthYearLabel: 'YYYY', dateA11yLabel: 'LL', monthYearA11yLabel: 'YYYY' },
      },
    },
  ],
})
export class MyCareerPlanComponent implements OnInit {
  private now = new Date();

  data:MyCareerPlan|undefined;
  noCareerFound:boolean = false;

  working_contract_career_plans:WorkingContractCareerPlan[] = [];
  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: ChartOptions|undefined;
  next_upgrade:{department_category:DepartmentCategory, upgraded_department_category_salary_condition:DepartmentCategorySalaryCondition, days_remaining:number, plus_for_seniority_then:number}|undefined;

  department_categories:DepartmentCategory[]|undefined;
  calculatorFormGroup:FormGroup;
  simulationCalculatorData:{data:undefined|{dcsc:DepartmentCategorySalaryCondition;plus_for_seniority:number}}|undefined;

  constructor(private api:ApiService,
              private user:UserService,
              private fb:FormBuilder) {
    this.calculatorFormGroup = this.fb.group({
      department_category_id: ['', Validators.compose([Validators.required])],
      years_in_bisual: ['', Validators.compose([Validators.required, Validators.min(0)])],
      when: [new Date(), Validators.compose([Validators.required])],
    });
  }

  ngOnInit(): void {
    this.fetchCareerPlan();
    this.fetchAllCareerPlans();
    this.fetchCareerPlanNextUpgrade();
    this.fetchAllDepartmentCategories();
  }

  onYearSelectedOnCalculator(date:Date, datepicker: MatDatepicker<Date>) {
    this.calculatorFormGroup.controls['when'].patchValue(date);
    datepicker.close();
  }

  calculatorWhenDatepickerFilter = (d: Date | null): boolean => {
    const day = (d || new Date());

    return day > this.now || day.getFullYear() == this.now.getFullYear();
  };

  submitCalculator() {
    const body:any = {...this.calculatorFormGroup.value};
    body.when = body.when.getFullYear();

    this.api.workingContractSimulateRetributionConditions(body).subscribe(
      data => {
        this.simulationCalculatorData = data;
      }
    );
  }

  private fetchCareerPlan() {
    const user = this.user.getCurrentUser();
    this.api.getUserWorkingContractCareerPlanActiveDetail(user.id).subscribe(
      data => {
        this.data = data;
        this.mountChartData();
      },
      error => {
        this.noCareerFound = true;
      }
    );
  }

  private fetchAllCareerPlans() {
    const user = this.user.getCurrentUser();
    this.api.getUserWorkingContractCareerPlans(user.id).subscribe(
      data => {
        this.working_contract_career_plans = data;
      }
    );
  }

  private fetchCareerPlanNextUpgrade() {
    const user = this.user.getCurrentUser();
    this.api.getUserWorkingContractCareerPlanActiveNextUpgrade(user.id).subscribe(
      data => {
        this.next_upgrade = data.data as any
      }
    );
  }

  private mountChartData() {
    const salarioSeriesName:string = "Salario base bruto anual";
    const optimisticSalarioSeriesName:string = "Salario bruto anual, Bisual cumple los objetivos";
    const salarioSeriesColor:string = "#0C83E1";
    const seguroMedicoSeriesName:string = "Seguro médico";
    const salarioWithPlusesSeriesName:string = "Salario bruto anual";
    const payflowSeriesName:string = "Payflow";
    const redColor:string = "#CE2525";
    const series:{name:string; data:number[]; type?:string}[] = [];
    let maxSalarySeries:number = 0;

    // Seguro médico
    series.push({
      name: seguroMedicoSeriesName,
      type: 'column',
      data: this.data?.years.map(y => (
        y.health_insurance_contract!=null
          ? (y.health_insurance_contract.active_health_insurance_contract_variable_condition.monthly_price * this.monthsActive(y.health_insurance_contract, y.year))
          : (y.health_insurance_plan!=null ? y.health_insurance_plan.monthly_aprox_price*12 : 0)
      )) as number[]
    });

    // Serie: Payflow
    series.push({
      name: payflowSeriesName,
      type: 'column',
      data: this.data?.years.map(y => y.flexible_remuneration_monthly_quantity*12) as number[]
    });

    // Salari si Bisual cumple els objectius
    series.push({
      name: optimisticSalarioSeriesName,
      type: 'line',
      data: this.data?.years.map(y => y.optimistic_brute_anual_base_salary!=null ? y.optimistic_brute_anual_base_salary + y.plus_for_seniority : 0) as number[]
    });

    // Salari total amb antiguitat
    series.push({
      name: salarioWithPlusesSeriesName,
      type: 'area',
      data: this.data?.years.map(y => y.brute_anual_base_salary + y.plus_for_seniority) as number[]
    });

    // xAxis Annotations - Department Categories + Posible prejubilación
    const xaxisAnnotations:any[] = [];
    let currDepartmentCategory:DepartmentCategory|undefined;
    const now:moment.Moment = moment();
    let nowPointYAxis:number|undefined;
    let prejubilacionStartDate:Date|undefined;
    let lastDate:Date|undefined;

    this.data?.years.forEach(y => {
      lastDate = new Date(y.date);
      if(!currDepartmentCategory || currDepartmentCategory.slug != y.department_category.slug) {
        currDepartmentCategory = y.department_category;
        xaxisAnnotations.push({
          x: new Date(y.date).getTime(),
          borderColor: y.is_approved ? undefined : salarioSeriesColor,
          label: {
            text: currDepartmentCategory.name,
            style: {
              color: y.is_approved ? "#fff" : salarioSeriesColor,
              background: y.is_approved ? salarioSeriesColor : "#fff"
            }
          }
        });
      }

      if(!nowPointYAxis && now.isSame(moment(y.date), 'year')) {
        nowPointYAxis = y.brute_anual_base_salary + y.plus_for_seniority;
      }

      // prejubilacion
      if(!prejubilacionStartDate && y.age >= 55 && y.possible_future_early_retirement) {
        prejubilacionStartDate = new Date(y.date);
      }

      // max salary
      if((y.optimistic_brute_anual_base_salary + y.plus_for_seniority) > maxSalarySeries) {
        maxSalarySeries = y.optimistic_brute_anual_base_salary + y.plus_for_seniority;
      }
    });

    // Afegim un marge per sobre al gràfic
    maxSalarySeries *= 1.1;

    // Insertem prejubilacion
    if(prejubilacionStartDate && lastDate) {
      xaxisAnnotations.push({
        x: prejubilacionStartDate.getTime(),
        x2: lastDate.getTime(),  // Establece el rango de la zona gris si quieres limitarlo
        fillColor: "#B3B3B3",  // Color de fondo de la zona (gris claro)
        opacity: 0.2,  // Transparencia de la zona gris
        label: {
          text: "Posible Prejubilación",
          style: {
            color: "#000",
            background: "#B3B3B3",
            fontWeight: "bold"
          },
          position: 'top'
        }
      });
    }

    // points: NOW
    const points:PointAnnotations[] = [];
    if(nowPointYAxis) {
      points.push({
        x: new Date().getTime(), // Posición en el eje X donde quieres el punto
        y: nowPointYAxis, // Posición en el eje Y donde quieres el punto
        marker: {
          size: 6,
          fillColor: redColor,
          strokeColor: redColor,
          radius: 2,
          offsetX: -6
        },
        label: {
          borderColor: redColor,
          offsetY: -10,
          style: {
            color: redColor,
            background: "#fff"
          },
          text: "Ahora"
        }
      });
    }

    this.chartOptions = {
      series: series,
      colors: ["#f0bebe", redColor, "#E3B163", salarioSeriesColor,],  // Colores personalizados para cada serie
      annotations: {
        xaxis: xaxisAnnotations,
        points: points
      },
      chart: {
        height: 350,
        type: "area",
        toolbar: {
          show: false  // Esto elimina el toolbar de zoom
        },
        zoom: {
          enabled: false  // Esto deshabilita el zoom mediante clic y arrastre
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: "smooth",
        dashArray: [0,0,5,0],
      },
      legend: {
        show: true,         // Muestra la leyenda
        onItemClick: {
          toggleDataSeries: false  // Desactiva la opción de ocultar series al hacer clic
        },
        onItemHover: {
          highlightDataSeries: true  // Permite resaltar al pasar el mouse, opcional
        }
      },
      xaxis: {
        type: "datetime",
        categories: this.data?.years.map(y => y.date),
        labels: {
          show: true,
          rotate: -45,  // Gira las etiquetas en diagonal si no hay espacio
          rotateAlways: true,  // Forzar la rotación si es necesario
          hideOverlappingLabels: false,  // Muestra todas las etiquetas sin importar el espacio
          formatter: (val, timestamp, opts) => {
            const year = new Date(val).getFullYear();
            const year_in_data = this.data?.years.find(y => y.year === year);
            if(year_in_data != null) {
              return `${year} - ${year_in_data.age} años`;
            }
            else {
              return `${year}`;
            }
          }
        },
        tickAmount: this.data?.years.length, // Esto asegura que se muestren todas las etiquetas
      },
      yaxis: [
        // Eje Y para "Seguro médico" y "Payflow" (0 a 3000)
        {
          seriesName: seguroMedicoSeriesName,  // Vincula este eje Y a "Seguro médico" y "Payflow"
          opposite: true,
          show: true,
          min: 0,
          max: 10000,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: redColor  // Color para el eje Y de Seguro médico y Payflow
          },
          labels: {
            style: {
              colors: redColor
            },
            formatter: (val) => {
              return val + '€';
            }
          }
        },
        {
          seriesName: payflowSeriesName,
          min: 0,
          max: 10000,
          show: false,  // Oculta un segundo eje innecesario para "Payflow"
          labels: {
            style: {
              colors: redColor
            },
            formatter: (val) => {
              return val + '€';
            }
          }
        },

        // Eje Y para "Salario con pluses bruto anual" y "Salario base bruto anual"
        {
          seriesName: optimisticSalarioSeriesName,  // Vincula este eje Y a las series de salario
          min: 0,
          max: maxSalarySeries,
          axisTicks: {
            show: true
          },
          axisBorder: {
            show: true,
            color: salarioSeriesColor  // Color para el eje Y de los salarios
          },
          labels: {
            style: {
              colors: salarioSeriesColor
            },
            formatter: (val) => {
              if (val >= 1000000) return (val / 1000000).toFixed(0) + 'M';
              if (val >= 1000) return (val / 1000).toFixed(0) + 'K';
              return val.toFixed(0);
            }
          }
        },
        {
          seriesName: salarioSeriesName,
          min: 0,
          max: maxSalarySeries,
          show: false,  // Oculta un segundo eje innecesario para "Salario base bruto anual"
          labels: {
            style: {
              colors: salarioSeriesColor
            },
            formatter: (val) => {
              if (val >= 1000000) return (val / 1000000).toFixed(0) + 'M';
              if (val >= 1000) return (val / 1000).toFixed(0) + 'K';
              return val.toFixed(0);
            }
          }
        }
      ],
      tooltip: {
        x: {
          format: "yy"
        }
      },
    }
  }

  private fetchAllDepartmentCategories() {
    this.api.getDepartmentCategories({ 'department-slug': 'development,sysadmin,designers,comercial' }).subscribe(
      data => {
        this.department_categories = data;
      }
    );
  }

  private monthsActive(health_insurance_contract:HealthInsuranceContract, year:number) {
    const start = new Date(health_insurance_contract.start_date);
    const end = health_insurance_contract.end_date!=null && health_insurance_contract.end_date.getFullYear() === year ? health_insurance_contract.end_date : new Date(year, 11, 31);

    if(start.getFullYear() === year) {
      if(end.getFullYear() === year) {
        return end.getMonth() - start.getMonth() + 1;
      }
      else {
        return 12 - start.getMonth() + 1;
      }
    }
    else if(end.getFullYear() === year) {
      return end.getMonth() + 1;
    }
    else {
      return 12;
    }
  }

}

export interface MyCareerPlan {
  working_contyract_career_plan: WorkingContractCareerPlan;
  years:{
    year:number;
    year_in_bisual:number;
    age:number;
    date:Date;
    department_category:DepartmentCategory;
    brute_anual_base_salary:number;
    optimistic_brute_anual_base_salary:number;
    flexible_remuneration_monthly_quantity:number;
    health_insurance_plan:HealthInsurancePlan;
    health_insurance_contract:HealthInsuranceContract;
    possible_future_early_retirement:boolean;
    possible_future_retirement_plan:boolean;
    plus_for_seniority:number;
    is_approved:number;
  }[]
}
