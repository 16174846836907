<h3 mat-dialog-title>{{board.id ? 'Editar tablero' : 'Crear tablero'}}</h3>

<mat-dialog-content [formGroup]="form">
  @if (board.project_tasks_boards!=null) {
    <span>{{ board.project_tasks_boards.length }} tareas serán incluidas en este sprint</span>
  }
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-8">
        <div class="row">
          <div class="col-12 d-flex flex-column mt-4">
            <mat-label>Nombre de tablero</mat-label>
            <mat-form-field class="nobottom" appearance="outline">
              <input matInput formControlName="title">
            </mat-form-field>
          </div>

          <div class="col-12">
            <mat-label>Fecha de inicio *</mat-label>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field class="nobottom" appearance="outline">
              <input matInput [matDatepicker]="startPicker" [formControl]="startDateControl">
              <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
              <mat-datepicker #startPicker></mat-datepicker>
              @if (board.start_date) {
                <mat-hint>Fecha de inicio planeada: {{ board.start_date | date:'short' }}</mat-hint>
              }
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field appearance="outline">
              <input type="time" matInput [formControl]="startTimeControl" />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-label>Fecha de finalización *</mat-label>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field class="nobottom" appearance="outline">
              <input matInput [matDatepicker]="endingPicker" [formControl]="endDateControl">
              <mat-datepicker-toggle matSuffix [for]="endingPicker"></mat-datepicker-toggle>
              <mat-datepicker #endingPicker></mat-datepicker>
            </mat-form-field>
          </div>
          <div class="col-12 col-lg-6">
            <mat-form-field class="nobottom" appearance="outline">
              <input type="time" matInput [formControl]="endTimeControl" />
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-label>Seguidores por defecto</mat-label>
            <mat-form-field class="nobottom" appearance="outline" style="width: 100%">
              <mat-chip-grid #chipGridUsers>
                @for (user of form.value.default_followers; track user; let i = $index) {
                  <mat-chip-row (removed)="removeUser(i)">
                    {{ user.name + ' ' + user.surnames }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                }
              </mat-chip-grid>
              <input placeholder="Buscar usuarios..." #usersInput [formControl]="usersSearchCtrl"
                [matChipInputFor]="chipGridUsers" [matAutocomplete]="autoUsers"/>
              <mat-autocomplete #autoUsers="matAutocomplete" (optionSelected)="selectedUser($event.option.value)">
                @for (user of filteredUsers | async; track user) {
                  <mat-option [value]="user">
                    {{ user.name + ' ' + user.surnames }}
                  </mat-option>
                }
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <div class="col-12">
            <mat-label>Canales de comunicación</mat-label>
            <mat-form-field class="nobottom" appearance="outline" style="width: 100%">
              <mat-chip-grid #chipGridCnc>
                @for (channel of form.value.company_notification_channels; track channel; let i = $index) {
                  <mat-chip-row (removed)="removeCnc(i)">
                    {{ channel.email != null ? channel.email : (channel.slack_channel_name != null ? (channel.slack_channel_name + ' (Slack)') : (channel.teams_channel_name!=null ? (channel.teams_channel_name + ' (Teams)') : '?')) }}
                    <button matChipRemove>
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                }
              </mat-chip-grid>
              <input placeholder="Buscar canales..." #cncInput [formControl]="cncSearchCtrl"
                [matChipInputFor]="chipGridCnc" [matAutocomplete]="autoCnc"/>
              <mat-autocomplete #autoCnc="matAutocomplete" (optionSelected)="selectedCnc($event.option.value)">
                @for (channel of filteredCnc | async; track channel) {
                  <mat-option [value]="channel">
                    {{ channel.email != null ? channel.email : (channel.slack_channel_name != null ? (channel.slack_channel_name + ' (Slack)') : (channel.teams_channel_name!=null ? (channel.teams_channel_name + ' (Teams)') : '?')) }}
                  </mat-option>
                }
              </mat-autocomplete>
              <mat-hint>Se utilizará para notificar al respecto de las actualizaciones de las tareas de la fase.</mat-hint>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="row">
          <div class="col-12">
            <mat-label>Permisos</mat-label>
          </div>
          <div class="col-12">
            <mat-checkbox formControlName="can_client_dev_manage_board">Un cliente con rol DEV tiene control del tablero</mat-checkbox>
          </div>
          <div class="col-12">
            <mat-checkbox formControlName="can_dev_manage_board">Un trabajador con rol DEV tiene control del tablero</mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button color="accent" (click)="save()" [disabled]="!form.valid">Guardar</button>
  <button mat-flat-button color="transparent" (click)="onCancel()" type="submit">Cancelar</button>
</mat-dialog-actions>



