import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject, Observable } from 'rxjs';

declare global {
  interface Window {
    require: any;
    process: any; // Añadir process en la interfaz de Window
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  private downloadsCounter:number = 0;
  private loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false as boolean);
  public loadingObservable:Observable<boolean> = this.loadingSubject.asObservable();

  constructor() { }

  public enableLoading() {
    if(this.downloadsCounter < 0) this.downloadsCounter = 0;
    this.downloadsCounter++;
    this.refreshLoading();
  }

  public disableLoading() {
    this.downloadsCounter--;
    if(this.downloadsCounter<0) this.downloadsCounter = 0;
    this.refreshLoading();
  }

  public cloneObj(src:any) {
    let target:any = {};
    for (let key in src) {
      if(src[key]==null) {
        target[key] = null;
      }
      else if(Array.isArray(src[key])) {
        target[key] = [];
        for(let k2 in src[key]) {
          target[key][k2] = this.cloneObj(src[key][k2]);
        }
      }
      else if(src[key] instanceof Date) {
        target[key] = new Date(src[key]);
      }
      else if(typeof src[key] === 'object') {
        target[key] = this.cloneObj(src[key]);
      }
      else {
        target[key] = src[key];
      }
    }
    return target;
  }

  public diffDays(d1:Date, d2:Date) {
    return d2.getDate() - d1.getDate();
  }

  getStatusTooltip(status:string) {
    let res = "No reconocido";
    switch(status) {
      case 'active':
        res = "Activo";
        break;
      case 'inactive':
        res = "Inactivo, lo ha marcado el cliente";
        break;
      case 'cancelled':
        res = "Cancelado, se eliminará pronto";
        break;
      case 'expired':
        res = "Expirado, el cliente no lo ha renovado";
        break;
      case 'deleted':
        res = "Eliminado de Hetzner";
        break;
    }
    return res;
  }

  downloadPdfFromBlobResponse(response:HttpResponse<any>) {
    const content_disposition = response.headers.get('Content-Disposition');
    let filename:string = content_disposition?.split('attachment; filename=')[1] as string;
    if(filename==null || filename=="") filename = "Archivo.pdf";
    filename = filename.replaceAll("\"", "");
    let blob = new Blob([response.body], {type: 'application/pdf'});
    saveAs(blob, filename);
  }

  downloadXlsxFromBlobResponse(response:HttpResponse<any>) {
    const content_disposition = response.headers.get('Content-Disposition');
    let filename:string = content_disposition?.split('attachment; filename=')[1] as string;
    if(filename==null || filename=="") filename = "Archivo.xlsx";
    filename = filename.replaceAll("\"", "");
    let blob = new Blob([response.body], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
    saveAs(blob, filename);
  }

  downloadTextFile(body:string, filename:string) {
    let blob = new Blob([body]);
    saveAs(blob, filename);
  }

  sleep(ms:number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  getChartsColorScheme() {
    return {
      domain: ['#0C83E1', '#6BC434', "#CE2525", "#E3B163", "#888888", "#E8F5FF"]
    };
  }

  getGreenRedColorScheme() {
    return {
      domain: ["#6BC434", "#CE2525"]
    };
  }

  getDefaultInitialDate() {
    const today = new Date();
    const first = today.getDate() - today.getDay() + 1;

    const monday = new Date(today.setDate(first));
    return monday;
  }

  getDefaultEndDate() {
    const today = new Date();
    const first = today.getDate() - today.getDay() + 1;
    const last = first + 6;

    const sunday = new Date(today.setDate(last));

    return sunday;
  }

  public dateToString(d:Date):string {
    return d.getDate() + "-" + (d.getMonth()+1) + "-" + d.getFullYear();
  }

  public dateToStringYYYYMMDD(d:Date):string {
    return d.getFullYear() + '-' + (d.getMonth()+1) + "-" + d.getDate();
  }

  public stringToDate(str:string):Date {
    const str_splitted = str.split("-");
    return new Date(+str_splitted[2], +str_splitted[1] - 1, +str_splitted[0]);
  }


  private refreshLoading() {
    this.loadingSubject.next(this.downloadsCounter > 0);
  }

  downloadCsvFromBlobResponse(response:HttpResponse<any>) {
    const content_disposition = response.headers.get('Content-Disposition');
    let filename:string = content_disposition?.split('attachment; filename=')[1] as string;
    if(filename==null || filename=="") filename = "Archivo.csv";
    let blob = new Blob([response.body], {type: 'text/csv'});
    saveAs(blob, filename);
  }

  readFileAsText(file: File, encoding: string) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(file, encoding);
    });
  }

  getHeaderArrayFromCsv(csvRecordsArr: any, separator: string) {
    let headers = csvRecordsArr[0].split(separator);
    let headerArray = [];
    for (let j = 0; j < headers.length; j++) {
      headerArray.push(headers[j]);
    }
    return headerArray;
  }

}
