import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';
import { Role } from '../models/role.model';
import { User } from '../models/user.model';
import { BisualSettingService } from '../services/bisual-setting.service';
import { UtilsService } from '../services/utils.service';

@Injectable()
export class BisualSettingInitializedGuard  {

  constructor(private bisual_setting:BisualSettingService,
              private utils:UtilsService) {}

  async canActivate(route:ActivatedRouteSnapshot, state:RouterStateSnapshot) {
    await this.isBisualSettingsInitialized();

    return true;
  }

  private async isBisualSettingsInitialized():Promise<any> {
    const res = this.bisual_setting.getSettings();

    if(res != null) return true;
    else {
        await this.utils.sleep(500);
        return await this.isBisualSettingsInitialized();
    }

  }
}
