<mat-dialog-content>
  @if (working_contract!=null) {
    <div class="row">
      <div class="col-9">
        <div class="d-flex justify-content-between">
          <h2>{{ viewDate | calendarDate:('monthViewTitle'):locale:weekStartsOn }}</h2>
          <div class="btn-group">
            <div class="btn btn-primary" mwlCalendarPreviousView [view]="'month'" [(viewDate)]="viewDate" (click)="changeMonth(-1)">Anterior</div>
            <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">Hoy</div>
            <div class="btn btn-primary" mwlCalendarNextView [view]="'month'" [(viewDate)]="viewDate" (click)="changeMonth(1)">Siguiente</div>
          </div>
        </div>
        <mwl-calendar-month-view
          [viewDate]="viewDate"
          [events]="events"
          [refresh]="refresh"
          (dayClicked)="dayClicked($event.day)"
          [locale]="locale"
          [weekStartsOn]="weekStartsOn"
          (eventClicked)="handleEvent('Clicked', $event.event)"
          (beforeViewRender)="beforeMonthViewRender($event)"
        ></mwl-calendar-month-view>
      </div>
      <div class="col-3">
        <div id="titulo-row">
          <h2>Horas restantes</h2>
        </div>
        @if (days_left!=null && days_total!=null) {
          <div id="vacation-spinner">
            <mat-progress-spinner color="accent" [mode]="'determinate'" [value]="spinner_prcnt" [diameter]="120"></mat-progress-spinner>
            <span id="hours-left">{{ days_left | number:'1.0-0' }} días</span>
            <span id="hours-total">De {{ days_total | number:'1.0-0' }} días</span>
          </div>
        }
        <mat-accordion class="mb-3">
          <mat-expansion-panel [expanded]="true" (closed)="stopAnimation()">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <mat-icon class="tilt-n-move-shaking">info</mat-icon>
              </mat-panel-title>
              <mat-panel-description>
                Política de vacaciones
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p>Algunas recomendaciones para que tu petición sea aceptada con mayor facilidad</p>
            <ul>
              <li>Si vas a pedir algunos días seguidos, pídelos con más de <strong>un mes de antelación</strong>, sin contar el mes en curso.</li>
              <li>El que no corre, <strong>¡vuela!</strong> Así que si no te das prisa, puede que tus compañeros imposibiliten tus vacaciones</li>
            </ul>
          </mat-expansion-panel>
        </mat-accordion>
        <ul id="list-periods">
          @for (vrp of vacation_request_periods_to_ask; track vrp) {
            <li>
              {{ vrp.vrp.day | date:'shortDate' }}: <b>{{ vrp.hours }} horas</b>
            </li>
          }
        </ul>
      </div>
    </div>
  }
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button cdkFocusInitial (click)="close()">Salir</button>
  <button mat-flat-button color="accent" [disabled]="reservarButtonDisabled" (click)="reservar()">Pedir vacaciones</button>
</mat-dialog-actions>
