<h1 mat-dialog-title>Informe de {{data.project.title}}</h1>

<mat-dialog-content [formGroup]="form" class="pb-3">
  <div class="container">
    <div class="row">
      <div class="col-12 col-lg-5">
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-label>Periodo del informe</mat-label>
          <mat-date-range-input [formGroup]="form" [rangePicker]="picker">
            <input matStartDate formControlName="from" placeholder="Fecha de inicio">
            <input matEndDate formControlName="to" placeholder="Fecha final">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
          <mat-hint>Dejar en blanco si quieres todos los datos.</mat-hint>
        </mat-form-field>
      </div>
      <!-- Checkbox to change use_time_invested from form -->
      <div class="col-12 col-lg-5">
        <mat-checkbox formControlName="use_time_invested" class="mt-3">Usar tiempo invertido</mat-checkbox>
      </div>

      <div class="col-2">
        <mat-form-field appearance="outline" style="width: 100%;" class="hidden-input">
          <!-- Hidden input -->
          <input matInput type="number" formControlName="multiplier">
        </mat-form-field>
      </div>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()" cdkFocusInitial type="button">Cancelar</button>
  <button mat-stroked-button color="accent" [disabled]="!form.valid" type="submit" (click)="onSubmitCsv()">
    <mat-icon style="margin-right: 10px">summarize</mat-icon>
    Obtener informe en CSV
  </button>
  <button mat-flat-button color="accent" [disabled]="!form.valid" type="submit" (click)="onSubmitPdf()">
    <mat-icon>download</mat-icon>
    Descargar informe en PDF
  </button>
</mat-dialog-actions>
