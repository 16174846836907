@if (wikiNode) {
  <div id="content" class="disable-right-click">
    <!-- an hidden div is created to set the position of appearance of the menu-->
    <div style="visibility: hidden; position: fixed;"
      [style.left]="menuTopLeftPosition.x"
      [style.top]="menuTopLeftPosition.y"
      matMenuTrigger [matMenuTriggerFor]="rightMenu">
    </div>
    <!-- standard material menu -->
    <mat-menu #rightMenu="matMenu">
      <ng-template matMenuContent let-item="item">
        @if (item.type=='WikiNode') {
          <button mat-menu-item (click)="deleteNode()">Borrar carpeta</button>
        }
        @if (item.type=='WikiNode') {
          <button mat-menu-item (click)="changeNameNode()">Cambiar nombre</button>
        }
        @if (item.type=='WikiArticle') {
          <button mat-menu-item (click)="deleteArticle()">Borrar documento</button>
        }
        <button mat-menu-item (click)="showMoveWikiItemDialog()">Mover</button>
      </ng-template>
    </mat-menu>
    <div class="page-title">
      <ul class="breadcrumbs">
        <a class="breadcrumbs-item" [routerLink]="['/employees','wiki']">
          <li>
            <mat-icon>menu_book</mat-icon>
          </li>
        </a>
        @for (p of wikiNode.all_parents; track p) {
          <a class="breadcrumbs-item" [routerLink]="['/employees','wiki', p.id]">
            <li>{{p.name}}</li>
          </a>
        }
        <a class="breadcrumbs-item">
          <li>{{wikiNode.name}}</li>
        </a>
      </ul>
      @if (environments != null) {
        <mat-form-field appearance="outline">
          <mat-label>Escoge entorno</mat-label>
          <mat-select [ngModel]="environment_selected" (ngModelChange)="onEnvironmentChanged($event)">
            @for (env of environments; track env) {
              <mat-option [value]="env.id">
                {{ env.application?.name }} ({{ env.role }} - {{ env.git_ref }})
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="createArticle()" >
          <mat-icon>post_add</mat-icon>
          <span>Crear nuevo documento</span>
        </button>
        <button mat-menu-item (click)="createNode()" >
          <mat-icon>create_new_folder</mat-icon>
          <span>Crear nueva carpeta</span>
        </button>
        @if (wikiNode.project != null) {
          <button mat-menu-item [routerLink]="['/employees','development', 'projects', wikiNode.project.id, 'resume']">
            <mat-icon>apps</mat-icon>
            <span>Ir a {{wikiNode.project.title}}</span>
          </button>
        }
      </mat-menu>
    </div>
    @if (wikiNode.children && wikiNode.children.length>0) {
      <h3>Carpetas</h3>
      <div class="row g-2 mb-5">
        @for (element of wikiNode!.children; track element) {
          <div class="col col-sm-4 col-lg-3 col-xl-2" (contextmenu)="onRightClick($event, element, 'WikiNode')">
            <div class="card folder" [routerLink]="['/employees','wiki', element.id]">
              <mat-icon>{{ element.icon!=null ? element.icon : 'folder' }}</mat-icon>
              <mat-icon class="icon-background">{{ element.icon!=null ? element.icon : 'folder' }}</mat-icon>
              <div class="title">{{element.name}}</div>
            </div>
          </div>
        }
      </div>
    }
    @if (wikiNode.wiki_articles && wikiNode.wiki_articles.length>0) {
      <h3>Archivos</h3>
      <div class="card">
        @for (element of wikiNode!.wiki_articles; track element) {
          <div
            (contextmenu)="element.id != null ? onRightClick($event, element, 'WikiArticle') : null">
            <div class="file">
              <div class="title">
                <a [routerLink]="element.id ? ['article', element.id] : ['github-doc', getPathGithub(element)]" [queryParams]="element.id!=null || environment_selected==null || environments==null || environments.length == 0 ? {'upper_node': wikiNodeId } : {'upper_node': wikiNodeId, 'application_environment_id': environment_selected }">
                  {{ element.wiki_article_version != null ? element.wiki_article_version.title : element.name }}
                </a>
                @if (element.html_url != null) {
                  <span>
                    <a [href]="element.html_url" target="_blank">{{ element.html_url }}</a>
                  </span>
                }
              </div>
              @if (element.wiki_article_version != null && element.wiki_article_version.created_at != null && element.wiki_article_version.created_by != null) {
                <div class="extra-info">
                  <p>{{ 'Ult. modificación: ' + (element.wiki_article_version.created_at | date:'short' ) }}</p>
                  <img class="mat-tooltip-trigger ng-star-inserted" src="{{ element.wiki_article_version.created_by.profile_picture_url }}">
                </div>
              }
              @if (element.wiki_article_version == null) {
                <div class="extra-info">
                  <p>Fuente: Github</p>
                </div>
              }
            </div>
            @if (element != wikiNode.wiki_articles[wikiNode.wiki_articles.length-1]) {
              <hr>
              }
            </div>
          }
        </div>
      }
    </div>
  }
