import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { CompanyNotificationChannel } from 'src/app/models/company_notification_channel.model';
import { ProjectPhaseBoard } from 'src/app/models/project_phase_board.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-edit-project-phase-board-dialog',
  templateUrl: './edit-project-phase-board-dialog.component.html',
  styleUrls: ['./edit-project-phase-board-dialog.component.css']
})
export class EditProjectPhaseBoardDialogComponent implements OnInit {
  form: UntypedFormGroup;
  board:ProjectPhaseBoard = this.data.board;
  action:string = this.data.board.status;

  startDateControl:UntypedFormControl = new UntypedFormControl(new Date(), Validators.required);
  startTimeControl:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)]));

  endDateControl:UntypedFormControl = new UntypedFormControl(new Date(new Date().setDate(new Date().getDate() + 14)), Validators.required);
  endTimeControl:UntypedFormControl = new UntypedFormControl('', Validators.compose([Validators.required, Validators.pattern(/^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/)]));

  project_phase_users:User[] = [];
  usersSearchCtrl = new FormControl('');
  filteredUsers: Observable<User[]>;

  company_notification_channels:CompanyNotificationChannel[] = [];
  cncSearchCtrl = new FormControl('');
  filteredCnc: Observable<CompanyNotificationChannel[]>;

  project_id: string = this.data.project_id;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<EditProjectPhaseBoardDialogComponent>,
    private fb: UntypedFormBuilder,
    private api:ApiService
  ) {
    this.form = this.fb.group({
      title: ['', Validators.compose([Validators.required])],
      start_date: [''],
      end_date: [''],
      can_client_dev_manage_board: [false],
      can_dev_manage_board: [false],
      default_followers: [[]],
      company_notification_channels: [[]]
    });

    this.initFilteredUsers();
    this.initFilteredCnc();
  }

  ngOnInit(): void {
    this.patchBoard();
    this.listenDateTimeControl();
    this.listenStartTimeControl();
    this.fetchProjectPhaseUsers();
    this.fetchCompanyNotificationChannels();
  }


  private patchBoard() {
    if(this.board.id) {
      this.form.patchValue({
        title: this.board.title,
      });
      if(this.board.start_date) {
        this.startDateControl.setValue(new Date(this.board.start_date));
        this.form.patchValue({
          start_date: new Date(this.board.start_date)
        });
      } else {
        this.startDateControl.setValue(new Date());
        this.form.patchValue({
          start_date: new Date()
        });
      }
      if(this.board.end_date) {
        this.endDateControl.setValue(new Date(this.board.end_date));
        this.form.patchValue({
          end_date: new Date(this.board.end_date)
        });
      } else {
        this.endDateControl.setValue(new Date(new Date().setDate(new Date().getDate() + 14)));
        this.form.patchValue({
          end_date: new Date(new Date().setDate(new Date().getDate() + 14))
        });
      }

      if(this.action == 'not_started') {
        this.startTimeControl.setValue(("00" + new Date().getHours()).slice(-2) + ":" + ("00" + new Date().getMinutes()).slice(-2));
        this.endTimeControl.setValue(("00" + new Date().getHours()).slice(-2) + ":" + ("00" + new Date().getMinutes()).slice(-2));
      } else {
        this.startDateControl = new UntypedFormControl(new Date(this.board.start_date as Date), Validators.required);
        this.startTimeControl.setValue(("00" + new Date(this.board.start_date as Date).getHours()).slice(-2) + ":" + ("00" + new Date(this.board.start_date as Date).getMinutes()).slice(-2));
        this.endDateControl = new UntypedFormControl(new Date(this.board.end_date as Date), Validators.required);
        this.endTimeControl.setValue(("00" + new Date(this.board.end_date as Date).getHours()).slice(-2) + ":" + ("00" + new Date(this.board.end_date as Date).getMinutes()).slice(-2));
      }

      if(this.board.default_followers.length) {
        this.form.patchValue({
          default_followers: this.board.default_followers
        })
      }

      if(this.board.company_notification_channels.length) {
        this.form.patchValue({
          company_notification_channels: this.board.company_notification_channels
        })
      }

      this.form.controls['can_client_dev_manage_board'].patchValue(this.board.can_client_dev_manage_board);
      this.form.controls['can_dev_manage_board'].patchValue(this.board.can_dev_manage_board);

    } else { //create sprint
      this.startTimeControl.setValue(("00" + new Date().getHours()).slice(-2) + ":" + ("00" + new Date().getMinutes()).slice(-2));
      this.form.patchValue({
        start_date: new Date()
      });
      this.endTimeControl.setValue(("00" + new Date().getHours()).slice(-2) + ":" + ("00" + new Date().getMinutes()).slice(-2));
      this.form.patchValue({
        end_date: new Date(new Date().setDate(new Date().getDate() + 14))
      });
    }

  }

  private fetchCompanyNotificationChannels() {
    this.api.getCompanyCompanyNotificationChannels(this.data.company_id).subscribe(
      data => {
        this.company_notification_channels = data;
      }
    );
  }

  private listenDateTimeControl() {
    this.startDateControl.valueChanges.subscribe(
      (data:Date) => {
        if(this.startDateControl.valid) {
          const res_hours_minutes = this.startTimeControl.value.split(":");
          let hours = res_hours_minutes[0];
          let minutes = res_hours_minutes[1];
          let new_date:Date = new Date(data);
          new_date.setHours(hours);
          new_date.setMinutes(minutes);
          this.form.patchValue({
            start_date: new_date
          })
        } else {
          this.form.patchValue({
            start_date: new Date()
          });
        }
      }
    );

    this.endDateControl.valueChanges.subscribe(
      (data:Date) => {
        if(this.endDateControl.valid) {
          const res_hours_minutes = this.endTimeControl.value.split(":");
          let hours = res_hours_minutes[0];
          let minutes = res_hours_minutes[1];
          let new_date:Date = new Date(data);
          new_date.setHours(hours);
          new_date.setMinutes(minutes);
          this.form.patchValue({
            end_date: new_date
          })
        } else {
          this.form.patchValue({
            end_date: new Date(new Date().setDate(new Date().getDate() + 14))
          });
        }
      }
    );
  }

  private listenStartTimeControl() {
    this.startTimeControl.valueChanges.subscribe(
      data => {
        if(this.startTimeControl.valid) {
          const splitted:number[] = data.split(":");
          let current_date:Date = new Date(this.startDateControl.value);
          current_date.setHours(splitted[0]);
          current_date.setMinutes(splitted[1])
          this.form.patchValue({
            start_date: current_date
          });
        }
      }
    );
    this.endTimeControl.valueChanges.subscribe(
      data => {
        if(this.endTimeControl.valid) {
          const splitted:number[] = data.split(":");
          let current_date:Date = new Date(this.endDateControl.value);
          current_date.setHours(splitted[0]);
          current_date.setMinutes(splitted[1])
          this.form.patchValue({
            end_date: current_date
          });
        }
      }
    );
  }

  save() {
    const data = {...this.form.value};
    data.default_followers = data.default_followers.map((u:User) => u.id);
    data.company_notification_channels = data.company_notification_channels.map((cnc:CompanyNotificationChannel) => cnc.id);
    this.api.updateProjectPhaseBoard((this.board.id as number).toString(), data).subscribe(
      response => {
        this.dialogRef.close(this.form.value);
      }
    );
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  removeUser(idx:number) {
    const users:User[] = this.form.value.default_followers;
    users.splice(idx, 1);
    this.form.controls['default_followers'].patchValue(users);
  }

  selectedUser(user:User) {
    const users:User[] = this.form.value.default_followers;
    users.push(user);
    this.form.controls['default_followers'].patchValue(users);
    this.usersSearchCtrl.setValue('');
  }

  removeCnc(idx:number) {
    const cncs:CompanyNotificationChannel[] = this.form.value.company_notification_channels;
    cncs.splice(idx, 1);
    this.form.controls['company_notification_channels'].patchValue(cncs);
  }

  selectedCnc(cnc:CompanyNotificationChannel) {
    const cncs:CompanyNotificationChannel[] = this.form.value.company_notification_channels;
    cncs.push(cnc);
    this.form.controls['company_notification_channels'].patchValue(cncs);
    this.cncSearchCtrl.setValue('');
  }

  private initFilteredUsers() {
    this.filteredUsers = this.usersSearchCtrl.valueChanges.pipe(
      startWith(null),
      map((user: string | null) => (user && (typeof user === "string") ? this._filterUsers(user) : this.project_phase_users.slice())),
    );
  }

  private _filterUsers(user: string): User[] {
    const filterValue = user.toLowerCase();
    return this.project_phase_users.filter(user => user.name.toLowerCase().includes(filterValue));
  }

  private fetchProjectPhaseUsers() {
    this.api.getActiveProjectMembersWithBusiness(this.project_id).subscribe(
      users => {
        this.project_phase_users = users.bisual_users.map((obj:any) => obj.user);
        users.company_users.forEach(cu => this.project_phase_users.push(cu));
      }
    );
  }

  private initFilteredCnc() {
    this.filteredCnc = this.cncSearchCtrl.valueChanges.pipe(
      startWith(null),
      map((cnc: string | null) => (cnc && (typeof cnc === "string") ? this._filterCncs(cnc) : this.company_notification_channels.slice())),
    );
  }

  private _filterCncs(cnc:string) {
    const filterValue = cnc.toLowerCase();
    return this.company_notification_channels.filter(cnc => cnc.teams_channel_name?.toLowerCase().includes(filterValue) || cnc.slack_channel_name?.toLowerCase().includes(filterValue) || cnc.email?.toLowerCase().includes(filterValue));
  }

}
