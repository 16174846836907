import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Computer } from 'src/app/models/computer.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-credit-computer-dialog',
  templateUrl: './credit-computer-dialog.component.html',
  styleUrl: './credit-computer-dialog.component.css'
})
export class CreditComputerDialogComponent implements OnInit {
  form:FormGroup;

  get isCreate() {
    return this.form.value['id'] == '';
  }

  constructor(private fb:FormBuilder,
              public dialogRef: MatDialogRef<CreditComputerDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:{computer:Computer|undefined},
              private api:ApiService,
  ) {
    this.form = this.fb.group({
      id: ['', Validators.compose([])],
      model: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      buyed_at: ['', Validators.compose([Validators.required])],
      ram_memory_in_gb: ['', Validators.compose([Validators.required, Validators.min(1)])],
      ssd_memory_in_gb: ['', Validators.compose([Validators.required, Validators.min(1)])],
      serial_number: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      chip: ['', Validators.compose([Validators.required, Validators.minLength(5)])],
      holded_asset_id: ['', Validators.compose([Validators.required])],
    });

    if(data.computer != undefined) {
      this.form.patchValue(data.computer);
    }
  }

  ngOnInit(): void {

  }

  submit() {
    if(this.isCreate) {
      this.createComputer();
    }
    else {
      this.updateComputer();
    }
  }

  close(data:any|undefined = undefined) {
    this.dialogRef.close(data);
  }

  private createComputer() {
    this.api.createComputer(this.form.value).subscribe(
      data => {
        this.close(data);
      }
    );
  }

  private updateComputer() {
    this.api.updateComputer(this.form.value['id'], this.form.value).subscribe(
      data => {
        this.close(data);
      }
    );
  }

}
