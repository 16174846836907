<div id="content" class="extensible">
  <div class="container">

    @if(contract != null) {
      <div class="row">
        <div class="col-12">
          <div class="page-title">
            <h1>Mi retribución</h1>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-lg-4">
          <div class="card" style="margin-bottom: 10px;">
            <h2>Mi paquete retributivo mensual</h2>
            <apx-chart
              [series]="paqueteRetributivoChartOptions.series"
              [chart]="paqueteRetributivoChartOptions.chart"
              [labels]="paqueteRetributivoChartOptions.labels"
              [colors]="paqueteRetributivoChartOptions.colors"
              [plotOptions]="paqueteRetributivoChartOptions.plotOptions"
              [dataLabels]="paqueteRetributivoChartOptions.dataLabels"
              [tooltip]="paqueteRetributivoChartOptions.tooltip"
            ></apx-chart>
          </div>
          <div class="card">
            <h2>Mi retribución flexible</h2>
            @if(contract.current_working_contract_variable_condition.flexible_remuneration_monthly_quantity!=undefined && contract.current_working_contract_variable_condition.flexible_remuneration_monthly_quantity > 0) {
              <p style="font-size: 18px;">
                <span style="font-size: 32px; font-weight: 700;">{{ contract.current_working_contract_variable_condition.flexible_remuneration_monthly_quantity | currency }}</span>
                /mes
              </p>

              <button mat-flat-button color="accent" (click)="openRetribucionFlexible()">Información de uso</button>
            }
            @else {
              <p>No disponible.</p>
            }
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card" style="margin-bottom: 10px;" id="seguroMedicoCard">
            <h2>Mi seguro médico</h2>
            @if(contract.current_working_contract_variable_condition.health_insurance_contract != null) {
              <img [src]="contract.current_working_contract_variable_condition.health_insurance_contract.health_insurance_plan.health_insurance_company.logo_url" [alt]="contract.current_working_contract_variable_condition.health_insurance_contract.health_insurance_plan.health_insurance_company.name" />
              <p>
                <b>Plan</b>: <a target="_blank" [href]="contract.current_working_contract_variable_condition.health_insurance_contract.contract_file_url">{{ contract.current_working_contract_variable_condition.health_insurance_contract.health_insurance_plan.name }}</a><br>
                <b>Desde</b>: {{ contract.current_working_contract_variable_condition.health_insurance_contract.start_date | date:'shortDate' }}<br>
                <b>Número póliza</b>: {{ contract.current_working_contract_variable_condition.health_insurance_contract.contract_identifier }}<br>
              </p>
              <a mat-flat-button class="mt-1" color="accent" target="_blank" [href]="contract.current_working_contract_variable_condition.health_insurance_contract.health_insurance_plan.medical_directory_link" style="margin-right: 5px;">Ver cuádro médico</a>
              @if(contract.current_working_contract_variable_condition.health_insurance_contract.active_health_insurance_contract_card != null) {
                <button mat-flat-button class="mt-1" color="accent" (click)="seeHealthInsuranceCard()">Ver tarjeta</button>
              }
            }
            @else {
              <p>No disponible.</p>
            }
          </div>
          <div class="card">
            <h2>Próximo aumento de condiciones</h2>
            @if(next_upgrade != undefined) {
              <p>El próximo <b>{{ next_upgrade.date | date:'shortDate' }}</b> se mejorarán tus condiciones.</p>
              <ul>
                <li>
                  <mat-icon>check_circle</mat-icon>
                  <span>Serás <b>{{ next_upgrade.category_name }}</b></span>
                </li>
                <li>
                  <mat-icon>check_circle</mat-icon>
                  <span>Quedan <b>{{ next_upgrade.days_remaining }} días</b></span>
                </li>
                <li>
                  <mat-icon>check_circle</mat-icon>
                  <span>Sueldo previsto: <b>{{ next_upgrade.salary | currency }}</b></span>
                </li>
                @if(next_upgrade.health_insurance) {
                  <li>
                    <mat-icon>check_circle</mat-icon>
                    Incluye seguro médico
                  </li>
                }
                @if(next_upgrade.ret_flexible > 0) {
                  <li>
                    <mat-icon>check_circle</mat-icon>
                    <span><b>{{ next_upgrade.ret_flexible | currency }}</b> en Tarjeta Restaurante</span>
                  </li>
                }
              </ul>
            }
            @else {
              <p>No hay aumentos de condiciones aprobados por ti. Ahora bien, eso no quiere decir que no los vayas a tener. Te recomendamos visitar la página de <b>Mi carrera</b> para ver la próxima previsión de aumento y lo que tienes que hacer para conseguirlo.</p>
              <button [routerLink]="['..', 'career-plan']" style="width: 100%;" mat-flat-button color="accent">Ver mi carrera</button>
            }
          </div>
        </div>
        <div class="col-12 col-lg-4">
          <div class="card" id="desgloseNomina">
            <h2>Desglose de mi nómina</h2>

            <div>
              <div class="salary-card blue" style="width: 50%; max-width: 200px;">
                <p class="salary-card-title">
                  {{ this.contract.current_working_contract_variable_condition.real_cost_for_bisual/12 | currency:'EUR':'symbol':'1.0-0' }}
                </p>
                <p class="salary-card-description">Coste anual para la empresa</p>
              </div>
              <div class="salary-discount">
                <p class="salary-card-title">-{{ this.contract.current_working_contract_variable_condition.seguridad_social_anual_amount_for_business/12 | currency:'EUR':'symbol':'1.0-0' }} ({{ this.contract.current_working_contract_variable_condition.seguridad_social_prcnt/100 | percent }})</p>
                <p class="salary-card-description">Seguridad Social a cargo de la empresa para el Estado</p>
                <mat-icon>south</mat-icon>
              </div>
            </div>

            <div style="justify-content: center; margin-bottom: 20px;">
              <div class="salary-card red" style="width: 80%; max-width: 270px;">
                <p class="salary-card-title">{{ this.contract.current_working_contract_variable_condition.total_brute_anual_salary_with_trienniums_retflexible/12 | currency:'EUR':'symbol':'1.0-0' }}</p>
                <p class="salary-card-description">Salario bruto anual, base de cotización sobre la que se calcula el IRPF</p>
              </div>
            </div>

            <div class="reverse">
              <div class="salary-discount">
                <p class="salary-card-title">-{{ (this.contract.current_working_contract_variable_condition.irpf_anual_amount_avg_in_payrolls_for_worker + this.contract.current_working_contract_variable_condition.seguridad_social_anual_amount_avg_for_worker)/12 | currency:'EUR':'symbol':'1.0-0'}} ({{ (this.contract.current_working_contract_variable_condition.irpf_prcnt_avg_in_payrolls_for_worker + this.contract.current_working_contract_variable_condition.seguridad_social_prcnt_avg_for_worker)/100 | percent }}*)</p>
                <p class="salary-card-description">IRPF y Seguridad Social a cargo del trabajador</p>
                <mat-icon>east</mat-icon>
              </div>
              <div class="salary-card red" style="width: 50%; max-width: 200px;">
                <p class="salary-card-title">{{ this.contract.current_working_contract_variable_condition.total_net_anual_salary_with_trienniums_retflexible/12 | currency:'EUR':'symbol':'1.0-0' }}</p>
                <p class="salary-card-description">Salario neto anual que recibe el trabajador</p>
              </div>
            </div>
            <p style="font-size: 10px; margin: 10px 0 0">* Nota sobre el IRPF: Basado en lo que se suele poner en tu nómina. Es variable por cada caso particular y se basa en una recomendación en función de los tramos de la Renta que cobra el Estado.</p>
          </div>
        </div>
      </div>
    }
  </div>

  <div class="row">
    <div class="col-12">
      <div class="page-title">
        <h2>Mis nóminas</h2>
      </div>
    </div>
    <div class="col-12">
      <div class="card">
        <p>Próximamente...</p>
      </div>
    </div>
  </div>
</div>
