import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { CalendarEvent, CalendarMonthViewBeforeRenderEvent, CalendarView, DAYS_OF_WEEK } from 'angular-calendar';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { Contract } from 'src/app/models/contract.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AssignNewGuardDialogComponent } from './assign-new-guard-dialog/assign-new-guard-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PermissionsService } from 'src/app/services/permissions.service';

@Component({
  selector: 'app-project-guardian-scheduled-weeks',
  templateUrl: './project-guardian-scheduled-weeks.component.html',
  styleUrls: ['./project-guardian-scheduled-weeks.component.css']
})
export class ProjectGuardianScheduledWeeksComponent implements OnInit {

  now:Date = new Date();
  filterForm:FormGroup;
  events:CalendarEvent[] = [];
  viewDate: Date = new Date();
  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  weekendDays: number[] = [DAYS_OF_WEEK.SATURDAY, DAYS_OF_WEEK.SUNDAY];
  refresh = new Subject<void>();
  view: CalendarView = CalendarView.Month;
  private guardian_contracts:Contract[] = null as any;
  isProjectManagerOrProductManager:boolean = false;

  private project_id:string = null as any;

  constructor(private activatedRoute:ActivatedRoute,
              private utils:UtilsService,
              private api:ApiService,
              private dialog:MatDialog,
              private snack:MatSnackBar,
              private permissions:PermissionsService) { }

  ngOnInit(): void {
    this.project_id = this.activatedRoute.snapshot.parent?.parent?.paramMap.get("id") as string;
    this.onViewDateChange();
  }

  onViewDateChange() {
    this.events = [];
    this.fetchVacations();
    this.fetchHolidays();
    this.fetchGuardianContracts();
    this.fetchGuardianScheduledWeeks();
    this.initIsPMorPO();
  }

  async beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent) {
    const bgGreyCssClass:string = "bg-grey";

    while(this.guardian_contracts == null) {
      await this.utils.sleep(100)
    }


    renderEvent.body.forEach((day) => {
      let dayCssClass:string = "";
      const today:moment.Moment = moment(day.date);

      // bg-grey class
      let availableGuardian: boolean = false;
      if(this.guardian_contracts.length == 0) dayCssClass += bgGreyCssClass + " ";
      else {
        for(let contract of this.guardian_contracts) {
          const startsAt:moment.Moment = moment(contract.starts_at);
          if(contract.ends_at == null) {
            availableGuardian = today.isSameOrAfter(startsAt, 'day');
          }
          else if(contract.ends_at != null) {
            const endsAt:moment.Moment = moment(contract.ends_at)
            availableGuardian = today.isSameOrAfter(startsAt, 'day') && today.isSameOrBefore(endsAt)
          }

          if(availableGuardian) break;
        }

        if(!availableGuardian) dayCssClass += bgGreyCssClass + " ";
      }

      // clickable
      if(availableGuardian && today.isAfter(moment()) && today.isoWeekday() === 1) dayCssClass += "clickable "

      day.cssClass = dayCssClass;
    });
  }

  onDayClicked(event:{day:any, sourceEvent: MouseEvent|KeyboardEvent}) {
    const day:moment.Moment = moment(event.day.date);

    if(this.isProjectManagerOrProductManager && ['BUTTON', 'MAT-ICON'].indexOf((event.sourceEvent.target! as any).tagName as string) == -1) {
      if(((event.sourceEvent.target as any)!.closest('mwl-calendar-month-cell')).classList.contains('clickable')) {
        this.assignNewGuard(day.toDate());
      }
      else if(day.isoWeekday() !== 1) {
        this.snack.open('Deberías seleccionar un lunes.', 'OK')
      }
      else {
        this.snack.open('Este día no es válido para asignar guardias. Debes seleccionar un día en azul.', 'OK')
      }
    }
  }

  assignNewGuard(date:Date) {
    const dialogRef = this.dialog.open(AssignNewGuardDialogComponent, {
      width: '400px',
      data: {
        project_id: this.project_id,
        from: moment(date).startOf('week'),
        to: moment(date).endOf('week'),
      }
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data) {
          this.onViewDateChange();
        }
      }
    );
  }

  unassignGuard(day:{events:any[]}) {
    if(day.events.length && confirm("¿Estás seguro que quieres desasignar las guardias de esta semana? Se notificará a las personas implicadas.")) {
      for(let event of day.events) {
        if(event.meta.type === "guardian") {
          // ha de ser eliminat
          this.api.deleteGuardianScheduledWeek(event.meta.id).subscribe(
            data => {
              this.onViewDateChange();
            }
          );
        }
      }
    }
  }

  private fetchVacations() {
    const from = moment(this.viewDate).startOf('month').startOf('week').toDate();
    const to = moment(this.viewDate).endOf('month').endOf('week').toDate();
    this.api.getProjectVacations(this.project_id, from, to).subscribe(
      data => {
        this.events.push(...data.map(item => {
          return {
            title: item.user.name + ' ' + item.user.surnames,
            color: {
              primary: this.stringToColour(item.user.name + ' ' + item.user.surnames),
              secondary: '#FAE3E3',
            },
            start: item.from_date,
            end: item.to_date,
            allDay: item.all_day,
            meta: {
              id: item.id,
              type: 'vacation',
            }
          };
        }));
        this.refresh.next();
      }
    );
  }

  private stringToColour(str:string) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
      var value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  private fetchHolidays() {
    const from = moment(this.viewDate).startOf('month').startOf('week').toDate();
    const to = moment(this.viewDate).endOf('month').endOf('week').toDate();
    this.api.getProjectHolidays(this.project_id, from, to).subscribe(
      data => {
        let grouped: any[] = [];

        data.forEach(item => {
          const index = grouped.findIndex((i:any) => i.slug == item.slug);
          if(index==-1) {
            grouped.push({
              name: item.name,
              slug: item.slug,
              users: item.username + (item.surnames ? ' ' + item.surnames : ''),
              from_date: item.date,
              to_date: item.date,
              all_day: true,
            });
          } else {
            grouped[index].users += ', ' + item.username + (item.surnames ? ' ' + item.surnames : '');
          }
        });

        this.events.push(...grouped.map(item => {
          return {
            title: item.name + ' (' + item.users + ')',
            color: {
              primary: '#FFD700',
              secondary: '#FAE3E3',
            },
            start: item.from_date,
            end: item.to_date,
            allDay: true,
            meta: {
              id: item.id,
              type: 'holiday',
            }
          };
        }));

        this.refresh.next();
      }
    );
  }

  private fetchGuardianContracts() {
    this.guardian_contracts = null as any;
    const from = moment(this.viewDate).startOf('month').startOf('week').toDate();
    const to = moment(this.viewDate).endOf('month').endOf('week').toDate();
    this.api.getProjectGuardianContracts(this.project_id, from, to).subscribe(
      data => {
        this.guardian_contracts = data;
      }
    );
  }

  private fetchGuardianScheduledWeeks() {
    const from = moment(this.viewDate).startOf('month').startOf('week');
    const to = moment(this.viewDate).endOf('month').endOf('week');
    const body:any = { project_id: this.project_id, from: from.format('YYYY-MM-DD'), to: to.format('YYYY-MM-DD'), with: 'user' };
    this.api.getGuardianScheduledWeeks(body).subscribe(
      data => {
        this.events.push(...data.map(item => {
          return {
            title: item.user.name + ' ' + item.user.surnames,
            color: {
              primary: this.stringToColour(item.user.name + ' ' + item.user.surnames),
              secondary: '#FAE3E3',
            },
            start: item.start_week,
            end: item.to_week,
            allDay: true,
            meta: {
              id: item.id,
              type: 'guardian',
              user: item.user
            }
          };
        }));
        this.refresh.next();
      }
    )
  }

  private initIsPMorPO() {
    this.isProjectManagerOrProductManager = this.permissions.isWorkerWithProjectRole(this.project_id, 'tech-lead') || this.permissions.isWorkerWithProjectRole(this.project_id, 'product-manager');
  }

}
