<h1 mat-dialog-title>Solicitar formación</h1>

<form mat-dialog-content [formGroup]="form" class="container">
  <div class="row">
    <div class="col-12">
      <p>
        En Bisual apostamos por la formación de todo el equipo. Mientras Bisual se prepara para ofrecer un programa de formación contínua, estructurada y coherente, se ha decidido ofrecer este formulario para poder solicitar formaciones.<br>
        <br>
        Por favor introduce los detalles del curso, como se puede acceder a él, qué es lo que te motiva a hacerlo y como puede beneficiar tanto a ti como a Bisual.<br>
        <br>
        Los cursos pueden ser online, presenciales, dentro o fuera de FUNDAE, de Udemy, grados, másters, etc.<br>
        <br>
        Una vez enviados los detalles, el equipo de administración de Bisual decidirá sobre qué condiciones se realiza: si Bisual lo paga (total, parcial o no), en horario laboral o no, etc.
      </p>
    </div>
    <div class="col-12 col-lg-8">
      <mat-form-field appearance="outline">
        <mat-label>Título de la formación</mat-label>
        <input matInput formControlName="title" />
      </mat-form-field>
    </div>
    <div class="col-12 col-lg-4">
      <mat-form-field appearance="outline">
        <mat-label>Precio</mat-label>
        <input matInput formControlName="price" />
      </mat-form-field>
    </div>
    <div class="col-12">
      <mat-form-field appearance="outline">
        <mat-label>Observaciones</mat-label>
        <textarea matInput rows="5" formControlName="observations" placeholder="Introduce el link del curso, de qué va, tus motivaciones y como pueden ayudarte tanto a ti como a Bisual a crecer."></textarea>
      </mat-form-field>
    </div>
  </div>
</form>

<mat-dialog-actions align="end">
  <button mat-flat-button (click)="close()" type="button">Cancelar</button>
  <button mat-flat-button color="accent" (click)="submit()" [disabled]="!form.valid" type="submit">
    <mat-icon>send</mat-icon>
    Enviar solicitud
  </button>
</mat-dialog-actions>
