import { HttpClient, HttpEvent, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { Observable, scan } from 'rxjs';
import { EmployeeService } from 'src/app/services/employee.service';
import { PlatformService } from 'src/app/services/platform.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-download-new-version-electron-app-dialog',
  templateUrl: './download-new-version-electron-app-dialog.component.html',
  styleUrl: './download-new-version-electron-app-dialog.component.css'
})
export class DownloadNewVersionElectronAppDialogComponent implements OnInit {
  now:moment.Moment = moment();
  private download_url:string;
  download$: Observable<Download>;

  constructor(public dialogRef: MatDialogRef<DownloadNewVersionElectronAppDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:{current_version:string;new_version:string;uploaded_at:moment.Moment,block:boolean},
              private platform:PlatformService,
              private http:HttpClient) {
                // per test: this.download_url = `https://testapi.bisual.net/storage/intranet/dist_electron/BisualIntranetApp-v${data.new_version}-mac.zip`;
                this.download_url = `${environment.laravel_url}/storage/intranet/dist_electron/BisualIntranetApp-v${data.new_version}-mac.zip`;
              }

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }

  startDownload() {
    const filename:string = this.download_url.split("/").pop() as string;
    this.download$ = this.http.get(this.download_url, {
      reportProgress: true,
      observe: 'events',
      responseType: 'blob'
    }).pipe(this.download(blob => {
      saveAs(blob, filename);
      this.platform.quitAndInstallNewVersion(filename);
    }));
  }

  private isHttpResponse<T>(event: HttpEvent<T>): event is HttpResponse<T> {
    return event.type === HttpEventType.Response
  }

  private isHttpProgressEvent(event: HttpEvent<unknown>): event is HttpProgressEvent {
    return event.type === HttpEventType.DownloadProgress
        || event.type === HttpEventType.UploadProgress
  }

  private download(saver?: (b: Blob) => void): (source: Observable<HttpEvent<Blob>>) => Observable<Download> {
    return (source: Observable<HttpEvent<Blob>>) =>
      source.pipe(
        scan((previous: Download, event: HttpEvent<Blob>): Download => {
            if (this.isHttpProgressEvent(event)) {
              return {
                progress: event.total
                  ? Math.round((100 * event.loaded) / event.total)
                  : previous.progress,
                state: 'IN_PROGRESS',
                content: null
              }
            }
            if (this.isHttpResponse(event)) {
              if (saver && event.body) {
                saver(event.body)
              }
              return {
                progress: 100,
                state: 'DONE',
                content: event.body
              }
            }
            return previous
          },
          {state: 'PENDING', progress: 0, content: null}
        )
      )
  }
}

interface Download {
  state: 'PENDING' | 'IN_PROGRESS' | 'DONE'
  progress: number
  content: Blob | null
}
