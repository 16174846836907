import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-html-dialog',
  templateUrl: './html-dialog.component.html',
  styleUrl: './html-dialog.component.css'
})
export class HtmlDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<HtmlDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data:{title:string;html:SafeHtml,dialog_close_button:string}) {}

  ngOnInit(): void {

  }

  close() {
    this.dialogRef.close();
  }
}
