<div id="content">
  <div class="page-title">
    <h1>Proyectos</h1>
    <button mat-flat-button color="accent" [disabled]="!permissions.validateDepartment(['direction'])">
      <mat-icon style="margin-right: 10px">add_user</mat-icon>
      Iniciar proyecto nuevo
    </button>
  </div>

  <div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        (page)="changePage($event)"
        style="margin-right: auto;"
      ></mat-paginator>

      <mat-form-field appearance="outline" class="bisual-paginator-select">
        <mat-label>Favorito</mat-label>
        <mat-select formControlName="ro_is_favorite">
          <mat-option [value]="">Todos</mat-option>
          <mat-option [value]="1">Sí</mat-option>
          <mat-option [value]="0">No</mat-option>

        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="bisual-paginator-select">
        <mat-label>Estado</mat-label>
        <mat-select formControlName="ro_is_active">
          <mat-option [value]="">Todos</mat-option>
          <mat-option [value]="1">Activo</mat-option>
          <mat-option [value]="0">Finalizado</mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <input matInput type="text" formControlName="search">
        <button matSuffix mat-icon-button aria-label="Clear">
          <mat-icon>search</mat-icon>
        </button>
      </mat-form-field>
    </form>

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="ro_is_favorite">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let project">
            <button mat-icon-button (click)="updateFavoriteProject(project)" class="favorite-icon">
              <mat-icon>{{project.ro_is_favorite ? 'star' : 'star_outline'}}</mat-icon>
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef>Proyecto</th>
          <td mat-cell *matCellDef="let project">
            <div class="d-flex">
              <div class="project-logo" [ngStyle]="{'background-image': 'url(' + project.logo_url + ')'}"></div>
              <a [routerLink]="[ project.id ]">{{ project.title }}</a>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="phases">
          <th mat-header-cell *matHeaderCellDef>Fases</th>
          <td mat-cell *matCellDef="let project">{{ project.project_phases.length==1 ? '1 fase' : (project.project_phases.length + ' fases') }}</td>
        </ng-container>

        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef>Cliente</th>
          <td mat-cell *matCellDef="let project">
            @if (project.company_id==null) {
              <span>Bisual</span>
            }
            @if (project.company_id!=null) {
              <a [routerLink]="['/employees', 'direction', 'users-management', 'companies', project.company_id]">{{ project.company.fact_name }}</a>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="product_manager">
          <th mat-header-cell *matHeaderCellDef>Product Manager</th>
          <td mat-cell *matCellDef="let project">
            {{ project.product_manager != null ? (project.product_manager.user.name + ' ' + project.product_manager.user.surnames) : '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="tech_lead">
          <th mat-header-cell *matHeaderCellDef>Project Manager</th>
          <td mat-cell *matCellDef="let project">
            {{ project.tech_lead != null ? (project.tech_lead.user.name + ' ' + project.tech_lead.user.surnames) : '-' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="contract">
          <th mat-header-cell *matHeaderCellDef>Contrato</th>
          <td mat-cell *matCellDef="let project">
            @for (contract of project.active_contracts; track contract; let i = $index) {
              <span>
                {{
                (contract.type === "project" ?
                'Genuine' :
                (
                contract.type === 'partner' ? (
                contract.current_contract_variable_condition != null ?
                ('Partner (' + (contract.current_contract_variable_condition.partner_monthly_hours ?? '?? ') + 'h)') :
                'Expirado'
                ) : (
                contract.type === "guardian" ?
                'Guardian 24/7' :
                '??'
                )
                )) + (i == project.active_contracts.length-1 ? '' : ', ')
                }}
              </span>
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let project; let i = index;">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item [routerLink]="[project.id]">
                <mat-icon style="margin-right: 10px">login</mat-icon>
                Acceder
              </button>
              <button mat-menu-item (click)="shareScreen(project.id)">
                <mat-icon style="margin-right: 10px">screen_share</mat-icon>
                Modo reunión
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
