import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { BisualSetting } from '../models/bisual-setting.model';
import { EmployeeService } from './employee.service';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class BisualSettingService {

  private bisualSettingSubject:BehaviorSubject<{[key:string]:BisualSetting}> = new BehaviorSubject(null as any);
  public bisualSettingObservable:Observable<{[key:string]:BisualSetting}> = this.bisualSettingSubject.asObservable();

  constructor(private api:ApiService,
              private employee:EmployeeService) { }

  init(user:User) {
    this.fetch(user);
  }

  getSettings() {
    return this.bisualSettingSubject.value;
  }

  get(key:string) {
    return this.bisualSettingSubject.value[key];
  }

  private fetch(user:User) {
    this.api.getBisualSettings({
      key: 'force_employees_to_use_desktop_app,frontend_version_required'
    }).subscribe(
      data => {
        let res:{[key:string]:BisualSetting} = {};
        data.forEach(d => {
          res[d.key] = d;
        })
        this.bisualSettingSubject.next(res);
        if(user.role.slug==="worker") {
          this.employee.init(user, this.get('force_employees_to_use_desktop_app').value === 'true', this.get('frontend_version_required'));
        }
      }
    );
  }
}
