<h1 mat-dialog-title>{{ title }}</h1>
<mat-dialog-content>
  <ngx-dropzone (change)="onSelect($event)" [accept]="accepted_file_types">
    <ngx-dropzone-label>{{title}}</ngx-dropzone-label>
    @for (f of noImages(); track f) {
      <ngx-dropzone-preview [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-preview>
    }
    @for (f of images(); track f) {
      <ngx-dropzone-image-preview ngProjectAs="ngx-dropzone-preview" [file]="f"></ngx-dropzone-image-preview>
    }
  </ngx-dropzone>
</mat-dialog-content>

<div mat-dialog-actions>
  <button mat-button (click)="close()" cdkFocusInitial type="button">Cerrar</button>
  <button mat-button color="accent" (click)="done()" type="button" [disabled]="!files || files!.length==0">Hecho</button>
</div>
