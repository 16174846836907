import { Component, OnInit } from '@angular/core';
import { Project } from 'src/app/models/project.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-lateral-menu-employee',
  templateUrl: './lateral-menu-employee.component.html',
  styleUrls: ['./lateral-menu-employee.component.css']
})
export class LateralMenuEmployeeComponent implements OnInit {

  project: Project|undefined;

  constructor(public permissions: PermissionsService,
    private api: ApiService,
    public employee:EmployeeService
  ) {
  }

  ngOnInit(): void {
    this.fetchProject();
  }

  private fetchProject() {
    this.employee.meetingModeProject.subscribe((project_id: number|null) => {
      if (project_id!=null) {
        this.api.getProject(project_id.toString()).subscribe((project: Project) => {
          this.project = project;
        });
      }
    });
  }

}
