import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import {
  CalendarEvent, CalendarMonthViewBeforeRenderEvent
} from 'angular-calendar';
import { EventColor, DAYS_OF_WEEK } from 'calendar-utils';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { EmployeeCalendarRegister } from 'src/app/models/employee_calendar_register.model';
import { Holiday } from 'src/app/models/holiday.model';
import { User } from 'src/app/models/user.model';
import { VacationRequest } from 'src/app/models/vacation_request';
import { VacationRequestPeriod } from 'src/app/models/vacation_request_period';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';


@Component({
  selector: 'app-modal-my-absences',
  templateUrl: './modal-my-absences.component.html',
  styleUrls: ['./modal-my-absences.component.css']
})
export class ModalMyAbsencesComponent implements OnInit {

  working_contract:WorkingContract;
  viewDate: Date = new Date();
  private user:User;
  refresh = new Subject<void>();
  locale: string = 'es';
  weekStartsOn: number = DAYS_OF_WEEK.MONDAY;
  events: CalendarEvent[] = [];

  vacation_request_periods_to_ask:{vrp:VacationRequestPeriod; hours:number}[] = [];

  get spinner_prcnt():number {
    if(this.hours_left == null || this.hours_total == null || this.hours_left == 0 || this.hours_total == 0) return 0;
    else return this.hours_left / this.hours_total * 100;
  }
  hours_left:number = null as any;
  days_left:number = null as any;
  hours_total:number = null as any;
  days_total:number = null as any;

  private is_sending_request:boolean = false;
  get reservarButtonDisabled(): boolean {
    return this.vacation_request_periods_to_ask.length == 0 || this.is_sending_request;
  }

  constructor(
    public dialogRef: MatDialogRef<ModalMyAbsencesComponent>,
    private api: ApiService,
    private utils: UtilsService,
    private _user:UserService,
    @Inject(MAT_DIALOG_DATA) public data:any,
    private snack:MatSnackBar
  ) {

  }

  ngOnInit(): void {
    this.getUser();
    this.fetchActiveWorkingContract();
    this.fetchCalendarData();
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    const mom_date:moment.Moment = moment(date);
    const events_vacation_request_local = events.filter(e => e.meta.type==="vacation_request_local");
    const events_holidays = events.filter(e => e.meta.type==="holiday");
    const events_vacation_request = events.filter(e => e.meta.type==="vacation_request");
    const events_vacation = events.filter(e => e.meta.type==="vacation");
    if(events_holidays.length > 0) this.snack.open("Estás intentando reservar vacaciones en un dia festivo... :S", "No quiero doble fiesta", { duration: 3000 });
    else if(new Date() >= date) this.snack.open("Estás intentando reservar días de vacaciones para... ¿el pasado?.", "Me he equivocado", { duration: 3000 });
    else if(!this.isDayInContract(date)) this.snack.open("Tu contrato actual se habrá terminado ese dia.", "Ok", { duration: 3000 });
    else if(events_vacation_request.length > 0) this.snack.open("Ya has pedido vacaciones ese día.", "Ok", { duration: 3000 });
    else if(events_vacation.length > 0) this.snack.open("Ya tienes vacaciones ese día.", "Ok", { duration: 3000 });
    else if(events_vacation_request_local.length == 0) {
      // creem
      const hours = this.working_contract.user_weekly_expected_hours[mom_date.day()];
      if(hours <= 0) this.snack.open("No tenemos previsto que trabajes ese dia.", "Ok", { duration: 3000 });
      else if(hours > this.hours_left) this.snack.open("No puedes reservar más horas.", "Ok", { duration: 3000 })
      else {
        const new_vacation_request_period = this.createVacationRequestLocalFromDate(mom_date);
        this.vacation_request_periods_to_ask.push(new_vacation_request_period);
        this.events.push(this.vacationRequestPeriodToEvent(new_vacation_request_period.vrp, true));
        this.hours_left -= hours;
      }
    }
    else {
      // Eliminem
      this.events = this.events.filter(e => {
        return e.meta.type!=="vacation_request_local" || !moment(e.start).isSame(mom_date, 'day');
      });
      let hoursDeleted:number = 0;
      this.vacation_request_periods_to_ask = this.vacation_request_periods_to_ask.filter(vrp => {
        const res = !moment(vrp.vrp.day).isSame(date, 'day');
        if(!res) hoursDeleted += vrp.hours;
        return res;
      });
      this.hours_left += hoursDeleted;
    }

    this.refresh.next();
  }

  handleEvent(action: string, event: CalendarEvent): void {
    // this.modalData = { event, action };
    // this.modal.open(this.modalContent, { size: 'lg' });
  }

  beforeMonthViewRender(renderEvent: CalendarMonthViewBeforeRenderEvent): void {
    const now = new Date();

    renderEvent.body.forEach((day) => {
      const date = day.date;

      if(
        // Dies anteriores/posteriores al contrato actual
        (this.working_contract.start_date > date || (this.working_contract.end_date!=null && this.working_contract.end_date < date)) ||
        // No trabaja ese dia
        (!this.worksOnDayOfWeek(date)) ||
        // hay holidays
        (this.someEventIsHoliday(day.events)) ||
        // hay vacaciones ya cogidas
        (this.someEventIsVacation(day.events)) ||
        // hay peticiones de vacaciones
        (this.someEventIsVacationRequest(day.events))
      ) {
        day.cssClass += ' bg-not-working-day';
      }
    });
  }

  changeMonth(num:number) {
    this.fetchCalendarData();
  }

  close(data:any = null) {
    this.dialogRef.close(data);
  }

  reservar() {
    let vr:VacationRequest = {
      vacation_request_periods: this.vacation_request_periods_to_ask.map(vrp => {
        let res = vrp.vrp;
        res.day = moment(res.day).format('YYYY-MM-DD') as any;
        return res;
      })
    } as any;

    this.api.createVacationRequest(vr).subscribe(
      data => {
        this.close({ refresh: true });
      }
    );
  }

  private getUser() {
    this.user = this._user.getCurrentUser();
  }

  private fetchCalendarData() {
    this.events = [];
    this.fetchAbsences();
  }

  private fetchActiveWorkingContract() {
    this.api.getActiveWorkingContract(this.user.id, { day: this.utils.dateToStringYYYYMMDD(this.viewDate), appends: "user_weekly_expected_hours" }).subscribe(
      data => {
        this.working_contract = data;

        // setup spinner
        this.hours_total = this.working_contract.vacation_days_info.vacations_total_days_available.hours_total;
        this.days_total = this.working_contract.vacation_days_info.vacations_total_days_available.days_total;
        this.hours_left = this.hours_total - this.working_contract.vacation_days_info.vacations_days_spent.hours_total;
        this.days_left = this.days_total - this.working_contract.vacation_days_info.vacations_days_spent.days_total;
      }
    );
  }

  private fetchAbsences() {
    this.is_sending_request = true;
    const selected_date:moment.Moment = moment(this.viewDate);
    this.api.getMyAbsences(
      selected_date.clone().startOf('month').startOf('week').toDate(),
      selected_date.clone().endOf('month').endOf('week').toDate()
    ).subscribe(
      data => {
        // Processem vacation requests pending
        data.vacation_requests.forEach(vr => {
          vr.vacation_request_periods.forEach(vrp => {
            this.events.push(this.vacationRequestPeriodToEvent(vrp));
          });
        });

        // Processem Holidays
        data.holidays.forEach(h => {
          this.events.push(this.holidayToEvent(h));
        });

        // Processem vacances
        data.employee_calendar_registers.filter(ecr => ecr.all_day).forEach(ecr => {
          // suposem que tots els que ens arriben aqui son amb is_working_time = false
          this.events.push(this.ecrToEvent(ecr));
        });

        // Processem vacation requests que tenim ara
        this.vacation_request_periods_to_ask.forEach(vrp => {
          this.events.push(this.vacationRequestPeriodToEvent(vrp.vrp, true));
        });

        this.refresh.next();
      },
      error => {

      },
      () => {
        this.is_sending_request = false;
      }
    );
  }

  private vacationRequestPeriodToEvent(vrp:VacationRequestPeriod, local_only:boolean = false): CalendarEvent<any> {
    return {
      start: moment(vrp.day).startOf('day').toDate(),
      end: moment(vrp.day).endOf('day').toDate(),
      title: 'Petición de vacaciones pendiente',
      color: local_only ? colors.vacation_requests_local : colors.vacation_requests,
      actions: undefined,
      allDay: true,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
      draggable: false,
      meta: { type: local_only ? 'vacation_request_local' : 'vacation_request' }
    };
  }

  private holidayToEvent(h:Holiday): CalendarEvent<any> {
    return {
      start: moment(h.date).startOf('day').toDate(),
      end: moment(h.date).endOf('day').toDate(),
      title: h.name,
      color: colors.holiday,
      actions: undefined,
      allDay: true,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
      draggable: false,
      meta: { type: 'holiday' }
    };
  }

  private ecrToEvent(ecr:EmployeeCalendarRegister) {
    const cat = (ecr.working_category as WorkingTimeCategory);
    return {
      start: ecr.from_date,
      end: ecr.all_day ? moment(ecr.from_date).endOf('day').toDate() : ecr.to_date,
      title: cat.name,
      color: { primary: cat.color, secondary: '#000' },
      actions: undefined,
      allDay: ecr.all_day,
      resizable: {
        beforeStart: false,
        afterEnd: false,
      },
      draggable: false,
      meta: { type: 'ecr' }
    };
  }

  private createVacationRequestLocalFromDate(mom:moment.Moment) {
    return {
      hours: this.working_contract.user_weekly_expected_hours[mom.day()],
      vrp: {
        id: null as any,
        vacation_request_id: null as any,
        day: mom.toDate(),
        created_at: null as any,
        updated_at: null as any
      }
    };
  }

  private isDayInContract(date:Date) {
    return this.working_contract.start_date <= date && (this.working_contract.end_date==null || this.working_contract.end_date >= date);
  }

  private worksOnDayOfWeek(date:Date): boolean {
    return this.working_contract.user_weekly_expected_hours[date.getDay()]>0;
  }

  private someEventIsHoliday(events:CalendarEvent[]): boolean {
    for(let e of events) {
      if(e.meta.type==='holiday') return true;
    }
    return false;
  }

  private someEventIsVacation(events:CalendarEvent[]): boolean {
    for(let e of events) {
      if(e.meta.type==='ecr') return true;
    }
    return false;
  }

  private someEventIsVacationRequest(events:CalendarEvent[]): boolean {
    for(let e of events) {
      if(e.meta.type==='vacation_request') return true;
    }
    return false;
  }

  stopAnimation() {
    document.querySelectorAll('.tilt-n-move-shaking').forEach(element => {
      element.classList.remove('tilt-n-move-shaking');
    });
  }
}

const colors: Record<string, EventColor> = {
  vacation: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  holiday: {
    primary: '#1e90ff',
    secondary: '#E3B163',
  },
  vacation_requests: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  vacation_requests_local: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
};
