@if (article) {
  <div id="content" class="extensible">
    <div class="page-title">
      <button mat-icon-button [routerLink]="['/employees','wiki',article.wiki_node_id!=null ? article.wiki_node_id : upper_node_id]">
        <mat-icon>arrow_back</mat-icon>
      </button>
      @if (!editMode) {
        <h1 class="">{{ article.wiki_article_version.title }}</h1>
      }
      @if (editMode) {
        <input [(ngModel)]="article.wiki_article_version.title" type="text" class="form-control" id="title" placeholder="Título">
      }
      <!-- Button change edit mode using mat icon button -->
      @if (environments != null) {
        <mat-form-field appearance="outline">
          <mat-label>Escoge entorno</mat-label>
          <mat-select [ngModel]="application_environment_id" (ngModelChange)="onEnvironmentChanged($event)">
            @for (env of environments; track env) {
              <mat-option [value]="env.id">
                {{ env.application?.name }} ({{ env.role }} - {{ env.git_ref }})
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      }
      <button mat-icon-button (click)="showIndice = !showIndice">
        <mat-icon>segment</mat-icon>
      </button>
      @if (!editMode && article.is_editable) {
        <button mat-icon-button (click)="toggleEdit()">
          <mat-icon>edit</mat-icon>
        </button>
      }
      <!-- Button change edit mode using mat icon button -->
      @if (article.id!=null && !editMode) {
        <button mat-icon-button [matMenuTriggerFor]="menu">
          <mat-icon>more_vert</mat-icon>
        </button>
      }
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="deleteArticle()">
          <mat-icon>delete</mat-icon>
          <span>Borrar documento</span>
        </button>
        <button mat-menu-item (click)="updateIsEditableAttribute()">
          <mat-icon>draw</mat-icon>
          <span>{{ 'Hacer ' + (article.is_editable ? 'no' : '') + ' editable' }}</span>
        </button>
        <button mat-menu-item (click)="showMoveWikiItemDialog()">
          <mat-icon>move_up</mat-icon>
          <span>Mover</span>
        </button>
        <button mat-menu-item (click)="downloadAsPDF()">
          <mat-icon>download</mat-icon>
          <span>Descargar</span>
        </button>
      </mat-menu>
    </div>
    <div class="d-flex">
      @if (showIndice) {
        <div class="toc">
          <h2>Índice</h2>
          <ul class="card">
            @for (item of indice; track item) {
              <li [style.marginLeft.px]="(item.level - 1) * 20">
                <a (click)="scrollToTitle(item.title)">{{ item.title }}</a>
              </li>
            }
          </ul>
        </div>
      }
      <div class="card w-100">
        <editor
          *ngIf="editMode"
          [(ngModel)]="article.wiki_article_version.description"
          [apiKey]="tinymce.getKey()"
          cloudChannel="6"
          [disabled]="false"
          id="description"
          [init]="tinyMceInit"
          initialValue=""
          [inline]="false"
          tagName="div"
          read
          plugins= "anchor autolink charmap codesample directionality help image insertdatetime link lists media nonbreaking pagebreak searchreplace table visualblocks visualchars wordcount advlist autolink lists link image charmap preview anchor searchreplace visualblocks code fullscreen insertdatetime media table emoticons codesample toc"
          toolbar= "undo redo spellcheckdialog | blocks fontfamily fontsize | codesample | bold italic underline strikethrough | forecolor backcolor | insertfile link image | align lineheight checklist bullist numlist | indent outdent | removeformat typography | table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol toc">
        </editor>
        @if (!editMode) {
          <!-- @if(articleId === "workers-salary-policy") {
            <p>Introduce una fecha para poder ver la evolución de los sueldos:</p>
            <mat-form-field appearance="outline">
              <
            </mat-form-field>
          } -->
          <div id="article-content" [innerHTML]="articleContent"></div>
        }
        @if (article.wiki_article_version != null && article.wiki_article_version.created_by != null && !editMode) {
          <p id="article-signature">
            {{ (article.wiki_article_version.created_at | date:'short') + ' - ' + article.wiki_article_version.created_by.name + ' ' + article.wiki_article_version.created_by.surnames }}
          </p>
        }
        @if (editMode) {
          <div class="editorButtons ">
            <button (click)="editMode = !editMode; fetchWikiArticle()" class="cancel">
              Cerrar
            </button>
            <button (click)="save()" class="save">
              Guardar
            </button>
          </div>
        }
      </div>
    </div>
  </div>
}
