import { NgModule } from '@angular/core';
import { ChildActivationEnd, RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { LoginComponent } from './components/auth/login/login.component';
import { RestorePasswordComponent } from './components/auth/restore-password/restore-password.component';
import { BillingComponent } from './components/main/employees/direction/billing/billing.component';
import { ConfigurationComponent } from './components/main/employees/direction/configuration/configuration.component';
import { DashboardComponent } from './components/main/employees/dashboard/dashboard.component';
import { HostingDetailComponent } from './components/main/employees/project-management/services/hostings/hosting-detail/hosting-detail.component';
import { LegalDocumentationComponent } from './components/main/employees/direction/legal-documentation/legal-documentation.component';
import { MainComponent } from './components/main/main.component';
import { ProjectDetailComponent } from './components/main/shared/projects/project-detail/project-detail.component';
import { ProjectsComponent } from './components/main/shared/projects/projects.component';
import { BusinessMailsComponent } from './components/main/employees/project-management/services/business-mails/business-mails.component';
import { DomainsComponent } from './components/main/employees/project-management/services/domains/domains.component';
import { HostingsComponent } from './components/main/employees/project-management/services/hostings/hostings.component';
import { ServicesComponent } from './components/main/employees/project-management/services/services.component';
import { SupportComponent } from './components/main/employees/support/support.component';
import { UsersDetailComponent } from './components/main/employees/direction/users-management/users/users-detail/users-detail.component';
import { UsersComponent } from './components/main/employees/direction/users-management/users/users.component';
import { LoggedGuard } from './guards/logged.guard';
import { DomainDetailComponent } from './components/main/employees/project-management/services/domains/domain-detail/domain-detail.component';
import { RegexServiceInitiatedGuard } from './guards/regex-service-initiated.guard';
import { BusinessMailDetailComponent } from './components/main/employees/project-management/services/business-mails/business-mail-detail/business-mail-detail.component';
import { TestComponent } from './components/main/employees/quick-actions/test/test.component';
import { BillingResumeComponent } from './components/main/employees/direction/billing/billing-resume/billing-resume.component';
import { BillingOrdersComponent } from './components/main/employees/direction/billing/billing-orders/billing-orders.component';
import { BillingProviderOrdersComponent } from './components/main/employees/direction/billing/billing-provider-orders/billing-provider-orders.component';
import { BillingContasimpleDashboardComponent } from './components/main/employees/direction/billing/billing-contasimple-dashboard/billing-contasimple-dashboard.component';
import { BillingAlertsComponent } from './components/main/employees/direction/billing/billing-alerts/billing-alerts.component';
import { UsersManagementComponent } from './components/main/employees/direction/users-management/users-management.component';
import { CompaniesComponent } from './components/main/employees/direction/users-management/companies/companies.component';
import { CompanyDetailComponent } from './components/main/employees/direction/users-management/companies/company-detail/company-detail.component';
import { ProvidersComponent } from './components/main/employees/direction/users-management/providers/providers.component';
import { ProviderDetailComponent } from './components/main/employees/direction/users-management/providers/provider-detail/provider-detail.component';
import { WorkingTimesResumeComponent } from './components/main/employees/my-zone//working-times-resume/working-times-resume.component';
import { WorkingTimesHistoryComponent } from './components/main/employees/my-zone/working-times-history/working-times-history.component';
import { WorkingTimesCalendarComponent } from './components/main/employees/my-zone/working-times-calendar/working-times-calendar.component';
import { BlogpostsComponent } from './components/main/employees/marketing/blogposts/blogposts.component';
import { ClockRegistersComponent } from './components/main/employees/my-zone/clock-registers/clock-registers.component';
import { VacationsComponent } from './components/main/employees/rrhh/absences-management/vacations/vacations.component';
import { MedicalSickLeavesComponent } from './components/main/employees/rrhh/absences-management/medical-sick-leaves/medical-sick-leaves.component';
import { TicketsComponent } from './components/main/employees/support/tickets/tickets.component';
import { MyAbsencesComponent } from './components/main/employees/my-zone/my-absences/my-absences.component';
import { MyPayrollsComponent } from './components/main/employees/my-zone/my-payrolls/my-payrolls.component';
import { ProjectManagementComponent } from './components/main/employees/project-management/project-management.component';
import { MarketingComponent } from './components/main/employees/marketing/marketing.component';
import { RrhhComponent } from './components/main/employees/rrhh/rrhh.component';
import { DirectionComponent } from './components/main/employees/direction/direction.component';
import { ProductionTeamGuard } from './guards/roles/production_team.guard';
import { MarketingGuard } from './guards/roles/marketing.guard';
import { RrhhGuard } from './guards/roles/rrhh.guard';
import { DirectionGuard } from './guards/roles/direction.guard';
import { AbsencesManagementComponent } from './components/main/employees/rrhh/absences-management/absences-management.component';
import { PayrollsComponent } from './components/main/employees/rrhh/payrolls/payrolls.component';
import { VacationRequestsComponent } from './components/main/employees/rrhh/absences-management/vacation-requests/vacation-requests.component';
import { ClockRegistersManagementComponent } from './components/main/employees/rrhh/clock-registers-management/clock-registers-management.component';
import { WorkersComponent } from './components/main/employees/rrhh/workers/workers.component';
import { DepartmentsInitiatedGuard } from './guards/departments-initiated.guard';
import { SupportGuard } from './guards/roles/support.guard';
import { ManageVacationRequestComponent } from './components/main/employees/rrhh/absences-management/vacation-requests/manage-vacation-request/manage-vacation-request.component';
import { ProjectResumeComponent } from './components/main/shared/projects/project-detail/project-resume/project-resume.component';
import { ClockRegisterReviewRequestsComponent } from './components/main/employees/rrhh/clock-registers-management/clock-register-review-requests/clock-register-review-requests.component';
import { ClockRegisterResumeComponent } from './components/main/employees/rrhh/clock-registers-management/clock-register-resume/clock-register-resume.component';
import { DepartmentsComponent } from './components/main/employees/rrhh/departments/departments.component';
import { DepartmentDetailComponent } from './components/main/employees/rrhh/departments/department-detail/department-detail.component';
import { DepartmentCategoryDetailComponent } from './components/main/employees/rrhh/departments/department-detail/department-category-detail/department-category-detail.component';
import { EmployeeGuard } from './guards/employee.guard';
import { ClientsGuard } from './guards/clients.guard';
import { WorkersPlanningComponent } from './components/main/employees/rrhh/workers-planning/workers-planning.component';
import { ProjectPhasesComponent } from './components/main/shared/projects/project-detail/project-phases/project-phases.component';
import { PlanningRoadmapComponent } from './components/main/employees/project-management/planning-roadmap/planning-roadmap.component';
import { ProjectAssignmentComponent } from './components/main/employees/project-management/project-assignment/project-assignment.component';
import { ProjectPhasesDetailComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-detail.component';
import { ProjectPhaseBacklogComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-backlog/project-phase-backlog.component';
import { ProjectPhaseBoardsComponent } from './components/main/shared/projects/project-detail/project-phases/project-phase-detail/project-phase-boards/project-phase-boards.component';
import { BillingPlanningComponent } from './components/main/employees/direction/billing/billing-planning/billing-planning.component';
import { ComercialComponent } from './components/main/employees/comercial/comercial.component';
import { ComercialGuard } from './guards/roles/comercial.guard';
import { PotentialClientsComponent } from './components/main/employees/comercial/potential-clients/potential-clients.component';
import { PotentialClientsStatusChangesComponent } from './components/main/employees/comercial/potential-clients-status-changes/potential-clients-status-changes.component';
import { PotentialClientDetailComponent } from './components/main/employees/comercial/potential-clients/potential-client-detail/potential-client-detail.component';
import { PotentialClientMeetingRecordComponent } from './components/main/employees/comercial/potential-clients/potential-client-detail/potential-client-meeting-record/potential-client-meeting-record.component';
import { CommercialDashboardComponent } from './components/main/employees/comercial/commercial-dashboard/commercial-dashboard.component';
import { WikiComponent } from './components/main/employees/wiki/wiki.component';
import { WikiNodeComponent } from './components/main/employees/wiki/wiki-node/wiki-node.component';
import { WikiArticleComponent } from './components/main/employees/wiki/wiki-article/wiki-article.component';
import { ProjectMeetingRecordsComponent } from './components/main/shared/projects/project-detail/project-meeting-records/project-meeting-records.component';
import { ProjectMeetingRecordCreditComponent } from './components/main/shared/projects/project-detail/project-meeting-records/project-meeting-record-credit/project-meeting-record-credit.component';
import { ProjectManagerGuard } from './guards/roles/department-categories/project-manager.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { ProjectApplicationsComponent } from './components/main/shared/projects/project-detail/project-applications/project-applications.component';
import { ProjectApplicationCreditComponent } from './components/main/shared/projects/project-detail/project-applications/project-application-credit/project-application-credit.component';
import { HolidaysComponent } from './components/main/employees/rrhh/holidays/holidays.component';
import { ProjectGuardianScheduledWeeksComponent } from './components/main/shared/projects/project-detail/project-guardian-scheduled-weeks/project-guardian-scheduled-weeks.component';
import { ProjectTeamsComponent } from './components/main/shared/projects/project-detail/project-teams/project-teams.component';
import { MyCareerPlanComponent } from './components/main/employees/my-zone/my-career-plan/my-career-plan.component';
import { ComputersComponent } from './components/main/employees/rrhh/computers/computers.component';
import { BisualSettingInitializedGuard } from './guards/bisual-setting-initialized.guard';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: 'login', component: LoginComponent, data: { title: "Login" } },
      { path: 'reset-password', component: RestorePasswordComponent, data: { title: "Restablecer contraseña" } },
      { path: '**', pathMatch: 'full', redirectTo: 'login' }
    ]
  },
  {
    path: '',
    canActivate: [ LoggedGuard ],
    component: MainComponent,
    children: [
      {
        path: 'employees',
        canActivate: [ EmployeeGuard, BisualSettingInitializedGuard ],
        children: [
          { path: 'dashboard', component: DashboardComponent, data: { title: "Dashboard" } },
          { path: 'my-profile', component: UsersDetailComponent, data: { title: "Mi perfil" }, canActivate: [ RegexServiceInitiatedGuard ] },
          {
            path: 'my-zone',
            children: [
              { path: 'resume', component: WorkingTimesResumeComponent, data: { title: "Resumen de trabajo" } },
              { path: 'history', component: WorkingTimesHistoryComponent, data: { title: "Historial de trabajo" } },
              { path: 'clock-registers', component: ClockRegistersComponent, data: { title: "Fichar y registro horario" } },
              { path: 'career-plan', component: MyCareerPlanComponent, data: { title: "Mi Plan de Carrer" } },
              { path: 'absences', component: MyAbsencesComponent, data: { title: "Mis vacaciones" } },
              { path: 'payrolls', component: MyPayrollsComponent, data: { title: "Mis nóminas" } },
              { path: 'calendar', component: WorkingTimesCalendarComponent, data: { title: "Mi calendario" } },

              { path: '**', pathMatch: 'full', redirectTo: 'resume' }
            ]
          },
          {
            path: 'support',
            component: SupportComponent,
            canActivate: [ DepartmentsInitiatedGuard, SupportGuard ],
            data: { title: "Soporte" },
            children: [
              { path: 'tickets', component: TicketsComponent, data: { title: 'Tickets' } },

              { path: '**', pathMatch: 'full', redirectTo: 'tickets' }
            ],
          },
          {
            path: 'development',
            component: ProjectManagementComponent,
            canActivate: [ DepartmentsInitiatedGuard, ProductionTeamGuard ],
            children: [
              { path: 'projects', component: ProjectsComponent, data: { title: "Proyectos" } },
              {
                path: 'projects/:project_id/phases/:phase_id',
                component: ProjectPhasesDetailComponent,
                children: [
                  { path: 'backlog', component: ProjectPhaseBacklogComponent, data: { title: "Backlog de proyecto" } },
                  { path: 'boards/:board_id', component: ProjectPhaseBoardsComponent, data: { title: "Boards del proyecto" } },
                  { path: '**', pathMatch: 'full', redirectTo: 'backlog' }
                ]
              },
              {
                path: 'projects/:id',
                component: ProjectDetailComponent,
                data: { title: 'Proyecto' },
                children: [
                  { path: 'resume', component: ProjectResumeComponent, data: { title: "Resumen de proyecto" } },
                  { path: 'phases', component: ProjectPhasesComponent, data: { title: "Fases del proyecto" } },
                  {
                    path: 'applications',
                    children: [
                      { path: '', component: ProjectApplicationsComponent, data: { title: "Aplicaciones del proyecto" } },
                      { path: 'create', component: ProjectApplicationCreditComponent, data: { title: "Crear aplicación" } },
                      { path: ':app_id', component: ProjectApplicationCreditComponent, data: { title: "Detalle de aplicación" } },
                    ]
                  },
                  { path: 'meeting-records',
                    children: [
                      { path: '', component: ProjectMeetingRecordsComponent, data: { title: "Actas de reunión" } },
                      { path: 'create', component: ProjectMeetingRecordCreditComponent, data: { title: "Crear acta de reunión" }, canDeactivate: [PendingChangesGuard] },
                      { path: ':meeting_id', component: ProjectMeetingRecordCreditComponent, data: { title: "Acta de reunión" }, canDeactivate: [PendingChangesGuard] },
                    ]
                  },
                  { path: 'guardian-scheduled-weeks',
                    children: [
                      { path: '', component: ProjectGuardianScheduledWeeksComponent, data: { title: "Calendario de Guardias" } },
                    ]
                  },
                  { path: 'project-teams',
                    children: [
                      { path: '', component: ProjectTeamsComponent, data: { title: "Equipos de proyecto" } },
                    ]
                  },
                  { path: '**', pathMatch: 'full', redirectTo: 'phases' }
                ],
              },
              {
                path: 'services',
                component: ServicesComponent,
                canActivate: [ RegexServiceInitiatedGuard ],
                children: [
                  { path: 'hostings', component: HostingsComponent, data: { title: "Hostings" } },
                  { path: 'domains', component: DomainsComponent, data: { title: "Dominios" } },
                  { path: 'business-mails', component: BusinessMailsComponent, data: { title: "Correos corporativos" } },
                  // xapusa
                  { path: 'hostings/:id', component: HostingDetailComponent },
                  { path: 'hostings/:id/:work', component: HostingDetailComponent },
                  { path: 'domains/:id', component: DomainDetailComponent },
                  { path: 'domains/:id/:work', component: DomainDetailComponent },
                  { path: 'business-mails/:id', component: BusinessMailDetailComponent },
                  { path: 'business-mails/:id/:work', component: BusinessMailDetailComponent },

                  { path: '**', pathMatch: 'full', redirectTo: 'hostings' }
                ]
              },
              { path: 'roadmap', component: PlanningRoadmapComponent, canActivate: [RrhhGuard], data: { title: "Roadmap" } },
              { path: 'assignment', component: ProjectAssignmentComponent,data: { title: "Asignación de proyectos" } },

              { path: '**', pathMatch: 'full', redirectTo: 'projects' }
            ],
          },
          {
            path: 'marketing',
            component: MarketingComponent,
            canActivate: [ DepartmentsInitiatedGuard, MarketingGuard ],
            children: [
              { path: 'blogposts', component: BlogpostsComponent, data: { title: "Blog" } },

              { path: '**', pathMatch: 'full', redirectTo: 'blogposts' }
            ],
          },
          {
            path: 'rrhh',
            component: RrhhComponent,
            canActivate: [ DepartmentsInitiatedGuard, RrhhGuard ],
            children: [
              { path: 'workers-planning', component: WorkersPlanningComponent, data: { title: 'Planificación de plantilla' } },
              {
                path: 'absences-management',
                component: AbsencesManagementComponent,
                children: [
                  { path: 'vacations', component: VacationsComponent, data: { title: "Gestión de vacaciones" } },
                  { path: 'vacation-requests', component: VacationRequestsComponent, data: { title: "Peticiones de vacaciones" } },
                  { path: 'manage-vacation-request/:id', component: ManageVacationRequestComponent, data: { title: "Administrar peticiones de vacaciones" } },
                  { path: 'medical-sick-leaves', component: MedicalSickLeavesComponent, data: { title: "Bajas médicas" } },
                ]
              },
              {
                path: 'clock-registers',
                component: ClockRegistersManagementComponent,
                children: [
                  { path: 'clock-register-review-requests', component: ClockRegisterReviewRequestsComponent, data: { title: "Solicitudes de cambio en el control horario" } },
                  { path: 'clock-register-resume', component: ClockRegisterResumeComponent, data: { title: "Resumen del control horario" } },
                ]
              },
              { path: 'payrolls', component: PayrollsComponent, data: { title: 'Nóminas' } },
              { path: 'workers', component: WorkersComponent, data: { title: 'Trabajadores' }, canActivate: [ RegexServiceInitiatedGuard ]},
              { path: 'workers/:id', component: UsersDetailComponent, data: { title: 'Datos Trabajadores' }, canActivate: [ RegexServiceInitiatedGuard ]},
              { path: 'departments', component: DepartmentsComponent, data: { title: 'Departamentos' } },
              { path: 'departments/:id', component: DepartmentDetailComponent, data: { title: 'Departamento' } },
              { path: 'departments/:department_id/department-categories/:department_category_id', component: DepartmentCategoryDetailComponent , data: {title: 'Categoria'}},
              { path: 'holidays', component: HolidaysComponent, data: { title: 'Festividades' } },
              { path: 'computers', component: ComputersComponent, data: { title: 'Ordenadores de trabajo' } },

              { path: '**', pathMatch: 'full', redirectTo: 'workers' }
            ],
          },
          {
            path: 'comercial',
            component: ComercialComponent,
            canActivate: [ DepartmentsInitiatedGuard, ComercialGuard ],
            children: [
              { path: 'dashboard', component: CommercialDashboardComponent, canActivate: [ DirectionGuard ], data: { title: 'Dashboard comercial' } },
              { path: 'potential-clients', component: PotentialClientsComponent, data: { title: 'Clientes potenciales' } },
              { path: 'potential-clients/:id', component: PotentialClientDetailComponent, data: { title: 'Cliente potencial' } },
              { path: 'potential-clients/:id/meeting-records', component: PotentialClientMeetingRecordComponent, data: { title: 'Crear acta de reunión' } },
              { path: 'potential-clients/:id/meeting-records/:meeting_id', component: PotentialClientMeetingRecordComponent, data: { title: 'Acta de reunión' } },
              { path: 'potential-clients-status-changes', component: PotentialClientsStatusChangesComponent, data: { title: 'Acciones comerciales' } }
            ]
          },
          {
            path: 'direction',
            component: DirectionComponent,
            canActivate: [ DepartmentsInitiatedGuard, DirectionGuard ],
            children: [
              {
                path: 'billing',
                component: BillingComponent,
                children: [
                  { path: 'resume', component: BillingResumeComponent, data: { title: "Resumen de facturación" } },
                  { path: 'orders', component: BillingOrdersComponent, data: { title: "Facturación - Ingresos" } },
                  { path: 'provider-orders', component: BillingProviderOrdersComponent, data: { title: "Facturación - Gastos" } },
                  { path: 'contasimple', component: BillingContasimpleDashboardComponent, data: { title: "Facturación - Contasimple" } },
                  { path: 'alerts', component: BillingAlertsComponent, data: { title: "Facturación - Alertas" } },
                  { path: 'planning', component: BillingPlanningComponent, data: { title: "Facturación - Planificación" } },
                  { path: '**', pathMatch: 'full', redirectTo: 'resume' }
                ]
              },
              {
                path: 'users-management',
                canActivate: [ RegexServiceInitiatedGuard ],
                component: UsersManagementComponent,
                children: [
                  { path: 'users', component: UsersComponent, data: { title: "Usuarios" } },
                  { path: 'users/:id', component: UsersDetailComponent, data: { title: "Usuario" } },
                  { path: 'companies', component: CompaniesComponent, data: { title: "Clientes" } },
                  { path: 'companies/:id', component: CompanyDetailComponent, data: { title: "Cliente" } },
                  { path: 'providers', component: ProvidersComponent, data: { title: "Proveedores" } },
                  { path: 'providers/:id', component: ProviderDetailComponent, data: { title: "Proveedor" } },
                  { path: '**', pathMatch: 'full', redirectTo: 'users' }
                ]
              },
              { path: 'configuration', component: ConfigurationComponent, data: { title: "Configuración" } },
              { path: 'legal', component: LegalDocumentationComponent, data: { title: "Documentación legal" } },
              { path: 'test', component: TestComponent, data: { title: "Test" } },

              { path: '**', pathMatch: 'full', redirectTo: 'my-zone' }
            ]
          },
          {
            path: 'wiki',
            children: [
              { path: '', component: WikiComponent, data: { title: "Wiki" }},
              { path: ':id', component: WikiNodeComponent, data: { title: "Wiki" }},
              { path: ':id/article/:article_id', component: WikiArticleComponent, data: { title: "Wiki" }},
              { path: ':node_id/github-doc/:github_file_name', component: WikiArticleComponent, data: { title: "Wiki - Github" }},
            ]
          }
        ]
      },
      {
        path: 'clients',
        canActivate: [ ClientsGuard, BisualSettingInitializedGuard ],
        children: [
          { path: 'projects', component: ProjectsComponent, data: { title: "Projects" } },
          { path: 'my-profile', component: UsersDetailComponent, data: { title: "Mi perfil" }, canActivate: [ RegexServiceInitiatedGuard ] },
          {
            path: 'projects/:project_id',
            component: ProjectDetailComponent,
            data: { title: 'Proyecto' },
            children: [
              { path: 'phases', component: ProjectPhasesComponent, data: { title: "Fases del proyecto" } },
              {
                path: 'phases/:phase_id',
                component: ProjectPhasesDetailComponent,
                data: { title: "Fases del proyecto" },
                children: [
                  { path: 'backlog', component: ProjectPhaseBacklogComponent, data: { title: "Backlog de proyecto" } },
                  { path: 'boards/:board_id', component: ProjectPhaseBoardsComponent, data: { title: "Boards del proyecto" } },
                  { path: '**', pathMatch: 'full', redirectTo: 'backlog' }
                ]
              },
              { path: '**', pathMatch: 'full', redirectTo: 'phases' }
        ],
          },
        ]
      },
    ]
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
