import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

declare global {
  interface Window {
    process: any; // Añadir process en la interfaz de Window
    electron: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  private ipcRenderer:any;

  constructor(private router:Router) {
    if(this.isElectronApp()) {
      this.ipcRenderer = window.electron.ipcRenderer;
      this.listenForExternalNavigationEvents();
    }
  }

  isElectronApp(): boolean {
    return !!(window && window.process && (window.process as any).type);
  }

  isMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any)['opera'];
    // Expresión regular para detectar dispositivos móviles
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }

  isDesktop(): boolean {
    return !this.isMobile();
  }

  getBaseHref(): string {
    return this.isElectronApp() ? './' : '/';
  }

  enableDoNotDisturbIfAvailable() {
    if(this.isElectronApp()) {
      this.ipcRenderer.send('enable-do-not-disturb');
    }
  }

  disableDoNotDisturbIfAvailable() {
    if(this.isElectronApp()) {
      this.ipcRenderer.send('disable-do-not-disturb');
    }
  }

  requestAccessibilityPermissions() { // necessari per activar i desactivar do not disturb
    if(this.isElectronApp()) {
      this.ipcRenderer.send('request-accessibility-permission');
    }
  }

  quitAndInstallNewVersion(filename:string) {
    if(this.isElectronApp()) {
      this.ipcRenderer.send('quit-and-install-new-version', filename);
    }
  }

  private listenForExternalNavigationEvents() {
    if(this.isElectronApp()) {
      window.electron.onNavigate((route: string) => {
        if(confirm("¿Estás seguro que quieres navegar a la página " + route + "?")) {
          this.router.navigateByUrl(route);
        }
      });
    }
  }
}
