import { AfterViewInit, Component, ElementRef, Inject, Injectable, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CalendarEvent, CalendarEventTimesChangedEvent, CalendarEventTitleFormatter, CalendarView, CalendarWeekViewBeforeRenderEvent } from 'angular-calendar';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, Subject } from 'rxjs';
import { WeeklyWorkingTimeBudgetEmployeeSerialized } from 'src/app/models/weekly_working_time_budget_employee.model';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WeekViewHourSegment } from 'calendar-utils';
import { addDays, addMinutes, endOfWeek, parseISO } from 'date-fns';
import { finalize, takeUntil } from 'rxjs/operators';
import { WorkingTimeCategory } from 'src/app/models/working_time_category.model';
import { EventColor } from 'calendar-utils';
import { EmployeeCalendarRegisterWithRelationsSerialized } from 'src/app/models/employee_calendar_register.model';
import { PermissionsService } from 'src/app/services/permissions.service';
import { User } from 'src/app/models/user.model';
import { UserService } from 'src/app/services/user.service';
import { CustomEventTitleFormatter } from 'src/app/components/main/shared/modules/custom-event-title-formatter.provider';
import { CalendarEventDetailDialogComponent } from './calendar-event-detail-dialog/calendar-event-detail-dialog.component';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-calendar-assignment-dialog',
  templateUrl: './calendar-assignment-dialog.component.html',
  styleUrls: ['./calendar-assignment-dialog.component.css'],
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
  ]
})
export class CalendarAssignmentDialogComponent implements OnInit, AfterViewInit {

  from:Date;
  to:Date;
  me: User = null as any;

  userOptions:{id:number; name:string; surnames:string;}[] = [];

  user_selected_index:number = null as any;

  avail_key_selected:string = null as any;
  availability:{[key:string]:Avail} = {};

  total_hours_assigned:number = 0;
  total_hours_created:number = 0;

  active_contract:WorkingContract;

  changesAvailableToSave:boolean = false;

  absences:("holiday"|"vacation"|"other"|null)[];

  private deletable_ids:number[] = [];

  private active_projects:{project_id:number,project_name:string,department_category_id:number,slug:string}[] = [];

  segmentation_options = [
    // {
    //   name: 'Horas enteras',
    //   value: 1
    // },
    {
      name: 'Detalle',
      value: 50
    },
    {
      name: 'Medio',
      value: 25
    },
    {
      name: 'Compacto',
      value: 10
    }
  ]
  hour_segmented_height = 50;
  hour_segments = 6;

  // Calendar
  @ViewChild('scrollContainer') scrollContainer: ElementRef<HTMLElement>;
  events: CalendarEventCustomized[] = [];
  refresh = new Subject<void>();
  dragToCreateActive:boolean = false;
  weekStartsOn:0|1|2|3|4|5|6 = 1;

  constructor(public dialogRef: MatDialogRef<CalendarAssignmentDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: {from:Date, to:Date, user_id:number|undefined},
              private api:ApiService,
              private utils:UtilsService,
              private userService: UserService,
              private dialog: MatDialog,
              private employee:EmployeeService,
              public permissions:PermissionsService,
              private elementRef: ElementRef) {
                this.from = this.data.from;
                this.to = this.data.to;
                this.initAbsencesAttribute();
              }

  ngOnInit(): void {
    this.me = this.userService.getCurrentUser();
    this.initActiveProjects();
    this.fetchEmployees();
  }

  changeHourSegmentedHeight(event:any) {
    this.hour_segmented_height = event;
    this.recalculateEventHeight();
    setTimeout(() => {
      this.refreshEvents();
      this.scrollToStartTime();
    }, 100);
  }

  ngAfterViewInit(): void {
    this.scrollToStartTime();
  }

  save() {
    if(confirm("¿Estás seguro que quieres guardar este calendario?")) {
      let body:any = {
        create_or_update: [],
        delete_ids: this.deletable_ids
      };

      let processed_events:CalendarEventCustomized[] = [];
      for(let event of this.events) {
        let to_date = event.end!=null ? event.end : null;
        if(to_date==null) {
          to_date = moment(event.start).add(1/this.hour_segments, 'hours').toDate();
        }
        if(event.meta.changed===true) {
          let item:any = event.meta.ecr;
          item.from_date = event.start;
          item.to_date = to_date;
          item.to_date = to_date;
          item.user_id = this.userOptions[this.user_selected_index].id;
          body.create_or_update.push(item);
          processed_events.push(event);
        }
      }

      this.api.saveBulkEmployeeCalendarRegisters(body).subscribe(
        data => {
          this.deletable_ids = [];
          processed_events.forEach(e => {
            e.meta.changed = false; // marquem com a no canviat
          });
          this.changesAvailableToSave = false;

          // refresquem els today tasks si hem canviat els meus horaris d'aquesta setmana
          if(moment(this.from).isSame(moment(), 'week') && this.userOptions[this.user_selected_index].id == this.me.id) {
            this.employee.reloadTodaysTasks(this.me);
          }
        }
      );
    }
  }

  close(): void {
    this.dialogRef.close();
  }

  selectUser(index:number) {
    if(!this.changesAvailableToSave || confirm("Hay cambios pendientes de guardar. ¿Estás seguro que quieres cambiar de usuario y perderlos?")) {
      this.user_selected_index = index;
      this.changesAvailableToSave = false;
      this.events = [];
      this.fetchUserWeeklyAvailability();
      this.fecthRemoteWorkingDays();
    }
  }

  fecthRemoteWorkingDays() {

    this.api.getEmployeeRemoteWork(this.userOptions[this.user_selected_index].id.toString(), this.from, this.to).subscribe(
      data => {
        data.forEach((item: any) => {
          // add date as event all day to calendar
          this.events.push({
            start: new Date(item),
            title: '🏠',
            color: {
              primary: 'transparent',
              secondary: 'transparent'
            },
            allDay: true,
            meta: {
              tmpEvent: false,
              changed: false
            }
          } as CalendarEventCustomized);
          this.refreshEvents();
        });
      }
    );
  }

  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    const oldMinutesRange = moment(event.end).diff(moment(event.start), 'minutes');
    const newMinutesRange = moment(newEnd).diff(moment(newStart), 'minutes');
    const oldHoursRange = moment(event.end).diff(moment(event.start), 'hours', true);
    const newHoursRange = moment(newEnd).diff(moment(newStart), 'hours', true);
    event.start = newStart;
    event.end = newEnd;
    event.meta.changed = true; // marquem com a canviat
    this.refresh.next();
    if(oldMinutesRange != newMinutesRange) { // si nomes arrastrem un slot de 9-10 a 10-11... no cal restar

      const avail_index = this.generateAvailKeyFromEcr(event.meta.ecr);
      if(avail_index != null) {
        const diff = oldHoursRange - newHoursRange;
        this.availability[avail_index].hours_assigned -= diff;

        // Sumem hores globals
        this.total_hours_created -= diff;
      }
    }

    this.changesAvailableToSave = true;
  }

  beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEvent) {
    if(this.active_contract != null) {
      renderEvent.hourColumns.forEach((hourColumn) => {
        const dayIndex:number = hourColumn.date.getDay(); // 0: sunday, 1: monday
        const { morning_entry_time, morning_exit_time, afternoon_entry_time, afternoon_exit_time } = this.getDayRangesByIndex(dayIndex);
        if((morning_entry_time!=null && morning_exit_time!=null) || (afternoon_entry_time != null && afternoon_exit_time != null)) {
          hourColumn.hours.forEach((hour) => {
            hour.segments.forEach((segment) => {
              const hours = segment.date.getHours();
              const minutes = segment.date.getMinutes();
              const hours_ponderated = hours + (minutes===0 ? 0 : (minutes/60));

              const absence = this.absences[dayIndex];

              if(absence!=null) {
                segment.cssClass = absence;
              }
              else if (
                (hours_ponderated >= morning_entry_time && hours_ponderated < morning_exit_time) ||
                (hours_ponderated >= afternoon_entry_time && hours_ponderated < afternoon_exit_time)
              ) {
                segment.cssClass = 'contractable';
              }
            });
          });
        }
      });
    }
  }

  startDragToCreate(
    segment: WeekViewHourSegment,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement
  ) {
    if(this.avail_key_selected!=null) {

      const self = this;
      const currentHoursBeforeStarting = this.availability[this.avail_key_selected].hours_assigned;
      const currentHoursBeforeStartingGlobal = this.total_hours_created;

      const avail:Avail = this.availability[this.avail_key_selected]; // Mirar per aqui !!!!

      var curentProject = this.active_projects.find(p => p.project_id == avail.name?.project_id)
      if(curentProject?.slug == 'tech-lead' || this.userOptions[this.user_selected_index]?.id == this.me.id || this.permissions.validateDepartment(['direction', 'rrhh'])){ // todo check if user needs to be project manager or other roles
        const dragToSelectEvent: CalendarEventCustomized = this.createCalendarEventFromAvail(avail, segment);

        const avail_index = this.generateAvailKeyFromEcr(dragToSelectEvent.meta.ecr);
        if(avail_index != null) {
          this.availability[avail_index].hours_assigned += 60/60/this.hour_segments;

          // Sumem hores globals
          this.total_hours_created += 60/60/this.hour_segments;
        }

        this.events = [...this.events, dragToSelectEvent];

        this.changesAvailableToSave = true;

        const segmentPosition = segmentElement.getBoundingClientRect();
        this.dragToCreateActive = true;
        const endOfView = endOfWeek(this.from, {
          weekStartsOn: this.weekStartsOn,
        });
        fromEvent(document, 'mousemove')
        .pipe(finalize(() => {
            delete dragToSelectEvent.meta.tmpEvent;
            this.dragToCreateActive = false;
            this.refreshEvents();
          }),
          takeUntil(fromEvent(document, 'mouseup'))
        ).subscribe((mouseMoveEvent: any) => {
          const minutesDiff = ceilToNearest(mouseMoveEvent.clientY - segmentPosition.top, this.hour_segmented_height);

          const daysDiff =
            floorToNearest(
              mouseMoveEvent.clientX - segmentPosition.left,
              segmentPosition.width
            ) / segmentPosition.width;

          const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
          if (newEnd > segment.date && newEnd < endOfView) {
            // dragToSelectEvent.end = newEnd;
          }

          // Calcular hores a sumar al resum
          const newHoursRange = moment(dragToSelectEvent.end).diff(moment(dragToSelectEvent.start), 'hours', true);
          this.availability[this.avail_key_selected].hours_assigned = currentHoursBeforeStarting + newHoursRange;

          // Sumem hores globals
          this.total_hours_created = currentHoursBeforeStartingGlobal + newHoursRange;
          this.refreshEvents();
        } );
      }
    }
  }

  private fetchEmployees() {
    if(this.data.user_id != undefined) {
      this.userOptions = [ this.userService.getCurrentUser() ];
      this.selectUser(0);
    }
    else {
      this.api.getWeeklyWorkingTimeBudgetEmployeeEmployees(this.from, this.to).subscribe(
        data => {
          const me = data.find(u => u.id === this.userService.getCurrentUser().id);
          if(me!=null) {
            data.splice(data.indexOf(me), 1);
            data.unshift(me);
          }
          this.userOptions = data;
          if(data.length > 0) {
            this.selectUser(0);
          }
        }
      );
    }
  }

  private fetchUserWeeklyAvailability() {
    const params:any = {
      employee_id: this.userOptions[this.user_selected_index].id,
      start_week_day: this.utils.dateToStringYYYYMMDD(this.from),
      end_week_day: this.utils.dateToStringYYYYMMDD(this.to)
    };

    this.api.getWeeklyWorkingTimeBudgetEmployeesSerialized(params).subscribe(
      data => {
        this.initAvailability(data);
        this.fetchUserContract();
      }
    );
  }

  private fetchUserContract() {
    this.active_contract = null as any;

    const params:any = {
      day: this.utils.dateToStringYYYYMMDD(this.from),
      with: 'current_working_contract_variable_condition'
    };

    this.api.getActiveWorkingContract(this.userOptions[this.user_selected_index].id, params).subscribe(
      data => {
        this.active_contract = data;

        this.fetchUserCalendarRegisters();
      }
    );
  }

  private initAvailability(data:WeeklyWorkingTimeBudgetEmployeeSerialized[]) {
    this.avail_key_selected = null as any;
    this.total_hours_assigned = 0;
    this.availability = {};
    data.forEach(wwtbe => {
      this.total_hours_assigned += wwtbe.hours;
      const key:string = this.generateAvailKeyFromWwtbe(wwtbe);
      this.availability[key] = {
        name: this.getNameFromWwtbe(wwtbe),
        color: this.getColorFromWwtbe(wwtbe),
        wwtbe_id: wwtbe.id,
        wwtbe_relatable_id: wwtbe.relatable_id,
        wwtbe_relatable_type: wwtbe.relatable_type,
        hours_to_do: wwtbe.hours,
        hours_assigned: 0,
      };
    });
    this.avail_key_selected = Object.keys(this.availability)[0]; // seleccionem la primera key
  }

  private initActiveProjects() {
    this.active_projects = this.employee.getActiveProjects();
  }

  private fetchUserCalendarRegisters() {
    this.total_hours_created = 0;

    this.api.getEmployeeCalendarRegistersForUserSerialized(this.userOptions[this.user_selected_index].id.toString(), this.from, this.to).subscribe(
      data => {
        let events = data.map(ecr => {
          const canManageEvent:boolean =  this.userOptions[this.user_selected_index].id == ecr.user_id ||
                                          this.permissions.validateDepartment(['direction', 'rrhh']) ||
                                          (ecr.project_id != null && (
                                            this.permissions.isWorkerWithProjectRole(ecr.project_id, 'tech-lead') ||
                                            this.permissions.isWorkerWithProjectRole(ecr.project_id, 'product-manager')
                                          ));


          // Sumem al global
          this.total_hours_created += Math.abs(moment(ecr.to_date).diff(moment(ecr.from_date), 'hours', true));

          // Sumem al resum
          const avail_index = this.generateAvailKeyFromEcr(ecr);
          if(this.availability[avail_index]!=null) {
            this.availability[avail_index].hours_assigned += moment(ecr.to_date).diff(moment(ecr.from_date), 'hours', true);
          }

          // ECR Actions
          const actions:any[] = [];
          if(canManageEvent) {
            actions.push({
              label: '✕',
              onClick: ({ event }: { event: CalendarEvent }): void => {
                this.onDelete(event);
              }
            });
          }

          if(ecr.call_link != null && ecr.call_link != "") {
            actions.push({
              label: '<img src="https://cdn-icons-png.flaticon.com/256/455/455691.png" alt="shared" width="12" style="margin-left: 4px;" />',
              onClick: ({ event }: { event: CalendarEvent }): void => {
                window.open(ecr.call_link, '_blank');
              }
            })
          }

          if(ecr.shared_users != null && ecr.shared_users.length) {
            actions.push({
              label: '<img src="https://gogeticons.com/frontend/web/icons/data/1/9/8/2/8/group_256.png" alt="shared" width="14" style="margin-left: 4px;" />',
              onClick: ({ event }: { event: CalendarEvent }): void => { }
            })
          }

          return {
            title: this.getEventWorkingCategoryTitle(ecr),
            subtitle: ecr.custom_title ?? ecr.ecr_wtc_name,
            project_phase_name: ecr.project_phase_name,
            color: this.getEventColorObj(this.getColorFromEcr(ecr)),
            start: ecr.from_date,
            end: ecr.to_date,
            resizable: { beforeStart: canManageEvent, afterEnd: canManageEvent },
            draggable: canManageEvent,
            actions: actions,
            height: this.calculateHeightOfEvent(this.calculateDifferenceInMinutes(new Date(ecr.from_date), new Date(ecr.to_date)) ?? 5),
            meta: {
              tmpEvent: false,
              changed: false,
              ecr: ecr,
            }
          } as CalendarEventCustomized;
        });

        this.events = [...this.events, ...events];

        this.fetchAbsences();
        this.cleanAvailsThatAreNotGoingToBeUsed();
      }
    );
  }

  private calculateDifferenceInMinutes(date1: Date, date2: Date): number {
    const differenceInMilliseconds = Math.abs(date2.getTime() - date1.getTime());
    const differenceInMinutes = Math.floor(differenceInMilliseconds / (1000 * 60));
    return differenceInMinutes;
  }

  private calculateHeightOfEvent(minutes: number): number {
    return (minutes*this.hour_segments/60) * this.hour_segmented_height;
  }

  private recalculateEventHeight() {
    this.events.forEach(event => {
      if (event.end!=null) {
        const h = this.calculateHeightOfEvent(this.calculateDifferenceInMinutes(event.start, event.end) ?? 5);
        event.height = h;
      }
    });
    this.refreshEvents();
  }

  checkPermissions(events:any,currentEvent:any = null){
    if(currentEvent != null){
      var selected_event = currentEvent?.meta?.ecr ?? currentEvent?.event?.meta?.ecr;
      if(selected_event){
        var avail_key = this.generateAvailKeyFromEcr(selected_event);
        if(avail_key != null){
          var avail = this.availability[avail_key];
          if(avail){
            if(avail.name.project_id ){
              var curentProject = this.active_projects.find(p => p.project_id == avail.name?.project_id)
              if(curentProject?.slug == 'tech-lead'){ // todo check if user needs to be project manager or other roles
                return true;
              }
            }
          }
        }
      }else{
      }
    }
    //Todo check if user has permissions on this event
    return this.userOptions[this.user_selected_index]?.id == this.me.id || this.permissions.validateDepartment(['direction', 'rrhh']) ?  true :  false;
  }

  private fetchAbsences() {
    this.api.getEmployeeAbsences(this.userOptions[this.user_selected_index].id, this.from, this.to).subscribe(
      data => {
        this.initAbsencesAttribute();

        // vacances
        data.employee_calendar_registers.forEach(ecr => {
          const index = moment(ecr.from_date).day();
          if(this.absences[index]==null) {
            let str:"other"|"vacation" = "other";
            if((ecr.working_category as WorkingTimeCategory).slug==="vacations") str = "vacation";
            this.absences[index] = str;
          }
        });

        // holidays
        data.holidays.forEach(h => {
          const index = moment(h.date).day();
          if(this.absences[index]==null) {
            this.absences[index] = "holiday";
          }
        });

        this.refreshEvents();
      }
    );
  }

  private getDayRangesByIndex(dayIndex:number) {
    switch(dayIndex) {
      case 0:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.sunday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.sunday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.sunday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.sunday_afternoon_exit_time
        };
      case 1:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.monday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.monday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.monday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.monday_afternoon_exit_time
        };
      case 2:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.tuesday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.tuesday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.tuesday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.tuesday_afternoon_exit_time
        };
      case 3:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.wednesday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.wednesday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.wednesday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.wednesday_afternoon_exit_time
        };
      case 4:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.thursday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.thursday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.thursday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.thursday_afternoon_exit_time
        };
      case 5:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.friday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.friday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.friday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.friday_afternoon_exit_time
        };
      case 6:
        return {
          morning_entry_time: this.active_contract.current_working_contract_variable_condition.saturday_morning_entry_time,
          morning_exit_time: this.active_contract.current_working_contract_variable_condition.saturday_morning_exit_time,
          afternoon_entry_time: this.active_contract.current_working_contract_variable_condition.saturday_afternoon_entry_time,
          afternoon_exit_time: this.active_contract.current_working_contract_variable_condition.saturday_afternoon_exit_time
        };
      default:
        return {
          morning_entry_time: 0,
          morning_exit_time: 0,
          afternoon_entry_time: 0,
          afternoon_exit_time: 0
        };
    }
  }

  private scrollToStartTime() {
    this.scrollContainer.nativeElement.scrollTop = 60 + 60 * 7.5; // 60 del header + 60 per hora
  }

  private getNameFromWwtbe(wwtbe:WeeklyWorkingTimeBudgetEmployeeSerialized) {
    return {
      title: wwtbe.project_title ?? wwtbe.wtc_name ?? "", // nom del projecte o del titol de la categoria (Nextlives i comercial)
      company: wwtbe.company_name,
      project_category: wwtbe.project_wtc_name, // sprint review
      project_phase_name: wwtbe.project_phase_name,
      project_id: wwtbe.project_id
    }
  }

  private getColorFromWwtbe(wwtbe:WeeklyWorkingTimeBudgetEmployeeSerialized) {
    return wwtbe.project_color ?? wwtbe.wtc_color as string;
  }

  private getEventColorObj(color:string): EventColor {
    return {
      primary: "transparent",
      secondary: color
    }
  }

  private getEventWorkingCategoryTitle(ecr:EmployeeCalendarRegisterWithRelationsSerialized) {
    return ecr.project_title ?? ecr.ecr_wtc_name ?? ecr.wtc_name;
  }

  private getColorFromEcr(ecr:EmployeeCalendarRegisterWithRelationsSerialized) {
    return ecr.project_color ?? ecr.ecr_wtc_color ?? ecr.wtc_color ?? '#fff';
  }

  private onDelete(event:CalendarEvent) {
    if(!this.checkPermissions(this.events,event)) return;
    this.events = this.events.filter((iEvent) => iEvent !== event);
    this.changesAvailableToSave = true;
    if(event.meta.ecr.id != null) {
      this.deletable_ids.push(event.meta.ecr.id);
    }

    const avail_index = this.generateAvailKeyFromEcr(event.meta.ecr);
    if(avail_index != null) {
      const diff = moment(event.end).diff(moment(event.start), 'hours', true);

      // eliminem del resum
      this.availability[avail_index].hours_assigned -= diff;

      // Sumem hores globals
      this.total_hours_created -= diff;
    }

    this.refreshEvents();

    this.cleanAvailsThatAreNotGoingToBeUsed();
  }

  private initAbsencesAttribute() {
    this.absences = [null,null,null,null,null,null,null];
  }

  private generateAvailKeyFromWwtbe(wwtbe:WeeklyWorkingTimeBudgetEmployeeSerialized) {
    if(wwtbe.relatable_type === "App\\Models\\WeeklyWorkingTimeBudgetDistributionDistributable") {
      return `wwtbdd_${wwtbe.relatable_id}`;
    }
    else {
      return `wtc_${wwtbe.relatable_id}`
    }
  }

  private generateAvailKeyFromEcr(ecr:EmployeeCalendarRegisterWithRelationsSerialized) {
    if(ecr.working_category_type === "App\\Models\\WeeklyWorkingTimeBudgetEmployee") {
      if(ecr.wwtbe_relatable_type==="App\\Models\\WeeklyWorkingTimeBudgetDistributionDistributable") {
        return `wwtbdd_${ecr.wwtbe_relatable_id}`;
      }
      else {
        return `wtc_${ecr.wwtbe_relatable_id}`
      }
    }
    else if (ecr.working_category_type === "App\\Models\\WorkingTimeCategory") {
      return `wtc_${ecr.working_category_id}`;
    }
    else {
      throw "It should never go here. ECR must always be from a WeeklyWorkingTimeBudgetEmployee in this screen.";
    }
  }

  private createCalendarEventFromAvail(avail:Avail, segment:WeekViewHourSegment): CalendarEventCustomized {
    return {
      id: this.events.length,
      color: this.getEventColorObj(this.availability[this.avail_key_selected].color),
      title: avail.name.title,
      subtitle: avail.name.project_category,
      project_phase_name: avail.name.project_phase_name,
      start: segment.date,
      end: 60/this.hour_segments > 1 ? addMinutes(segment.date, 60/this.hour_segments) : addMinutes(segment.date, 60),
      resizable: { beforeStart: true, afterEnd: true },
      draggable: true,
      actions: [
        {
          label: '✕',
          onClick: ({ event }: { event: CalendarEvent; }): void => {
            this.onDelete(event);
          },
        },
      ],
      height: null,
      meta: {
        tmpEvent: true,
        changed: true,
        ecr: {
          id: undefined as any,
          from_date: segment.date,
          to_date: null as any,
          working_category_id: avail.wwtbe_id,
          working_category_type: "App\\Models\\WeeklyWorkingTimeBudgetEmployee", // sempre serà això perquè mai farem vacances o coses aixi...
          wwtbe_relatable_type: avail.wwtbe_relatable_type,
          wwtbe_relatable_id: avail.wwtbe_relatable_id,
          user_id: this.userOptions[this.user_selected_index].id,
          custom_title: undefined,
          call_link: undefined,
          shared_users: []
        } as any,
      }
    };
  }

  eventClicked(event:any) {
    let ecr:EmployeeCalendarRegisterWithRelationsSerialized = event.meta.ecr;
    const canManageEvent:boolean =  this.userOptions[this.user_selected_index]?.id == this.me.id ||
                                    this.permissions.validateDepartment(['direction', 'rrhh']) ||
                                    (ecr != null && ecr.project_id != null && (
                                      this.permissions.isWorkerWithProjectRole(ecr.project_id, 'tech-lead') ||
                                      this.permissions.isWorkerWithProjectRole(ecr.project_id, 'product-manager')
                                    ));

    if (canManageEvent) {
      const dialogDetail = this.dialog.open(CalendarEventDetailDialogComponent, {
        data: {
          event: event,
        },
        width: '600px'
      });

      dialogDetail.afterClosed().subscribe(
        data => {
          if(data!=null) {
            const index = this.events.indexOf(event);
            this.events[index].meta.ecr.custom_title = data.custom_title;
            this.events[index].meta.ecr.call_link = data.call_link;
            this.events[index].meta.ecr.shared_users = data.shared_users;
            this.events[index].subtitle = data.custom_title ?? this.events[index].subtitle;
            const h = this.calculateHeightOfEvent(this.calculateDifferenceInMinutes(new Date(data.start), new Date(data.end)) ?? 5);
            this.events[index].height = h;

            this.eventTimesChanged({
              event: this.events[index],
              newStart: data.start,
              newEnd: data.end
            } as any);

            // delay 0,1s perquè es refresqui el component
            setTimeout(() => {
              this.refreshEvents();
            }, 100);
          }
        }
      );
    }
  }

  private cleanAvailsThatAreNotGoingToBeUsed() {
    for(let key of Object.keys(this.availability)) {
      if(this.availability[key].hours_assigned == 0 && this.availability[key].hours_to_do == 0) {
        delete this.availability[key];
      }
    }
  }

  private refreshEvents() {
    this.refresh.next();
    const eventContainers = this.elementRef.nativeElement.querySelectorAll('.cal-event-container');
    eventContainers.forEach((container:any) => {
      const childWithHeight = container.querySelector('[data-event-height]');
      if (childWithHeight) {
        const height = childWithHeight.getAttribute('data-event-height');
        container.style.height = height + 'px';
      }
    });
  }
}

interface Avail {
  name:{
    title:string;
    company:string|undefined;
    project_category:string|undefined;
    project_phase_name:string|undefined;
    project_id:number|undefined;
  };
  color:string;
  wwtbe_id:number;
  wwtbe_relatable_id:number;
  wwtbe_relatable_type:"App\\Models\\WorkingTimeCategory"|"App\\Models\\WeeklyWorkingTimeBudgetDistributionDistributable";
  hours_to_do:number;
  hours_assigned:number;
}

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

interface CalendarEventCustomized extends CalendarEvent {
  height: number|null;
  // totes les propietats de CalendarEvent
  subtitle: string|undefined,
  project_phase_name: string|undefined;
  meta: {
    tmpEvent?: boolean,
    changed: boolean,
    ecr: EmployeeCalendarRegisterWithRelationsSerialized,
  }
}
