import { DecimalPipe, NgIfContext } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { getYear } from 'date-fns';
import { Department } from 'src/app/models/department.model';
import { DepartmentCategory } from 'src/app/models/department_category.model';
import { EmployeeMonthWorkingCapacity } from 'src/app/models/employee_month_working_capacity.model';
import { Role } from 'src/app/models/role.model';
import { User } from 'src/app/models/user.model';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NonProductiveHoursPlanningDialogComponent } from './non-productive-hours-planning-dialog/non-productive-hours-planning-dialog.component';
import { VacationsAndHolidaysDialogComponent } from './vacations-and-holidays-dialog/vacations-and-holidays-dialog.component';

@Component({
  selector: 'app-workers-planning',
  templateUrl: './workers-planning.component.html',
  styleUrls: ['./workers-planning.component.css']
})
export class WorkersPlanningComponent implements OnInit {

  filterForm:FormGroup = null as any;
  private formPersistence:any;

  dataSource:MatTableDataSource<EmployeePlanningRow> = new MatTableDataSource();
  _displayedColumns:string[] = ["user", "category"];
  get displayedColumns():string[] {
    return this._displayedColumns.concat(this.monthControl.value);
  }

  months:{key:string, value:string}[] = [
    {key: "january", value: "Enero"},
    {key: "february", value: "Febrero" },
    {key: "march", value: "Marzo" },
    {key: "april", value: "Abril" },
    {key: "may", value: "Mayo" },
    {key: "june", value: "Junio" },
    {key: "july", value: "Julio" },
    {key: "august", value: "Agosto" },
    {key: "september", value: "Septiembre" },
    {key: "october", value: "Octubre" },
    {key: "november", value: "Noviembre" },
    {key: "december", value: "Diciembre" }
  ];
  monthControl:FormControl = new FormControl(this.months.map(v => v.key));
  private monthsInterval:any = null as any;

  private _totals:{total_hours:number, projects:number; projects_budgetable:number; plantilla: number; plantilla_vacations:number}[] = [
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 },
    { total_hours: 0, projects: 0, projects_budgetable: 0, plantilla: 0, plantilla_vacations: 0 }
  ];
  totals:{total_hours:number; projects:number; projects_budgetable:number; plantilla: number; plantilla_vacations:number}[] = this.utils.cloneObj(this._totals);

  showUsers:{[key:number]:boolean} = {};

  get changesAvailableToSave(): boolean {
    for(let item of this.dataSource.data) {
      for(let j of item.employee_month_working_capacity_value) {
        if(j!=null && j.changed) {
          return true;
        }
      }
    }
    return false;
  }

  monthly_times_occupied:number[] = [];

  constructor(private fb:FormBuilder,
              private activatedRoute:ActivatedRoute,
              private router:Router,
              private utils:UtilsService,
              private api:ApiService,
              private permissions:PermissionsService,
              private snack:MatSnackBar,
              private dialog:MatDialog,
              private decimalPipe: DecimalPipe) {

    this.filterForm = this.fb.group({
      year:[(this.activatedRoute.snapshot.queryParamMap.get('year')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('year') as any) : new Date().getFullYear()), Validators.required],
    });

  }

  ngOnInit(): void {
    this.listenQueryParameters();
    this.initFilterFormListener();
    this.listenMonthFormControl();
  }

  changeYear(sumYears:number) {
    this.filterForm.controls['year'].setValue(this.filterForm.value['year']+sumYears);
  }

  getMonthNameByIndex(i:number) {
    switch(i) {
      case 0:
        return 'january';
        break;
        case 1:
        return 'february';
        break;
        case 2:
        return 'march';
        break;
        case 3:
        return 'april';
        break;
        case 4:
        return 'may';
        break;
        case 5:
        return 'june';
        break;
        case 6:
        return 'july';
        break;
        case 7:
        return 'august';
        break;
        case 8:
        return 'september';
        break;
        case 9:
        return 'october';
        break;
        case 10:
        return 'november';
        break;
        case 11:
        return 'december';
        break;
        default:
          return '';
          break;
    }
  }

  getCategoriesByUser(row:EmployeePlanningRow) {
    let res:string[] = [];
    for(let item of this.dataSource.data) {
      if(item.user_id==row.user_id) {
        res.push(item.employee_month_working_capacity_field);
      }
    }
    return res;
  }

  isFirstUserRow(row:EmployeePlanningRow) {
    let res:boolean = true;
    for(let r of this.dataSource.data) { // quan es trobi l'usuari, dictarà sentència
      if(row.user_id==r.user_id && r.id!=row.id) return false;
      else if(row.user_id==r.user_id && r.id==row.id) return true;
    }
    return res;
  }

  isLastUserRow(row:EmployeePlanningRow): boolean {
    let foundFirst:boolean = false;

    const itemsFromMyUser:EmployeePlanningRow[] = this.dataSource.data.filter(item => item.user_id==row.user_id);

    if(itemsFromMyUser.length===0) return false;
    else return itemsFromMyUser[itemsFromMyUser.length-1].id==row.id;
  }

  isFooterRow(id:string, td:HTMLElement) {
    return td.parentElement!=null && td.parentElement.id===id;
  }

  changeInputEvt(row:EmployeePlanningRow, index:number, evt:any) {
    const newVal = evt.target.value;
    if(newVal != null) {
      this.changeInput(row, index, newVal);
    }
  }

  isMonthModified(indexMonth:number, row_id:string|undefined) {
    let key:string = null as any;
    if(row_id===undefined) return false;
    else if(row_id==="horas-proyectos-row") {
      key = "projects";
    }
    else if(row_id==="coste-plantilla-row") {
      key = "plantilla";
    }
    else if(row_id==="cost-plantilla-vacaciones-row") {
      key = "plantilla_vacations";
    }
    else return false;

    for(let item of this.dataSource.data) {
      if(item.employee_month_working_capacity_value[indexMonth].changed) {
        return true;
      }
    }

    return false;
  }

  isFieldLessTimeThanNormal(row:EmployeePlanningRow, index:number, debug:boolean = false) {
    return row.employee_month_working_capacity_field=='total_availability.hours' &&
      row.emwc[index]!=null &&
      (row.emwc[index].total_availability.holidays.length > 0 || row.emwc[index].total_availability.vacations.length > 0);
  }

  getInputTooltip(row:EmployeePlanningRow, index:number): any {
    if(this.isFieldLessTimeThanNormal(row, index)) {
      const ta = row.emwc[index].total_availability;
      if(ta.holidays.length > 0 && ta.vacations.length > 0) {
        return "Disfruta de vacaciones y fiestas este mes.";
      }
      else if(ta.holidays.length > 0) {
        return "Disfruta de festividades este mes.";
      }
      else if(ta.vacations.length > 0) {
        return "Disfruta de vacaciones este mes.";
      }
      else return null;
    }
    else if(row.employee_month_working_capacity_field === 'non_productive_hours') {
      return "Haz DOBLE click para abrir planificador de horas de no desarrollo";
    }
    else return null;
  }

  onClickInput(row:any, index:number) {
    if(row.employee_month_working_capacity_field==="total_availability.hours") {
      // Obrirem un popup per veure festivitats i vacances
      const emwc = row.emwc[index];
      const ta = emwc.total_availability;
      const dialogRef = this.dialog.open(VacationsAndHolidaysDialogComponent, {
        width: '500px',
        data: {
          total_availability: ta
        }
      });
    }
  }

  onDblClickInput(row:any, index:number) {
    if(row.employee_month_working_capacity_field==="non_productive_hours") {
      // Abrir popup de planning non productive hours
      this.openNonProductiveHoursPlanningDialog(row, index);
    }
  }

  save() {
    if(confirm("¿Estás seguro que quieres guardar esta planificación?")) {
      let resTemp:any = {};
      for(let item of this.dataSource.data) {
        item.employee_month_working_capacity_value.forEach((item2,i) => {
          if(item2.changed) {
            const key = `${item.user_id}_${i+1}_${this.filterForm.value['year']}`;
            if(resTemp[key]==null) {
              resTemp[key] = {
                id: item.emwc!=null && item.emwc[i]!=null ? item.emwc[i].id : null,
                user_id: item.user_id,
                month: i+1,
                year: this.filterForm.value['year']
              };
            }

            resTemp[key][item.employee_month_working_capacity_field] = item2.val;
          }
        });
      }

      const res:any[] = Object.keys(resTemp).map(k => resTemp[k]);

      this.api.saveBulkEmployeeMonthWorkingCapacity(res).subscribe(
        data => {
          this.fetchPlanning();
        }
      );
    }
  }

  getHoursOccupiedTooltipByMonth(indexMonth:number) {
    return 'Horas presupuestables ocupadas: ' +
      this.decimalPipe.transform(this.monthly_times_occupied[indexMonth], '1.0-2') +
      ' horas. Horas de trabajadores ocupadas: ' +
      this.decimalPipe.transform(this.monthly_times_occupied[indexMonth], '1.0-2') +
      ' horas.';
  }

  getHoursOccupiedClassByMonth(indexMonth:number) {
    const ocupadas = this.monthly_times_occupied[indexMonth];
    const presupuestables = this.totals[indexMonth].projects_budgetable;
    if(ocupadas==null || presupuestables==null) return {};
    else {
      const ratio = ocupadas / presupuestables;

      return {
        danger: ratio > 1,
        warning: ratio >= 0.9 && ratio < 1,
        ok: ratio < 0.9
      };
    }
  }

  isMonthPosterior(indexMonth:any) {
    const year = this.filterForm.value['year'];
    const currentYear = new Date().getFullYear();
    if(year < currentYear) return false;
    else if(year > currentYear) return true;
    else {
      const currentMonth = new Date().getMonth();
      return (indexMonth+1) > currentMonth;
    }
  }

  copyMonth(indexMonth:number) {
    if(confirm("¿Estás seguro que quieres sobrescribir el mes actual y copiar el mes anterior a éste?")) {
      this.api.copyEmployeeMonthWorkingCapacityFromLastMonth(indexMonth+1, this.filterForm.value['year']).subscribe(
        data => {
          this.fetchPlanning();
        }
      );
    }
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          this.router.navigate([], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(params => {
      if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
        let params_temp = this.utils.cloneObj(params); //params es read_only
        Object.keys(params_temp).forEach(param_key => {
          if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
        });
        this.filterForm.patchValue(params_temp, { emitEvent: false });
      }
      this.formPersistence = params;

      this.fetchPlanning();
    })
  }

  private fetchPlanning() {
    const year = this.filterForm.value['year'];
    this.dataSource.data = [];
    this.monthly_times_occupied = [];
    this.api.getEmployeesPlanningByYear(year).subscribe(
      data => {
        this.afterFetchingPlanning(data);
      }
    );

    this.api.getDevelopmentMonthlyTimeOccupiedByYear(year).subscribe(
      data => {
        this.monthly_times_occupied = data;
      }
    );
  }

  private afterFetchingPlanning(users:User[]) {
    const year = this.filterForm.value['year'];
    this.dataSource.data = [];

    const source = this.usersToRows(users);
    this.dataSource.data = source;

    // Actualitzem showUsers
    this.showUsers = {};
    users.forEach(u => {
      this.showUsers[u.id] = false;
    });
  }

  private usersToRows(users:User[]):EmployeePlanningRow[] {
    let resTemp:any = {};
    let i:number = 1;
    this.totals = this.utils.cloneObj(this._totals);

    for(let user of users) {
      // availability hours
      let key:string = `${user.id}-total_availability.hours`;
      if(resTemp[key]==null || resTemp[key]==undefined) {
        resTemp[key] = {
          id: i++, // assignem i sumem
          // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
          email: user.email,
          emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
          employee_month_working_capacity_field: 'total_availability.hours',
          employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
          readonly: true,
          user_id: user.id,
          name: user.name,
          surnames: user.surnames,
          role: user.role,
          working_contracts: user.working_contracts,
          changed: false,
          unit: 'h'
        }
      }

      // non_productive_hours
      key = `${user.id}-non_productive_hours`;
      if(resTemp[key]==null || resTemp[key]==undefined) {
        resTemp[key] = {
          id: i++, // assignem i sumem
          // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
          email: user.email,
          emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
          employee_month_working_capacity_field: 'non_productive_hours',
          employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
          readonly: false,
          user_id: user.id,
          name: user.name,
          surnames: user.surnames,
          role: user.role,
          working_contracts: user.working_contracts,
          changed: false,
          unit: 'h'
        };
      }

      // chicle_hours
      // key = `${user.id}-chicle_hours`;
      // if(resTemp[key]==null || resTemp[key]==undefined) {
      //   resTemp[key] = {
      //     id: i++, // assignem i sumem
      //     department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
      //     email: user.email,
      //     emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
      //     employee_month_working_capacity_field: 'chicle_hours',
      //     employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
      //     readonly: false,
      //     user_id: user.id,
      //     name: user.name,
      //     surnames: user.surnames,
      //     role: user.role,
      //     working_contracts: user.working_contracts,
      //     changed: false,
      //     unit: 'h'
      //   };
      // }

      // development_time.hours
      key = `${user.id}-development_time.hours`;
      if(resTemp[key]==null || resTemp[key]==undefined) {
        resTemp[key] = {
          id: i++, // assignem i sumem
          // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
          email: user.email,
          emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
          employee_month_working_capacity_field: 'development_time.hours',
          employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
          readonly: true,
          user_id: user.id,
          name: user.name,
          surnames: user.surnames,
          role: user.role,
          working_contracts: user.working_contracts,
          changed: false,
          unit: 'h'
        };
      }

      // productivity prcnt
      key = `${user.id}-productivity_prcnt`;
      if(resTemp[key]==null || resTemp[key]==undefined) {
        resTemp[key] = {
          id: i++, // assignem i sumem
          // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
          email: user.email,
          emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
          employee_month_working_capacity_field: 'productivity_prcnt',
          employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
          readonly: false,
          user_id: user.id,
          name: user.name,
          surnames: user.surnames,
          role: user.role,
          working_contracts: user.working_contracts,
          changed: false,
          unit: '%'
        };
      }

      // development_budgetable_time.hours
      key = `${user.id}-development_budgetable_time.hours`;
      if(resTemp[key]==null || resTemp[key]==undefined) {
        resTemp[key] = {
          id: i++, // assignem i sumem
          // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
          email: user.email,
          emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
          employee_month_working_capacity_field: 'development_budgetable_time.hours',
          employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
          readonly: true,
          user_id: user.id,
          name: user.name,
          surnames: user.surnames,
          role: user.role,
          working_contracts: user.working_contracts,
          changed: false,
          unit: 'h'
        };
      }

      // price_per_hour
      if(this.userHasPricePerHourOnEmwc(user)) {
        key = `${user.id}-price_per_hour`;
        if(resTemp[key]==null || resTemp[key]==undefined) {
          resTemp[key] = {
            id: i++, // assignem i sumem
            // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
            email: user.email,
            emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
            employee_month_working_capacity_field: 'price_per_hour',
            employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
            readonly: true,
            user_id: user.id,
            name: user.name,
            surnames: user.surnames,
            role: user.role,
            working_contracts: user.working_contracts,
            changed: false,
            unit: '€/h'
          };
        }
      }

      // brute salary
      key = `${user.id}-brute_salary`;
      if(resTemp[key]==null || resTemp[key]==undefined) {
        resTemp[key] = {
          id: i++, // assignem i sumem
          // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
          email: user.email,
          emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
          employee_month_working_capacity_field: 'brute_salary',
          employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
          readonly: true,
          user_id: user.id,
          name: user.name,
          surnames: user.surnames,
          role: user.role,
          working_contracts: user.working_contracts,
          changed: false,
          unit: '€'
        };
      }

      // seguridad_social_prcnt
      if(this.userHasSeguridadSocialOnEmwc(user)) {
        key = `${user.id}-seguridad_social_prcnt`;
        if(resTemp[key]==null || resTemp[key]==undefined) {
          resTemp[key] = {
            id: i++, // assignem i sumem
            // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
            email: user.email,
            emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
            employee_month_working_capacity_field: 'seguridad_social_prcnt',
            employee_month_working_capacity_value: [null,null,null,null,null,null,null,null,null,null,null,null].map(val => { return {changed:false, val:val}; }),
            readonly: true,
            user_id: user.id,
            name: user.name,
            surnames: user.surnames,
            role: user.role,
            working_contracts: user.working_contracts,
            changed: false,
            unit: '%'
          };
        }
      }

      // real_cost_for_bisual
      key = `${user.id}-real_cost_for_bisual`;
      if(resTemp[key]==null || resTemp[key]==undefined) {
        resTemp[key] = {
          id: i++, // assignem i sumem
          // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
          email: user.email,
          emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
          employee_month_working_capacity_field: 'real_cost_for_bisual',
          employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
          readonly: true,
          user_id: user.id,
          name: user.name,
          surnames: user.surnames,
          role: user.role,
          working_contracts: user.working_contracts,
          changed: false,
          unit: '€'
        };
      }

      // real_cost_for_bisual_with_vacations
      key = `${user.id}-real_cost_for_bisual_with_vacations`;
      if(resTemp[key]==null || resTemp[key]==undefined) {
        resTemp[key] = {
          id: i++, // assignem i sumem
          // department_category: user.current_working_contract.current_working_contract_variable_condition.department_category,
          email: user.email,
          emwc: [null,null,null,null,null,null,null,null,null,null,null,null],
          employee_month_working_capacity_field: 'real_cost_for_bisual_with_vacations',
          employee_month_working_capacity_value: [0,0,0,0,0,0,0,0,0,0,0,0].map(val => { return {changed:false, val:val}; }),
          readonly: true,
          user_id: user.id,
          name: user.name,
          surnames: user.surnames,
          role: user.role,
          working_contracts: user.working_contracts,
          changed: false,
          unit: '€'
        };
      }

      for(let emwc of user.employee_strict_month_working_capacities) {
        // availability hours
        key = `${user.id}-total_availability.hours`;
        resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.total_availability.hours};
        this.totals[emwc.month-1].total_hours += emwc.total_availability.hours;
        resTemp[key].emwc[emwc.month-1] = emwc;

        // non_productive_hours
        key = `${user.id}-non_productive_hours`;
        resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.non_productive_hours};
        resTemp[key].emwc[emwc.month-1] = emwc;

        // chicle_hours
        // key = `${user.id}-chicle_hours`;
        // resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.chicle_hours};
        // resTemp[key].emwc[emwc.month-1] = emwc;

        // development_time.hours
        key = `${user.id}-development_time.hours`;
        resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.development_time.hours};
        this.totals[emwc.month-1].projects += emwc.development_time.hours;
        resTemp[key].emwc[emwc.month-1] = emwc;

        // productivity prcnt
        key = `${user.id}-productivity_prcnt`;
        resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.productivity_prcnt};
        resTemp[key].emwc[emwc.month-1] = emwc;

        // development_budgetable_time.hours
        key = `${user.id}-development_budgetable_time.hours`;
        resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.development_budgetable_time.hours};
        this.totals[emwc.month-1].projects_budgetable += emwc.development_budgetable_time.hours;
        resTemp[key].emwc[emwc.month-1] = emwc;

        // price_per_hour
        key = `${user.id}-price_per_hour`;
        if(resTemp[key]!=null) {
          resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.working_contract_variable_condition.price_per_hour};
          resTemp[key].emwc[emwc.month-1] = emwc;
        }

        // brute salary
        key = `${user.id}-brute_salary`;
        resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.working_contract_variable_condition.total_brute_anual_salary_with_trienniums_retflexible};
        resTemp[key].emwc[emwc.month-1] = emwc;

        // seguridad_social_prcnt
        key = `${user.id}-seguridad_social_prcnt`;
        if(resTemp[key]!=null) {
          resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.working_contract_variable_condition.seguridad_social_prcnt};
          resTemp[key].emwc[emwc.month-1] = emwc;
        }

        // real_cost_for_bisual
        key = `${user.id}-real_cost_for_bisual`;
        resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.working_contract_variable_condition.real_cost_for_bisual};
        this.totals[emwc.month-1].plantilla += emwc.working_contract_variable_condition.real_cost_for_bisual;
        resTemp[key].emwc[emwc.month-1] = emwc;

        // real_cost_for_bisual_with_vacations
        key = `${user.id}-real_cost_for_bisual_with_vacations`;
        resTemp[key].employee_month_working_capacity_value[emwc.month-1] = {changed: false, val: emwc.working_contract_variable_condition.real_cost_for_bisual_with_vacations};
        this.totals[emwc.month-1].plantilla_vacations += emwc.working_contract_variable_condition.real_cost_for_bisual_with_vacations;
        resTemp[key].emwc[emwc.month-1] = emwc;
      }
    }

    let res:EmployeePlanningRow[] = [];
    for(let key of Object.keys(resTemp)) {
      const obj = resTemp[key];
      res.push(obj);
    }

    return res;
  }

  private userHasPricePerHourOnEmwc(user:User): boolean {
    let res:boolean = false;
    for(let emwc of user.employee_strict_month_working_capacities) {
      if(emwc.working_contract_variable_condition.price_per_hour!=null) {
        res = true;
        break;
      }
    }
    return res;
  }

  private userHasSeguridadSocialOnEmwc(user:User):boolean {
    let res:boolean = false;
    for(let emwc of user.employee_strict_month_working_capacities) {
      if(emwc.working_contract_variable_condition.seguridad_social_prcnt!=null) {
        res = true;
        break;
      }
    }
    return res;
  }

  private listenMonthFormControl() {
    this.monthControl.valueChanges.subscribe(
      (month_keys:string[]) => {
        if(this.monthsInterval!=null) clearTimeout(this.monthsInterval);
        this.monthsInterval = setTimeout(() => {
          this.fetchPlanning();
          this.monthsInterval = null;
        }, 1000);
      }
    );
  }

  /**
   * Intenta simular el que fa el servidor... Sembla que va bastant bé. Fa càlculs per previsualitzar canvis abans d'enviar a servidor
   */
  private recalcMonth(row:EmployeePlanningRow, index:number, newVal:any) {
    const field = row.employee_month_working_capacity_field;
    const productivityPrcnt = field=="productivity_prcnt" ? newVal : this.searchFieldInDataSource('productivity_prcnt', row.user_id, index);
    const nonProductiveHours = field=="non_productive_hours" ? newVal : this.searchFieldInDataSource('non_productive_hours', row.user_id, index);
    // const horasChicle = field=="chicle_hours" ? newVal : this.searchFieldInDataSource('chicle_hours', row.user_id, index);
    const seguridadSocialPrcnt = field=="seguridad_social_prcnt" ? newVal : this.searchFieldInDataSource('seguridad_social_prcnt', row.user_id, index);

    this.api.recalcPlanningMonthByEmwcId(row.emwc[index].id, productivityPrcnt, nonProductiveHours, seguridadSocialPrcnt, 0).subscribe(
      newEmwc => {
        for(let i=0; i<this.dataSource.data.length; i++) {
          const itField = this.dataSource.data[i].employee_month_working_capacity_field;
          const itUserId = this.dataSource.data[i].user_id;

          if(itUserId==row.user_id) {
            if(itField=='development_time.hours') {
              // Actualitzem 'Horas de desarrollo'
              const diff = this.dataSource.data[i].employee_month_working_capacity_value[index].val - newEmwc.development_time.hours;
              this.dataSource.data[i].employee_month_working_capacity_value[index].val = newEmwc.development_time.hours;
              this.totals[index].projects -= diff;
            }
            else if(itField=='development_budgetable_time.hours') {
              // Actualitzem 'Horas presupuestables'
              const diff = this.dataSource.data[i].employee_month_working_capacity_value[index].val - newEmwc.development_budgetable_time.hours;
              this.dataSource.data[i].employee_month_working_capacity_value[index].val = newEmwc.development_budgetable_time.hours;
              this.totals[index].projects_budgetable -= diff;
            }
            else if(itField=='real_cost_for_bisual') {
              // Actualitzem 'Coste para Bisual'
              const diff = this.dataSource.data[i].employee_month_working_capacity_value[index].val - newEmwc.working_contract_variable_condition.real_cost_for_bisual;
              this.dataSource.data[i].employee_month_working_capacity_value[index].val = newEmwc.working_contract_variable_condition.real_cost_for_bisual;
              this.totals[index].plantilla -= diff;
            }
            else if(itField=='real_cost_for_bisual_with_vacations') {
              // Actualitzem 'Coste para Bisual con vacaciones'
              const diff = this.dataSource.data[i].employee_month_working_capacity_value[index].val - newEmwc.working_contract_variable_condition.real_cost_for_bisual_with_vacations;
              this.dataSource.data[i].employee_month_working_capacity_value[index].val = newEmwc.working_contract_variable_condition.real_cost_for_bisual_with_vacations;
              this.totals[index].plantilla_vacations -= diff;
            }
          }
        }

      }
    );

  }

  private searchFieldInDataSource(field:string, user_id:number, monthIndex:number) {
    let res:any = null;
    for(let item of this.dataSource.data) {
      if(item.user_id==user_id && item.employee_month_working_capacity_field==field && item.employee_month_working_capacity_value[monthIndex]!=null && item.employee_month_working_capacity_value[monthIndex].val!=null) {
        res = item.employee_month_working_capacity_value[monthIndex].val;
        break;
      }
    }
    return res;
  }

  private openNonProductiveHoursPlanningDialog(row:any, index:number) {
    const dialogRef = this.dialog.open(NonProductiveHoursPlanningDialogComponent, {
      width: '800px',
      data: {
        row: row,
        index: index,
        year: this.filterForm.value['year'],
        currentVal: row.employee_month_working_capacity_value[index].val
      }
    });

    dialogRef.afterClosed().subscribe(
      (hours:number) => {
        if(hours != null) {
          this.changeInput(row, index, hours);
        }
      }
    );
  }

  private changeInput(row:EmployeePlanningRow, index:number, newVal:number) {
    if(newVal!=null && row.employee_month_working_capacity_value[index].val != newVal) {
      row.employee_month_working_capacity_value[index].val = newVal;
      row.employee_month_working_capacity_value[index].changed = true;

      this.recalcMonth(row, index, newVal);
    }
  }

}

interface EmployeePlanningRow {
  id:number;
  // department_category:DepartmentCategory;
  email:string;
  emwc:EmployeeMonthWorkingCapacity[];
  user_id:number;
  name:string;
  surnames:string;
  role:Role;
  working_contracts:WorkingContract[];

  employee_month_working_capacity_field:string;
  employee_month_working_capacity_value:{val:any, changed:boolean}[];
  readonly:boolean;
  unit:string;
}
