<div [id]="inheritIdContent ? '' : 'content'" class = "inherit-content" >

  <div class="page-title">
    <h1>{{router.url.includes('/users-management') ? 'Editar Usuario' : 'Editar mi perfil'}}</h1>
    @if (user != null && user.role.slug === 'worker') {
      <button mat-flat-button [routerLink]="['/employees', 'direction', 'users-management', 'users']" type="button">
        <mat-icon matPrefix>arrow_back</mat-icon>
        Volver
      </button>
    }
    @if (permissions.validateDepartment(['direction', 'rrhh'])) {
      <button mat-flat-button color="warn" (click)="delete()" type="button">
        <mat-icon matPrefix>delete</mat-icon>
        Eliminar
      </button>
    }
  </div>

  <form class="card mb-5" [formGroup]="userForm">
    <div class="container" style="margin-top: 20px">
      <div class="row">
        <div class="col-12 col-md-6 col-xl-3" style="margin-top: 10px" style="margin-bottom: 10px">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Nombre</mat-label>
            <input type="text" matInput formControlName="name">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Apellidos</mat-label>
            <input type="text" matInput formControlName="surnames">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Correo electrónico</mat-label>
            <input type="email" matInput formControlName="email">
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          @if (itsMe) {
            <button mat-stroked-button style="padding:10.5px;" (click)="changePassword()" [disabled]="!canEdit()">
              <mat-icon>lock</mat-icon>
              Cambiar contraseña
            </button>
          }
          @if (!itsMe) {
            <button mat-stroked-button (click)="changePassword()" [disabled]="!canEdit()">
              <mat-icon>lock</mat-icon>
              Regenerar contraseña
            </button>
          }
        </div>


        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="sex">
              <mat-option value="M">Masculino</mat-option>
              <mat-option value="F">Femenino</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Fecha de nacimiento</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="birthday">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 col-xl-3">
          <mat-form-field class="nobottom" appearance="outline">
            <mat-label>Teléfono móvil</mat-label>
            <input type="tel" matInput placeholder="+34.666 555 444" formControlName="phone">
            <mat-icon matSuffix>phone</mat-icon>
            <mat-hint>F: +XX.XXX XXX XXX</mat-hint>
          </mat-form-field>
        </div>
      </div>

      @if (permissions.isDepartment('direction') || permissions.isDepartment('rrhh')) {
        <div class="row">
          <h2>Rol</h2>
          <div class="col-12 col-md-6 col-lg-6">
            <mat-form-field class="nobottom" appearance="outline">
              <mat-label>Rol</mat-label>
              <mat-select formControlName="role_id">
                @for (r of roles; track r) {
                  <mat-option [value]="r.id">{{ r.name }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </div>
          @if(userForm.value['role_id']===2) {
            <div class="col-12 col-md-6 col-lg-6">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Empresa</mat-label>
                <input type="text" matInput [formControl]="clientSelectControl" [matAutocomplete]="client_select_auto" [disabled]="!permissions.validateDepartment(['direction', 'rrhh']) || itsMe">
                <mat-autocomplete #client_select_auto="matAutocomplete" [displayWith]="displayFn">
                  @for (c of available_customers; track c) {
                    <mat-option [value]="c" (click)="selectCustomer(c)">{{ c.fact_name }}</mat-option>
                  }
                </mat-autocomplete>
                @if (customerSelected != null) {
                  <button mat-button class= "field" matSuffix mat-icon-button aria-label="Clear" (click)="resetCustomer()" [disabled]="!permissions.validateDepartment(['direction', 'rrhh'])">
                    <mat-icon>close</mat-icon>
                  </button>
                }
                @if (customerSelected == null) {
                  <mat-icon matSuffix>expand_more</mat-icon>
                }
              </mat-form-field>
            </div>
          }
        </div>
      }
      @if (router.url.includes('/users-management')) {
        <button class="mb-4" mat-flat-button color="accent" (click)="saveUser()" type="submit" [disabled]="!(userForm.valid && canEdit())">
          <mat-icon matPrefix>save</mat-icon>
          Guardar
        </button>
      }
      @if (!router.url.includes('/users-management')) {
        <button class="mb-4" mat-flat-button color="accent" (click)="saveUser()" type="submit" [disabled]="!(userForm.valid)">
          <mat-icon matPrefix>save</mat-icon>
          Guardar
        </button>
      }
    </div>
  </form>

  @if (activeContract!=null && userForm.value['role_id'] === 1) {
    <div class="contract-active">
      <div class="page-title">
        <h1>Contrato de trabajo activo</h1>
      </div>
      <form class="card" [formGroup]="userContractForm">
        <div class="container">
          <div class="row">
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Tipo de contrato</mat-label>
                <mat-select formControlName="working_contract_type_id">
                  @for(wct of working_contract_types; track wct;) {
                    <mat-option [value]="wct.id">{{ wct.name }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Inicio contrato</mat-label>
                <input matInput [matDatepicker]="pickerStartDate" formControlName="start_date">
                <mat-datepicker-toggle matIconSuffix [for]="pickerStartDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerStartDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Final contrato</mat-label>
                <input matInput [matDatepicker]="pickerEndDate" formControlName="end_date">
                <mat-datepicker-toggle matIconSuffix [for]="pickerEndDate"></mat-datepicker-toggle>
                <mat-datepicker #pickerEndDate></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-select formControlName="department_category_id">
                  @for(dc of department_categories; track dc;) {
                    <mat-option [value]="dc.id">{{ dc.name }}</mat-option>
                  }
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Horas estimadas semanales</mat-label>
                <input type="text" matInput formControlName="hours_per_week_expected">
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-checkbox formControlName="possible_future_early_retirement">¿Existe posibilidad de prejubilación?</mat-checkbox>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-checkbox formControlName="possible_future_retirement_plan">¿Existe posibilidad de plan de pensiones?</mat-checkbox>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field appearance="outline">
                <mat-label>Salario bruto base anual</mat-label>
                <input type="number" matInput formControlName="brute_anual_base_salary">
                <span matSuffix>€</span>
                @if(permissions.validateDepartment(['direction', 'rrhh'])) {
                  <mat-hint>Salario recomendado según categoria: {{ activeContract.current_working_contract_variable_condition.department_category.active_department_category_salary_condition.brute_anual_base_salary | currency }}</mat-hint>
                }
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field appearance="outline" class="nobottom">
                <mat-label>Plus anual por antigüedad (trienios)</mat-label>
                <input matInput type="number" disabled [value]="activeContract.current_working_contract_variable_condition.current_triennium_anual_salary" />
                <span matSuffix>€</span>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field appearance="outline">
                <mat-label>Payflow mensual</mat-label>
                <input type="number" matInput formControlName="flexible_remuneration_monthly_quantity">
                <span matSuffix>€</span>
                @if(permissions.validateDepartment(['direction', 'rrhh'])) {
                  <mat-hint>Payflow recomendado según categoria: {{ activeContract.current_working_contract_variable_condition.department_category.active_department_category_salary_condition.flexible_remuneration_monthly_quantity | currency }}</mat-hint>
                }
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Nº de pagas anuales</mat-label>
                <mat-select formControlName="number_of_payments_per_year">
                  <mat-option [value]="12">12 pagas</mat-option>
                  <mat-option [value]="14">14 pagas</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Precio hora</mat-label>
                <input type="number" matInput formControlName="price_per_hour">
                <span matSuffix>€/h</span>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Guardian: Salario bruto semanal</mat-label>
                <input type="number" matInput formControlName="guardian_weekly_salary">
                <span matSuffix>€</span>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Guardian: Plus por atender cliente</mat-label>
                <input type="number" matInput formControlName="guardian_trigger_attended_salary">
                <span matSuffix>€</span>
              </mat-form-field>
            </div>
            <div class="col-12 col-md-6 col-xl-3">
              <mat-form-field class="nobottom" appearance="outline">
                <mat-label>Guardian: Precio por hora</mat-label>
                <input type="number" matInput formControlName="guardian_price_per_hour">
                <span matSuffix>€/h</span>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <mat-form-field class="nobottom" appearance="outline" class="w-100">
                <mat-label>Descripción de trabajo</mat-label>
                <textarea matInput formControlName="job_description" cdkTextareaAutosize cdkAutosizeMinRows="5" cdkAutosizeMaxRows="15"></textarea>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <table id="timetable">
                <thead>
                  <tr>
                    <th></th>
                    <th>Lunes</th>
                    <th>Martes</th>
                    <th>Miércoles</th>
                    <th>Jueves</th>
                    <th>Viernes</th>
                    <th>Sábado</th>
                    <th>Domingo</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th>Mañana</th>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="monday_morning_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="monday_morning_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="tuesday_morning_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="tuesday_morning_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="wednesday_morning_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="wednesday_morning_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="thursday_morning_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="thursday_morning_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="friday_morning_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="friday_morning_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="saturday_morning_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="saturday_morning_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="sunday_morning_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="sunday_morning_exit_time" />
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr>
                    <th>Tarde</th>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="monday_afternoon_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="monday_afternoon_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="tuesday_afternoon_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="tuesday_afternoon_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="wednesday_afternoon_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="wednesday_afternoon_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="thursday_afternoon_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="thursday_afternoon_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="friday_afternoon_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="friday_afternoon_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="saturday_afternoon_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="saturday_afternoon_exit_time" />
                      </mat-form-field>
                    </td>
                    <td>
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="sunday_afternoon_entry_time" />
                      </mat-form-field>
                      -
                      <mat-form-field appearance="outline" class="nobottom">
                        <input matInput type="number" formControlName="sunday_afternoon_exit_time" />
                      </mat-form-field>
                    </td>
                  </tr>
                  <tr style="text-align: center;">
                    <th>Teletrabajo</th>
                    <td><mat-checkbox formControlName="has_remote_work_monday"></mat-checkbox></td>
                    <td><mat-checkbox formControlName="has_remote_work_tuesday"></mat-checkbox></td>
                    <td><mat-checkbox formControlName="has_remote_work_wednesday"></mat-checkbox></td>
                    <td><mat-checkbox formControlName="has_remote_work_thursday"></mat-checkbox></td>
                    <td><mat-checkbox formControlName="has_remote_work_friday"></mat-checkbox></td>
                    <td><mat-checkbox formControlName="has_remote_work_saturday"></mat-checkbox></td>
                    <td><mat-checkbox formControlName="has_remote_work_sunday"></mat-checkbox></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="row">
            <div class="col-12" id="vacations-info">
              <div class="vacations-section">
                <h2>Vacaciones totales</h2>
                <p class="subtitle">Por contrato, en el año actual</p>
                <p class="value">
                  {{ activeContract.vacation_days_info.vacations_total_days_available.days_total }} días
                  <!-- <span class="small">{{ activeContract.vacation_days_info.vacations_total_days_available.hours_total }} horas</span> -->
                </p>
              </div>
              <div class="vacations-section">
                <h2>Vacaciones restantes</h2>
                <p class="subtitle">Por contrato, en el año actual</p>
                <p class="value">
                  {{ (activeContract.vacation_days_info.vacations_total_days_available.days_total - activeContract.vacation_days_info.vacations_days_spent.days_total) | number:'1.0-2' }} días
                  <!-- <span class="small">{{ (activeContract.vacation_days_info.vacations_total_days_available.hours_total - activeContract.vacation_days_info.vacations_days_spent.hours_total) | number:'1.0-2' }} h.</span> -->
                </p>
              </div>
              <div class="vacations-section">
                <h2>Próximas vacaciones</h2>
                <p class="subtitle">Aceptadas</p>
                <p class="value">
                  - días
                  <span class="small">-</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        @if(permissions.validateDepartment(['direction', 'rrhh'])) {
          <button mat-flat-button color="accent" class="mb-1" (click)="saveContract()" type="submit" [disabled]="!userContractForm.valid">
            <mat-icon matPrefix>save</mat-icon>
            Guardar
          </button>
        }
      </form>
    </div>
  }

  @if (user != null && user.role.slug === 'client' && user.client_role === 'business') {
    <div class="company-data">
      <div class="page-title">
        <h1>Datos de empresa</h1>
      </div>
      <form class="card" [formGroup]="companyForm">
        <div class="container">
          <div class="row">
            <div class="column">
              <div class="container">
                <div class="row mt-4">
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Nombre</mat-label>
                      <input type="text" matInput formControlName="fact_name">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Email de contacto</mat-label>
                      <input type="text" matInput formControlName="email">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Teléfono de contacto</mat-label>
                      <input type="text" matInput formControlName="phone">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>NIF/CIF</mat-label>
                      <input type="text" matInput formControlName="vat">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-6">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Dirección de facturación</mat-label>
                      <input type="text" matInput formControlName="fact_address">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Ciudad</mat-label>
                      <input type="text" matInput formControlName="fact_city">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Código postal</mat-label>
                      <input type="text" matInput formControlName="fact_zipcode">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>Província</mat-label>
                      <input type="text" matInput formControlName="fact_county">
                    </mat-form-field>
                  </div>
                  <div class="col-12 col-md-6 col-xl-3">
                    <mat-form-field class="nobottom" appearance="outline">
                      <mat-label>País</mat-label>
                      <mat-select formControlName="fact_country">
                        @for (c of countries; track c) {
                          <mat-option [value]="c.name">{{ c.name }}</mat-option>
                        }
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <button mat-flat-button color="accent" class="mb-1" (click)="saveCompany()" type="submit" [disabled]="!(companyForm.valid)">
                  <mat-icon matPrefix>save</mat-icon>
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  }

</div>
