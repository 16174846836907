@if (this.me.role.slug == 'client') {
  <app-top-menu-client></app-top-menu-client>
}
@if (this.me.role.slug == 'worker') {
  @if(employee.meetingModeProject | async) {
    <div id="record-screen-banner">
      Modo reunión activado
    </div>
  }
  <app-top-menu-employee ></app-top-menu-employee>
}
<div id="main-container" [class.minimized]="minimized">
  <input id="menu-toggler" name="menu-toggler" type="checkbox" />
  <label for="menu-toggler" id="menu-icon">
    <span class="menu-line"></span>
    <span class="menu-line"></span>
    <span class="menu-line"></span>
  </label>

  <nav id="main-menu">
    @if (this.me.role.slug == 'worker') {
      <app-lateral-menu-employee></app-lateral-menu-employee>
    }
    @if (this.me.role.slug == 'client') {
      <app-lateral-menu-client></app-lateral-menu-client>
    }

    <div id="minimize-button">
      <button mat-stroked-button type="button" (click)="minimized = !minimized">
        <mat-icon>chevron_left</mat-icon>
        <span>
          Esconder
          <span class="shortcut">Ctrl+Space</span>
        </span>
      </button>
    </div>

    <p id="version">Bisual Intranet v{{ version }}</p>
  </nav>

  @if (minimized) {
    <div id="maximize-button">
      <button mat-stroked-button type="button" (click)="minimized = !minimized">
        <span>Abrir</span>
        <mat-icon>chevron_right</mat-icon>
      </button>
    </div>
  }

  <div id="content-container">
    <router-outlet></router-outlet>
  </div>
</div>
