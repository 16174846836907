import { Component, HostListener, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateAdapter } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Company } from 'src/app/models/company.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { ClockRegisterService } from 'src/app/services/clock-register.service';
import { CustomerService } from 'src/app/services/customer.service';
import { UserService } from 'src/app/services/user.service';
import { ForceClockRegisterDialogComponent } from './employees/main-dialogs/force-clock-register-dialog/force-clock-register-dialog.component';
import { AddNewWorkingTimeDialogComponent } from './employees/my-zone/working-times-history/add-new-working-time-dialog/add-new-working-time-dialog.component';
import { WorkingTime } from 'src/app/models/working_time.model';
import { GeneralSearchDialogComponent } from '../utils/general-search-dialog/general-search-dialog.component';
import { PermissionsService } from 'src/app/services/permissions.service';
import { EmployeeService } from 'src/app/services/employee.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
})
export class MainComponent implements OnInit {
  clientSelectControl = new UntypedFormControl();
  customerSelected: Company = null as any;
  available_customers: Company[] = [];
  showHeaderMenu: boolean = false;
  me: User = null as any;
  minimized: boolean = false;
  version:string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    public clock: ClockRegisterService,
    private customerService: CustomerService,
    private api: ApiService,
    private userService: UserService,
    private dialog:MatDialog,
    private permissions:PermissionsService,
    public employee:EmployeeService
  ) {
    this.version = environment.version;
  }

  ngOnInit(): void {
    this.checkStartAttribute();
    this.listenCustomerSelected();
    this.listenControlSearch();
    this.me = this.userService.getCurrentUser();
    this.redirectByRoleOnRootRoute();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    const key = event.key;
    const wasWriting = ["input", "textarea"].indexOf((event.target as any).tagName.toLowerCase()) >= 0;
    const isControlOrCmdPressed = event.ctrlKey || event.metaKey;

    if(key==="+" && !wasWriting && this.permissions.isWorker()) {
      this.addWorkingTime();
    } else if (((key === "k" && isControlOrCmdPressed) || key === '¡') && !wasWriting && this.permissions.isWorker()) {
      this.openGeneralSearch();
    } else if (key === " " && isControlOrCmdPressed && !wasWriting) {
      this.minimized = !this.minimized
    }
  }

  addWorkingTime() {
    const dialogRef = this.dialog.open(AddNewWorkingTimeDialogComponent, {
      width: '650px',
      data: { }
    });

    dialogRef.afterClosed().toPromise().then(
      (data:WorkingTime) => {
        if(data!=null) {
          this.api.createWorkingTime(data).toPromise().then(
            data => {

            }
          );
        }
      }
    );
  }

  openGeneralSearch() {
    const dialogRef = this.dialog.open(GeneralSearchDialogComponent, {
      width: '650px',
      data: { },
      panelClass: 'custom-dialog-container'
    });
  }

  clickClock(justification:string = null as any) {
    this.clock.click(justification)?.subscribe(
      status => {
        this.handleClick(status);
      }
    );
  }

  redirectByRoleOnRootRoute(){
    if(this.router.url === '/'){
      if(this.me.role.slug == 'worker') {
        this.router.navigate(['/employees']);
      } else {
        this.router.navigate(['/clients']);
      }
    }
  }

  selectCustomer(c: Company) {
    this.customerService.selectCustomer(c);
  }

  resetCustomer() {
    this.available_customers = [];
    this.clientSelectControl.setValue('');
    this.customerService.reset();
  }

  displayFn(company: Company): string {
    return company ? company.fact_name : '';
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/auth', 'login']);
  }

  private checkStartAttribute() {
    const start_attribute =
      this.activatedRoute.snapshot.queryParamMap.get('start');

    if (start_attribute != null) {
      this.router.navigate(this.activatedRoute.snapshot.url, {
        queryParams: {},
      });
    }
  }

  private listenCustomerSelected() {
    this.customerService.customerObservable.subscribe((data) => {
      this.customerSelected = data;
    });
  }

  private listenControlSearch() {
    this.clientSelectControl.valueChanges.subscribe((search_string: string) => {
      if (search_string != null && search_string.length >= 3) {
        const params = {
          search: search_string,
        };
        this.api.getCompanies(params).subscribe((data) => {
          this.available_customers = data.data;
        });
      } else this.available_customers = [];
    });
  }

  private handleClick(status:any) {
    if(status.status==="error") {
      const dialogRef = this.dialog.open(ForceClockRegisterDialogComponent, { width: '500px', data: { status: status } });
      dialogRef.afterClosed().subscribe(
        body => {
          if(body!=null && body.force===true) {
            this.clickClock(body.justification);
          }
        }
      );
    }
  }
}
