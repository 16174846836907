<div id="content">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Dashboard</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      @if (today_data != null) {
        <div class="col-12 col-md-6">
          <div class="card">
            <h2>Estado actual</h2>
            <table id="today-status">
              @for (d of today_data; track d) {
                <tr>
                  <td style="padding-right: 6px;">
                    @if (!d.vacation && !d.holiday && d.working && !d.rest) {
                      <span [matTooltip]="'Trabajando'" class="info-emoji">🟢</span>
                    }
                    @if (!d.vacation && !d.holiday && d.rest) {
                      <span [matTooltip]="'Descanso'" class="info-emoji">🔵</span>
                    }
                    @if (!d.vacation && !d.holiday && !d.working && !d.rest) {
                      <span [matTooltip]="'No trabajando'" class="info-emoji">⚫️</span>
                    }
                    @if (isToday(d.birthday)) {
                      <span [matTooltip]="'Es su cumpleaños'" class="info-emoji">🎂</span>
                    }
                    @if (d.vacation) {
                      <span [matTooltip]="'Vacaciones'" class="info-emoji">🌴</span>
                    }
                    @if (d.holiday) {
                      <span [matTooltip]="'Festivo'" class="info-emoji">🎆</span>
                    }
                    @if (d.remote_working && d.out_of_office) {
                      <span [matTooltip]="'Tiene teletrabajo'" class="info-emoji">🏠</span>
                    }
                    @if (!d.remote_working && d.out_of_office) {
                      <span [matTooltip]="'No está en la oficina, y debería'" class="info-emoji">
                        🏢
                        <span class="suffix-info-emoji">❌</span>
                      </span>
                    }
                  </td>
                  <td [matTooltip]="d.working_hours">{{ d.name + ' ' + d.surnames }}</td>
                  @if (d['task']!='') {
                    <mat-chip class="mat-task-chip" [style.background-color]="d['task_color']">{{ d['task'] }}</mat-chip>
                  }
                </tr>
              }
            </table>
          </div>
        </div>
      }
      @if (active_employees != null) {
        <div class="col-12 col-md-6">
          <div class="card">
            <h2>Vacaciones en los próximos 15 días</h2>
            <div>
            @for (wc of working_contract; track wc) {
              @if (wc!=null && wc.vacation_day_next_available!=null && wc.vacation_day_next_available <= 15) {
                  <span class="d-flex justify-center mt-2">
                    <img class="profile-image" src="{{wc.user.profile_picture_url}}" [matTooltip]="wc.user.name">
                    @if (wc.vacation_day_next_available==0) {
                      ya está de vacaciones
                    } @else {
                      en {{ wc.vacation_day_next_available }} {{ wc.vacation_day_next_available > 1 ? ' días.' : ' día.'}}
                    }
                  </span>
                }
              }
            </div>
          </div>
        </div>
      }
    </div>

    <div class="row">
      @if (this_week_summary!=null && this_week_summary.resume_categories_in_seconds!=null) {
        <div class="col-12 col-md-6">
          <app-progress-bar-card
            [title]="'Mi progreso esta semana'"
            [projects]="this_week_summary.resume_categories_in_seconds.projects"
            [categories]="this_week_summary.resume_categories_in_seconds.categories">
          </app-progress-bar-card>
        </div>
      }
      @if (prev_week_summary!=null && prev_week_summary.resume_categories_in_seconds!=null) {
        <div class="col-12 col-md-6">
          <app-progress-bar-card
            [title]="'Mi progreso la semana anterior'"
            [projects]="prev_week_summary.resume_categories_in_seconds.projects"
            [categories]="prev_week_summary.resume_categories_in_seconds.categories">
          </app-progress-bar-card>
        </div>
      }
      <!-- <div class="col-12 col-md-6" *ngIf="this_month_summary!=null && this_month_summary.resume_categories_in_seconds!=null">
      <app-progress-bar-card
        [title]="'Mi progreso este mes'"
        [projects]="this_month_summary.resume_categories_in_seconds.projects"
        [categories]="this_month_summary.resume_categories_in_seconds.categories">
      </app-progress-bar-card>
    </div>
    <div class="col-12 col-md-6" *ngIf="prev_month_summary!=null && prev_month_summary.resume_categories_in_seconds!=null">
      <app-progress-bar-card
        [title]="'Mi progreso el mes pasado'"
        [projects]="prev_month_summary.resume_categories_in_seconds.projects"
        [categories]="prev_month_summary.resume_categories_in_seconds.categories">
      </app-progress-bar-card>
    </div> -->
  </div>
</div>

<div class="container">
  <div class="card">
    <app-vacations></app-vacations>
  </div>
</div>
</div>

