<header>

  <div id="logo-container">
    <div id="logo" [routerLink]="['/employees', 'my-zone']">
      <img src="assets/img/logo/logo-bisual-black.png" alt="bisual" />
      @if (me.role.slug == 'worker') {
        <span id="logo-subtitle">Employees</span>
      }
      @if (me.role.slug == 'client') {
        <span id="logo-subtitle">Clientes</span>
      }
    </div>
  </div>
  <div id="header-menu-container">
    <button
      mat-icon-button
      (click)="showHeaderMenu = !showHeaderMenu"
      id="toggle-menu-header"
      >
      @if (!showHeaderMenu) {
        <mat-icon>expand_more</mat-icon>
      }
      @if (showHeaderMenu) {
        <mat-icon>expand_less</mat-icon>
      }
    </button>
    <div id="header-menu-content" [ngClass]="{ show: showHeaderMenu }">
      @if (clock_schedule_status!=null) {
        <button mat-stroked-button [ngClass]="{'warn': clock_schedule_status, 'success': !clock_schedule_status}" (click)="clickClock()" id="click-button">
          {{ clock_schedule_status ? "Registrar salida" : "Registrar entrada" }}
          <mat-icon>{{ clock_schedule_status ? "logout" : "login" }}</mat-icon>
        </button>
      }
      @if (clock_schedule_status!=null) {
        <button mat-stroked-button id="free-time-button" [matTooltip]="'Gestionar tiempo de descanso.'" (click)="clickClockRest()" [disabled]="!rest_status_last_in && !clock_rest_available">
          <div style="line-height: 1;">
            <mat-icon id="timer">timer</mat-icon>
            @if (!rest_status_last_in) {
              <mat-icon id="play">play_circle</mat-icon>
            }
            @if (rest_status_last_in) {
              <mat-icon id="pause">pause</mat-icon>
            }
            <mat-progress-spinner
              [color]="colorRestSpinner"
              strokeWidth="2"
              mode="determinate"
              diameter="30"
              [value]="valueRestTime()">
            </mat-progress-spinner>
          </div>
        </button>
      }

      @if ((employee.currentGuardStatus | async)?.active) {
        <button mat-stroked-button id="guardian-button">
          <div class="icon-wrapper">
            <mat-spinner color="warn" [strokeWidth]="2" [diameter]="33"></mat-spinner>
            <mat-icon>security</mat-icon>
          </div>
          <p>
            Guardia activada
            <span>Proyectos: {{ (employee.currentGuardStatus | async)?.project_names }}</span>
          </p>
        </button>
      }

      <button
        mat-stroked-button
        [matMenuTriggerFor]="quick_links_menu"
        id="quick-links-menu">
        Enlaces rápidos
        <mat-icon>expand_more</mat-icon>
      </button>
      <mat-menu #quick_links_menu="matMenu" xPosition="before">
        <button class="quick-action" mat-menu-item (click)="addWorkingTime()">
          <mat-icon>add</mat-icon>
          <div>
            <span>Registrar tiempo trabajado</span>
            <p class="subtitle">
              +
            </p>
          </div>
        </button>
        @if(!(employee.meetingModeProject | async)) {
          <button class="quick-action" mat-menu-item [routerLink]="['/employees','development', 'projects', ]" [queryParams]="{ro_is_favorite: 1}">
            <div class="icon-favorite-icon">
              <mat-icon>apps</mat-icon>
              <mat-icon>star</mat-icon>
            </div>
            <div style="display: inline-block;">Proyectos favoritos</div>
          </button>
          <button class="quick-action" mat-menu-item (click)="openGeneralSearch()">
            <mat-icon>search</mat-icon>
            <div>
              <span>Búsqueda avanzada</span>
              <p class="subtitle">
                Ctrl · k / ¡
              </p>
            </div>
          </button>
          <button class="quick-action" mat-menu-item (click)="openBisualBudgetRequest()">
            <mat-icon>request_quote</mat-icon>
            <div>
              <span>Solicitud de partida presupuestaria</span>
            </div>
          </button>
          <button class="quick-action" mat-menu-item (click)="openFormationDialog()">
            <mat-icon>school</mat-icon>
            Solicitar formación
          </button>
        }
        @else {
          <button class="quick-action" mat-menu-item (click)="cleanShareScreen()">
            <mat-icon>domain_verification_off</mat-icon>
            Desactivar modo reunión
          </button>
        }
      </mat-menu>

      <!-- <div id="client-selector">
      <span
        >{{
        customerSelected != null
        ? "Cliente seleccionado:"
        : "Selecciona cliente"
        }}
      </span>
      <mat-form-field appearance="outline">
        <mat-icon matPrefix>account_circle</mat-icon>
        <input
          type="text"
          matInput
          [formControl]="clientSelectControl"
          [matAutocomplete]="client_select_auto"
          />
        <mat-autocomplete
          #client_select_auto="matAutocomplete"
          [displayWith]="displayFn"
          >
          <mat-option
            *ngFor="let c of available_customers"
            [value]="c"
            (click)="selectCustomer(c)"
            >{{ c.fact_name }}</mat-option
            >
          </mat-autocomplete>
          <button
            mat-button
            *ngIf="customerSelected != null"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="resetCustomer()"
            >
            <mat-icon>close</mat-icon>
          </button>
          <mat-icon matSuffix *ngIf="customerSelected == null"
            >expand_more</mat-icon
            >
          </mat-form-field>
        </div> -->

        @if (!(employee.meetingModeProject | async)) {
          @if (currentTaskTitle != null) {
            <div id="next-task-div">
              <img id="gif-task" src="assets/img/icons/live_task_icon.gif" />
              <div id="task-titles">
                <p id="current-task" [matTooltip]="currentTaskTitle"> {{ currentTaskTitle }} </p>
                @if (nextTaskTitle!=null && nextTaskTitle!='') {
                  <p id="next-task" [ngClass]="{visibleDiv: !nextTaskTitle}" [matTooltip]="nextTaskTitle!=null ? nextTaskTitle : ''"> Next:  {{ nextTaskTitle }} ({{ nextStartDate |  date:'shortTime' }}) </p>
                }
              </div>
            </div>
          }
        }

        <button
          id="account-button"
          mat-stroked-button
          [matMenuTriggerFor]="account_menu"
          >
          <img [src]="me.profile_picture_url" />
          <span id="me-container">
            {{ me.name + " " + me.surnames }}
            <span class="department">
              {{ me!=null && me.current_working_contract.current_working_contract_variable_condition.department_category!=null ? (me.current_working_contract.current_working_contract_variable_condition.department_category.name) : '' }}
            </span>
          </span>
          <mat-icon>expand_more</mat-icon>
        </button>

        <mat-menu #account_menu="matMenu" xPosition="before">
          <button
            mat-menu-item [routerLink]="['employees/my-profile']"
            >
            <mat-icon>account_circle</mat-icon>
            Mi cuenta
          </button>
          <button mat-menu-item (click)="logout()">
            <mat-icon>logout</mat-icon>
            Cerrar sesión
          </button>
        </mat-menu>
      </div>
    </div>
  </header>
