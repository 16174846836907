import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CalendarAssignmentDialogComponent } from '../calendar-assignment-dialog/calendar-assignment-dialog.component';
import * as moment from 'moment';
import { DateFromToDialogComponent } from 'src/app/components/utils/date-from-to-dialog/date-from-to-dialog.component';

@Component({
  selector: 'app-project-assignment-table',
  templateUrl: './project-assignment-table.component.html',
  styleUrls: ['./project-assignment-table.component.css'],
  animations: [
    trigger('expandCollapse', [
      state('collapsed', style({
        transform: 'rotate(0deg)'
      })),
      state('expanded', style({
        transform: 'rotate(180deg)'
      })),
      transition('collapsed <=> expanded', animate('300ms ease-in-out'))
    ])
  ]
})
export class ProjectAssignmentTableComponent implements OnInit {

  @ViewChild(MatTable) table: MatTable<any>;
  dataSource:MatTableDataSource<ProjectAssignmentRow> = new MatTableDataSource();
  private templateDisplayedColumns:string[] = ['type','category','child1',"general"];
  displayedColumns:string[] = this.templateDisplayedColumns;
  resume:ResponseResume;

  showSaveButton:boolean = false;
  isSaving:boolean = false;
  me : User = null as any;
  calendar_button_disabled:boolean = false;
  copy_button_disabled:boolean = true;

  private original_response:any;

  private HOURS_TOLERANCE:number = 1;

  @Input() from:Date = new Date();
  @Input() to:Date = new Date();
  @Output() onSave:EventEmitter<any> = new EventEmitter();

  constructor(
    private api:ApiService,
    public permissions:PermissionsService,
    private utils:UtilsService,
    private userService:UserService,
    private dialog:MatDialog) { }

  ngOnInit(): void {
    this.me = this.userService.getCurrentUser();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(changes.from || changes.to) {
      this.fetchAssignment();
    }
  }

  trackByIndexUsers(i:number) { return i; } // Issue: https://github.com/angular/components/issues/8361#issuecomment-345804954
  // Si sólo quieres usar el índice
  trackByIndexOnly(index: number): number {
    return index;
  }

  createColumnKey(user_id:number) {
    return `user_${user_id}`;
  }

  isFooterRow(id:string, td:HTMLElement) {
    return td.parentElement!=null && td.parentElement.id===id;
  }

  onChangeInput(rowIndex:number, userIndex:number, newVal:number) {
    const user_id = this.dataSource.data[rowIndex].users[userIndex].id;
    const user = this.original_response.resume.users.find((u:any) => u.id===user_id);

    // Agafem la diferència
    const currentVal = this.dataSource.data[rowIndex].users[userIndex].hours!==null ? this.dataSource.data[rowIndex].users[userIndex].hours : 0;
    newVal = newVal ?? 0;
    const diff = newVal - currentVal;
    const newValPrcnt = newVal * this.resume.users[userIndex].productivity_prcnt / 100;
    const currentValPrcnt = currentVal * this.resume.users[userIndex].productivity_prcnt / 100;
    const diff_budgetable = newValPrcnt - currentValPrcnt;

    // Assignem hora a l'usuari
    this.dataSource.data[rowIndex].users[userIndex].hours = newVal;
    this.dataSource.data[rowIndex].users[userIndex].changed = true;

    // find in datasource object with the same id
    let project = this.dataSource.data.find((u:any) => u.budgetable_id=== this.dataSource.data[rowIndex].project_id);
    if(project){
      project.hours_unassigned += this.dataSource.data[rowIndex].needs_productive_factor_calc ? diff_budgetable : diff;
    }

    // Sumem hores al total de usuari
    if(this.dataSource.data[rowIndex].type==='wtc') {
      this.resume.users[userIndex].non_development_hours.assigned += diff; // hores reals
    }
    else {
      this.resume.users[userIndex].development_hours.assigned += diff; // hores reals
    }
    this.resume.users[userIndex].totals.assigned += diff; // hores reals

    // Sumem hores al projecte / categoria
    if(this.dataSource.data[rowIndex].type==='wtc') {
      this.dataSource.data[rowIndex].hours_assigned += diff;
    }
    else if(!this.dataSource.data[rowIndex].needs_productive_factor_calc) {
      this.dataSource.data[rowIndex].hours_unassigned -= diff; // restem amb hores reals
    }
    else {
      this.dataSource.data[rowIndex].hours_unassigned -= diff_budgetable; // restem amb el % de producció
    }

    // Activem boto de guardar
    if(!this.showSaveButton) this.showSaveButton = true;
    this.calendar_button_disabled = true;
    this.copy_button_disabled = true;
  }

  save() {
    if(confirm("¿Estás seguro que quieres guardar los cambios?")) {
      this.isSaving = true;

      let res:any[] = [];
      for(let row of this.dataSource.data) {
        for(let user of row.users) {
          if(user.changed) {
            let item:any = this.utils.cloneObj(user);
            item.start_week_day = this.utils.dateToStringYYYYMMDD(this.from);
            item.end_week_day = this.utils.dateToStringYYYYMMDD(this.to);
            item.type = row.type;
            item.distributable_id = row.relatable_id;
            item.employee_id = user.id;
            item.working_time_category_id = row.budgetable_id; // error here !!
            item.relatable_type = user.relatable_type;
            item.relatable_id = user.relatable_id;
            res.push(item);
          }
        }
      }

      if(res.length > 0) {
        this.api.saveBulkWeeklyWorkingTimeBudgetEmployee(res).subscribe(
          data => {
            this.showSaveButton = false;
            this.isSaving = false;
            this.onSave.emit(data);

            this.fetchAssignment();
          },
          error => {
            this.showSaveButton = true;
            this.isSaving = false;
          }
        );
      }
    }
  }

  checkUserPermissions(row:any, userIndex:number) {
    if(row.disabled) return false; //todo mirar si es el camp correcte
    if(this.me.current_working_contract.current_working_contract_variable_condition.department_category.name == 'CEO' || this.me.current_working_contract.current_working_contract_variable_condition.department_category.name == 'RRHH' || this.me.current_working_contract.current_working_contract_variable_condition.department_category.name == 'Product Owner' ){
      return true;
    }else{
      if(row.type=='wtc') {
        if(this.me.current_working_contract.current_working_contract_variable_condition.department_category.slug == 'ceo' || this.me.current_working_contract.current_working_contract_variable_condition.department_category.slug == 'product-owner' || this.me.current_working_contract.current_working_contract_variable_condition.department_category.slug == 'tech-lead' ){
          return true;
        }else{
          return false;
        }
      }else{
        if(row.users.find((u:any) => u.id===this.me.id).role_id == 11 ){
          return true;
        }
      }
    }
      return false;
  }

  openCalendars() {
    const dialogRef = this.dialog.open(CalendarAssignmentDialogComponent, {
      width: '90%',
      disableClose: true,
      data: {
        from: this.from,
        to: this.to
      }
    });
  }

  copyLastWeek() {
    if(confirm("¿Estás seguro que quieres copiar la semana anterior a la setmana seleccionada actualmente? Se borrarán todas las asignaciones y registros de calendarios de ESTA semana.")) {
      this.api.copyLastWeekAssignation(moment(this.from)).subscribe(
        data => {
          // this.fetchAssignment();
        }
      );
    }
  }


  copyWeekFromTo() {
    const dialogRef = this.dialog.open(DateFromToDialogComponent, {
      width: '380px',
      disableClose: true,
      data: {
        from: this.from,
        to: this.to
      }
    });
    // subscribe to the result
    dialogRef.afterClosed().subscribe(result => {
      if(result) {
        if(confirm("¿Estás seguro que quieres copiar la semana seleccionada a la setmana seleccionada actualmente? Se borrarán todas las asignaciones y registros de calendarios de ESTA semana.")) {
          this.api.copyWeekFromTo(moment(result.start as Date), moment(result.end as Date)).subscribe(
            data => {
              // this.fetchAssignment();
            }
          );
        }
      }
    });

  }

  onDblClickInput(rowIndex:number, userIndex:number) {
    if(this.dataSource.data[rowIndex].hours_unassigned != null) {
      this.onChangeInput(rowIndex, userIndex, this.dataSource.data[rowIndex].users[userIndex].hours + this.dataSource.data[rowIndex].hours_unassigned)
    }
  }

  private fetchAssignment() {
    this.api.getEmployeesProjectAssignment(this.from, this.to).toPromise().then(
      data => {
        this.calendar_button_disabled = false;
        this.copy_button_disabled = false;
        this.original_response = data;
        this.processAssignments(data);
        this.showSaveButton = false;
      }
    );
  }

  private processAssignments(data:any) {
    // Guardem el resume
    this.resume = data.resume as ResponseResume;

    // Inicialitzem resposta
    let res:ProjectAssignmentRow[] = [];

    // Inicialitzem columnes
    let columns:string[] = Object.values(this.utils.cloneObj(this.templateDisplayedColumns));
    data.resume.users.forEach((u:any) => {
      columns.push(this.createColumnKey(u.id));
    });

    // Processem working time categories
    data.working_time_categories.forEach((wtc:any, index:number) => {
      let item:ProjectAssignmentRow = {
        budgetable_id: wtc.id,
        name: wtc.name,
        type: "wtc",
        color: wtc.color,
        is_first: index===0,
        is_schedulable: true,
        rowspan: data.working_time_categories.length,
        hours_assigned: wtc.hours_assigned,
        hours_unassigned: null as any,
        needs_productive_factor_calc: wtc.needs_productive_factor_calc,
        department_categories: wtc.department_categories,
        hidden: false,
        users: wtc.users.map((u:any) => {
          return {
            id: u.id,
            hours: u.hours ?? 0,
            changed: false,
            relatable_type: 'App\\Models\\WorkingTimeCategory', // de moment hardcoded
            relatable_id: wtc.id,
            weekly_working_time_budget_employee_id: u.weekly_working_time_budget_employee_id
          };
        })
      };
      res.push(item);
    });

    // Error en l'its first !!
    Object.values(data.projects).forEach((c:any, indexProjects:number) => {
      if(c.distributables.length>0) {
        res.push(this.transformApiCompanyToProjectAssignmentRow(c , c.distributables[0].users,indexProjects ));
        Object.values(c.distributables).forEach((distributable:any, index:number) => {
          if(distributable.parent_weekly_working_time_budget_distribution_distributable_id == null){
            res.push(this.transformApiAssignmentWorkingContractToProjectAssignmentRow(distributable, distributable.distributable.name,'', "App\\Models\\WeeklyWorkingTimeBudgetDistributionDistributables",1, distributable.distributable.color, distributable.distributable.id,c.id));
            if(distributable.children.length>0) {
                distributable.children.forEach((child:any, indexChild:number) => {
                  res.push(this.transformApiAssignmentWorkingContractToProjectAssignmentRow(child,child.distributable_name, child.distributable.name, "App\\Models\\WeeklyWorkingTimeBudgetDistributionDistributables",2, child.distributable.color, child.distributable.id,c.id));
                  if(child.children.length>0) {
                    child.children.forEach((child2:any, indexChild2:number) => {
                      res.push(this.transformApiAssignmentWorkingContractToProjectAssignmentRow(child2,child2.distributable_name, child2.distributable_name, "App\\Models\\WeeklyWorkingTimeBudgetDistributionDistributables",3, child2.distributable.color, child2.distributable.id,c.id));
                      if(child2.children.length>0) {
                        child2.children.forEach((child3:any, indexChild3:number) => {
                          res.push(this.transformApiAssignmentWorkingContractToProjectAssignmentRow(child3,child3.distributable_name, child3.distributable_name, "App\\Models\\WeeklyWorkingTimeBudgetDistributionDistributables",4, child3.distributable.color, child3.distributable.id,c.id));
                        });
                      }
                    });
                  }
                });
              }
            }
          });
        }
      });
      this.dataSource.data = res;
      this.displayedColumns = columns;
  }


  private transformApiCompanyToProjectAssignmentRow(company:any , users :any ,index:number): ProjectAssignmentRow {
    let hours_unassigned = 0;
    company.distributables.forEach((element:any) => {
      element.users.forEach((user:any) => {
        let prcnt =this.resume.users.find((u:any) => u.id === user.id)?.productivity_prcnt ?? 0;
        hours_unassigned += (user.hours * ((element.distributable.needs_productive_factor_calc ?? false) ? prcnt / 100 : 1));
      });
    });

    return {
      budgetable_id: company.id,
      name: company.name,
      company_name: company.company_name,
      type: "company",
      is_first: index == 0 ? true : false,
      is_schedulable: false,
      rowspan: index == 0 ? 999 : 1,
      hours_assigned: company.totalHours,
      deep: 0,
      child_length: company.distributables.length,
      hours_unassigned: hours_unassigned ?? 0,
      hidden: false,
      needs_productive_factor_calc: false,
      users: users.map((u:any) => {
        return {
          id: u.id,
          changed: false,
          hours: u.hours ?? 0,
          relatable_type: 'company',
          relatable_id: company.id,
          weekly_working_time_budget_employee_id: u.weekly_working_time_budget_employee_id,
          role_id : u.role_id
        };
      }),
      color: '',
    };
  }


  private transformApiAssignmentWorkingContractToProjectAssignmentRow(distributable:any, name:string, subtitle:string, type:string,rowspan:number, color:string, budgetable_id:number,project_id:number ): ProjectAssignmentRow {
    return {
      budgetable_id: budgetable_id,
      name: name,
      subtitle: subtitle,
      type: type,
      is_first: false,
      rowspan: rowspan,
      deep: rowspan,
      color: color,
      is_schedulable: distributable.is_schedulable,
      is_shareable: distributable.distributable.is_shareable ?? null,
      disabled: false,
      hidden: true,
      hours_assigned: distributable.hours_assigned,
      hours_unassigned: distributable.hours_unassigned,
      relatable_type: distributable.relatable_type,
      relatable_id: distributable.id,
      child_length: distributable.children.length,
      project_id: project_id,
      needs_productive_factor_calc: distributable.distributable?.needs_productive_factor_calc,
      weekly_working_time_budgets: distributable.users.map((u:any) => u.weekly_working_time_budget_employee_id).join(","),
      users: distributable.users.map((u:any) => {
        return {
          id: u.id,
          changed: false,
          hours: u.hours ?? 0,
          // ara per ara sempre sera wwtbdd
          relatable_type: 'App\\Models\\WeeklyWorkingTimeBudgetDistributionDistributable',
          relatable_id: distributable.id,
          weekly_working_time_budget_employee_id: u.weekly_working_time_budget_employee_id,
          role_id: distributable.users ? distributable.users.find((user:any) => user.id === u.id).role : null
        };
      })
    };
  }

  acceptableHoursAssigned(hours1:number, hours2:number) {
    return Math.abs((Math.round(hours1*100)/10)-(Math.round(hours2*100)/10))<=this.HOURS_TOLERANCE;
  }

  overflowHoursAssigned(hours1:number, hours2:number) {
    return !this.acceptableHoursAssigned(hours1, hours2) && hours1<hours2;
  }

  hideChilds(row:any ,deep:number,element:any){
    const index = this.dataSource.data.indexOf(row);
    var nextRow = this.dataSource.data[index+1] ?? null;
    var hidden = row.hidden ?? false;

    if(element && element.target.classList){
      if(element.target.classList.contains('hide')){
        hidden = false;
      }else if(element.target.classList.contains('show')){
        hidden = true;
      }
    }
    if(nextRow && nextRow.deep !== undefined && nextRow.deep > deep){
      nextRow.hidden = hidden; // Posible error recursiu here
      this.hideChilds(nextRow , deep ,element);
    }else{
      if(element.target.classList.contains('hide')){
        element.target.classList.remove('hide');
        element.target.classList.add('show');
      }else if(element.target.classList.contains('show')){
        element.target.classList.remove('show');
        element.target.classList.add('hide');
      }
    }
  }

}



interface ProjectAssignmentRow {
  budgetable_id:number;
  is_first:boolean;
  hours_assigned:number;
  hours_unassigned:number;
  name:string;
  subtitle?:string;
  company_name?:string;
  rowspan:number;
  deep?:number;
  disabled?:boolean;
  hidden?:boolean;
  is_schedulable?:boolean;
  is_shareable?:boolean;
  type:string;
  department_categories?:any[];
  color:string;
  relatable_type?:string;
  relatable_id?:number;
  child_length?:number;
  project_id?:number;
  weekly_working_time_budgets?:string;
  needs_productive_factor_calc:boolean;
  users:{
    id:number;
    hours:number;
    weekly_working_time_budget_employee_id:number;
    changed:boolean;
    relatable_type?:string;
    relatable_id?:number;
    role_id?:number;
  }[];
}

interface ResponseResume {
  general:{
    development_hours:ResponseResumeGeneralHours;
    non_development_hours:ResponseResumeGeneralHours;
    totals:ResponseResumeGeneralHours;
  };
  users:{
    id:number;
    name:string;
    surnames:string;
    productivity_prcnt:number;
    development_hours:ResponseResumeGeneralHours;
    non_development_hours:ResponseResumeGeneralHours;
    totals:ResponseResumeGeneralHours;
    has_holidays: boolean;
    has_vacations: boolean;
    relatable_type: string;
    relatable_id: number;
    role_id: number;
  }[];
}

interface ResponseResumeGeneralHours {
  assigned:number;
  to_do:number;
  to_do_budgetable:number;
}
