<div id="content">
  <div class="page-title">
    <h1>Ordenadores de trabajo</h1>
    <button mat-flat-button color="accent" (click)="openDialog()">
      <mat-icon style="margin-right: 10px">add</mat-icon>
      Alta nuevo ordenador
    </button>
  </div>

  <div class="bisual-table">
    <form class="card paginator" [formGroup]="filterForm">
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        (page)="changePage($event)"
        style="margin-right: auto;"
      ></mat-paginator>

    </form>

    <div class="bisual-table-container">
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="model">
          <th mat-header-cell *matHeaderCellDef>Modelo</th>
          <td mat-cell *matCellDef="let computer">
            {{ computer.model }}
          </td>
        </ng-container>

        <ng-container matColumnDef="buyed_at">
          <th mat-header-cell *matHeaderCellDef>Fecha de compra</th>
          <td mat-cell *matCellDef="let computer">
            {{ computer.buyed_at | date:'shortDate' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="chip">
          <th mat-header-cell *matHeaderCellDef>Procesador</th>
          <td mat-cell *matCellDef="let computer">
            {{ computer.chip }}
          </td>
        </ng-container>

        <ng-container matColumnDef="memory">
          <th mat-header-cell *matHeaderCellDef>Memoria</th>
          <td mat-cell *matCellDef="let computer">
            {{ computer.ram_memory_in_gb }} GB RAM / {{ computer.ssd_memory_in_gb }} GB SSD
          </td>
        </ng-container>

        <ng-container matColumnDef="active_user">
          <th mat-header-cell *matHeaderCellDef>Trabajador asignado</th>
          <td mat-cell *matCellDef="let computer; let i = index;">
            <mat-form-field appearance="outline" class="nobottom">
              <mat-select [ngModel]="computer.active_user?.id" (ngModelChange)="onOwnerChange(i, $event)">
                <mat-option>No asignado</mat-option>
                @for(user of activeWorkers; track user;) {
                  <mat-option [value]="user.id">{{ user.name }} {{ user.surnames }}</mat-option>
                }
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let computer">
            <button mat-icon-button [matMenuTriggerFor]="menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item (click)="openDialog(computer)">
                <mat-icon>edit</mat-icon>
                Editar
              </button>
              <button mat-menu-item (click)="navigateToHolded(computer)">
                <mat-icon>visibility</mat-icon>
                Ver activo en Holded
              </button>
            </mat-menu>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    </div>
  </div>
</div>
