import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment';
import { EmployeeCalendarRegisterWithRelationsSerialized } from 'src/app/models/employee_calendar_register.model';
import { User } from 'src/app/models/user.model';
import { WeeklyWorkingTimeBudgetEmployee, WeeklyWorkingTimeBudgetEmployeeSerialized } from 'src/app/models/weekly_working_time_budget_employee.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-calendar-event-detail-dialog',
  templateUrl: './calendar-event-detail-dialog.component.html',
  styleUrls: ['./calendar-event-detail-dialog.component.css']
})
export class CalendarEventDetailDialogComponent implements OnInit {
  event:any;
  ecr:EmployeeCalendarRegisterWithRelationsSerialized;
  form:UntypedFormGroup;

  shareable_user_wwtbes:WeeklyWorkingTimeBudgetEmployeeSerialized[] = [];

  constructor(
    private dialogRef: MatDialogRef<CalendarEventDetailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data:{event:{meta:{ecr:EmployeeCalendarRegisterWithRelationsSerialized}}},
    fb:UntypedFormBuilder,
    private api:ApiService,
    private user:UserService
  ) {
    this.event = data.event;
    this.ecr = this.data.event.meta.ecr;

    const datePipe = new DatePipe('es-ES');
    this.form = fb.group({
      custom_title: [this.event.meta.ecr.custom_title],
      call_link: [this.event.meta.ecr.call_link],
      start: [datePipe.transform(this.event.start!.toISOString(), 'yyyy-MM-ddTHH:mm:ss')!.slice(0, 16)],
      end: [datePipe.transform( this.event.end!=null ? this.event.end!.toISOString() : this.event.start!.toISOString(), 'yyyy-MM-ddTHH:mm:ss')!.slice(0, 16)],
      shared_users: [this.ecr.shared_users ?? [], this.ecr.wtc_is_shareable || this.ecr.ecr_wtc_is_shareable ? null : Validators.compose([Validators.min(0), Validators.max(0)])]
    });
  }

  ngOnInit(): void {
    if(this.ecr.wtc_is_shareable || this.ecr.ecr_wtc_is_shareable) this.fetchShareableUserWwtbes();
  }

  close() {
    this.dialogRef.close();
  }

  addMinutes(minutes:number) {
    //end date is start date + minutes
    const start = new Date(this.form.value.start);
    const end = new Date(start.getTime() + minutes * 60000);
    const datePipe = new DatePipe('es-ES');
    this.form.patchValue({end: datePipe.transform(end!.toISOString(), 'yyyy-MM-ddTHH:mm:ss')!.slice(0, 16)});
  }

  save() {
    this.dialogRef.close({
      start: new Date(this.form.value.start),
      end: new Date(this.form.value.end),
      custom_title: this.form.value.custom_title,
      call_link: this.form.value.call_link,
      shared_users: this.form.value.shared_users,
    });
  }

  private fetchShareableUserWwtbes() {
    const user:User = this.user.getCurrentUser();
    this.api.getWeeklyWorkingTimeBudgetEmployeesSerialized({
      start_week_day: moment(this.event.start).startOf('week').format('YYYY-MM-DD'),
      end_week_day: moment(this.event.end).endOf('week').format('YYYY-MM-DD'),
      relatable_type: this.ecr.wwtbe_relatable_type,
      relatable_id: this.ecr.wwtbe_relatable_id,
    }).subscribe(
      data => {
        this.shareable_user_wwtbes = data.filter(row => row.user_id != user.id);
      }
    );
  }
}
