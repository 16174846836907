import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user.model';
import { ApiService } from './api.service';
import { UtilsService } from './utils.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { WorkingContract } from '../models/working_contract.model';
import { EmployeeCalendarRegisterWithRelationsSerialized } from '../models/employee_calendar_register.model';
import * as moment from 'moment';
import { CalendarAssignmentDialogComponent } from '../components/main/employees/project-management/project-assignment/calendar-assignment-dialog/calendar-assignment-dialog.component';
import { PlatformService } from './platform.service';
import { environment } from 'src/environments/environment';
import { ForceSetupDoNotDisturbShortcutsDialogComponent } from '../components/main/shared/force-setup-do-not-disturb-shortcuts-dialog/force-setup-do-not-disturb-shortcuts-dialog.component';
import { BisualSettingService } from './bisual-setting.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DownloadNewVersionElectronAppDialogComponent } from '../components/main/shared/download-new-version-electron-app-dialog/download-new-version-electron-app-dialog.component';
import { BisualSetting } from '../models/bisual-setting.model';


@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  // Ongoing task variable
  private todaysTasks:CalendarRegisterLocalItem[] = [];
  private currentTaskSubject: BehaviorSubject<{ current:CalendarRegisterLocalItem, next:CalendarRegisterLocalItem }> = new BehaviorSubject({current: null as any, next:null as any});
  public currentTask : Observable<{ current: CalendarRegisterLocalItem, next: CalendarRegisterLocalItem }> = this.currentTaskSubject.asObservable();

  // Guard status
  private currentGuardStatusSubject: BehaviorSubject<{active:boolean; project_names:string|undefined}> = new BehaviorSubject({active: false, project_names: undefined} as any);
  public currentGuardStatus : Observable<{active:boolean; project_names:string|undefined}> = this.currentGuardStatusSubject.asObservable();

  // Meeting mode
  private meetingModeLocalStorageKey:string = "meeting_mode_project_id";
  private meetingModeProjectId: BehaviorSubject<number> = new BehaviorSubject(null as any);
  public meetingModeProject : Observable<number> = this.meetingModeProjectId.asObservable();

  // Current working contract from user
  private currentWorkingContractActiveSubject: BehaviorSubject<WorkingContract> = new BehaviorSubject(null as any);
  public workingContractActive : Observable<WorkingContract> = this.currentWorkingContractActiveSubject.asObservable();

  // Active Projects
  private active_projects:{project_id:number,project_name:string,department_category_id:number,slug:string}[] = [];

  // Download App Observable
  private forceDownloadElectronAppSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public forceDownloadElectronApp: Observable<boolean> = this.forceDownloadElectronAppSubject.asObservable();

  // Force Activate
  private forceSetupDoNotDisturbShortcutsDialogRef:MatDialogRef<ForceSetupDoNotDisturbShortcutsDialogComponent>|undefined;

  // meetings already stopped from meeting mode
  private meetingsAlreadyStoppedFromMeetingModeLocalStorageKey:string = "meetings_already_stopped_from_meeting_mode_ecr_ids";

  constructor(private apiService:ApiService,
              private utils:UtilsService,
              private dialog:MatDialog,
              private platform:PlatformService) {

  }

  init(user:User, force_employees_to_use_desktop_app:boolean, frontend_version_required:BisualSetting) {
    this.loadCurrentActiveProjects(user.id);
    this.loadCurrentWorkingContract(user);
    this.loadCurrentGuardianStatus();
    this.loadRequiredActions(user);
    this.initMeetingModeStatus();
    this.forceIfNecessaryToDownloadElectronApp(user, force_employees_to_use_desktop_app);
    this.forceIfNecessaryToSetupDoNotDisturbShortcutsOnMac(user);
    this.checkIfNecessaryToDownloadElectronNewVersion(user, frontend_version_required);
  }

  isMeetingOngoing(project_id:number|null = null) {
    return project_id!=null ? this.meetingModeProjectId.value == project_id : this.meetingModeProjectId.value != null;
  }

  startMeetingMode(project_id:number) {
    localStorage.setItem(this.meetingModeLocalStorageKey, project_id.toString());
    this.meetingModeProjectId.next(project_id);
    this.platform.enableDoNotDisturbIfAvailable();
  }

  stopMeetingMode() {
    const current_ecr:CalendarRegisterLocalItem|undefined = this.currentTaskSubject.value && this.currentTaskSubject.value.current && this.currentTaskSubject.value.current.id ? this.currentTaskSubject.value.current : undefined;
    localStorage.removeItem(this.meetingModeLocalStorageKey);
    if(current_ecr && current_ecr.id && current_ecr.call_link && current_ecr.project_id && (+current_ecr.project_id) == this.meetingModeProjectId.value) {
      this.addEcrToMeetingsAlreadyStoppedFromMeetingMode(current_ecr.id);
    }
    this.meetingModeProjectId.next(null as any);
    this.platform.disableDoNotDisturbIfAvailable();
  }

  reloadTodaysTasks(user:User) {
    this.todaysTasks = [];
    this.currentTaskSubject.next(null as any);
    this.loadTodaysTasks(user);
  }

  logout() {
    this.stopMeetingMode();
    this.cleanMeetingsAlreadyStoppedFromMeetingMode();
  }

  private cleanMeetingsAlreadyStoppedFromMeetingMode() {
    localStorage.removeItem(this.meetingsAlreadyStoppedFromMeetingModeLocalStorageKey);
  }

  private addEcrToMeetingsAlreadyStoppedFromMeetingMode(ecr_id:number) {
    const ecr_ids = this.getEcrIdsOfMeetingsAlreadyStoppedFromMeetingMode();
    if(!this.isEcrIdOnMeetingsAlreadyStoppedFromMeetingMode(ecr_id)) {
      ecr_ids.push(ecr_id);
    }
    localStorage.setItem(this.meetingsAlreadyStoppedFromMeetingModeLocalStorageKey, JSON.stringify(ecr_ids));
  }

  private getEcrIdsOfMeetingsAlreadyStoppedFromMeetingMode(): number[] {
    const str = localStorage.getItem(this.meetingsAlreadyStoppedFromMeetingModeLocalStorageKey);
    let ecr_ids:number[] = [];
    if(str) ecr_ids = JSON.parse(str);
    return ecr_ids;
  }

  private isEcrIdOnMeetingsAlreadyStoppedFromMeetingMode(ecr_id:number) {
    const ecr_ids = this.getEcrIdsOfMeetingsAlreadyStoppedFromMeetingMode();
    return ecr_ids.indexOf(ecr_id) !== -1;
  }

  private initMeetingModeStatus() {
    const project_id = localStorage.getItem(this.meetingModeLocalStorageKey);
    if(project_id != null) {
      this.meetingModeProjectId.next(+project_id);
    }
    else {
      const current_ecr:CalendarRegisterLocalItem|undefined = this.currentTaskSubject.value.current;
      if(current_ecr && current_ecr.id && current_ecr.project_id && current_ecr.call_link && !this.isEcrIdOnMeetingsAlreadyStoppedFromMeetingMode(current_ecr.id)) {
        this.startMeetingMode(+current_ecr.project_id);
      }
    }
  }

  public getActiveProjects() {
    return this.active_projects;
  }

  public getCurrentTask(): any {
    return this.currentTaskSubject.value;
  }

  public forceIfNecessaryToDownloadElectronApp(user:User, force_employees_to_use_desktop_app:boolean) {
    if(user.role.slug === "worker" && !this.platform.isElectronApp() && this.platform.isDesktop() && environment.production && force_employees_to_use_desktop_app) {
      this.forceDownloadElectronAppSubject.next(true);
    }
  }

  public async recheckToForceSetupDoNotDisturbShortcutsDialog() {
    if(this.platform.isElectronApp()) {
      const ok:boolean = await window.electron.checkDoNotDisturbShortcuts();
      if(ok && this.forceSetupDoNotDisturbShortcutsDialogRef) {
        this.forceSetupDoNotDisturbShortcutsDialogRef.close();
      }
      else if(!ok) {
        alert("No se ha configurado aún.");
      }
    }
  }

  public clearDownloadElectronApp() {
    this.forceDownloadElectronAppSubject.next(false);
  }

  private async forceIfNecessaryToSetupDoNotDisturbShortcutsOnMac(user:User) {
    if(user.role.slug === "worker" && this.platform.isElectronApp()) {
      const ok:boolean = await window.electron.checkDoNotDisturbShortcuts();
      if(!ok) {
        this.forceSetupDoNotDisturbShortcutsDialogRef = this.dialog.open(ForceSetupDoNotDisturbShortcutsDialogComponent, {
          width: '600px',
          disableClose: true
        });
      }
      else if(this.forceSetupDoNotDisturbShortcutsDialogRef) {
        this.forceSetupDoNotDisturbShortcutsDialogRef.close();
      }
    }
  }

  private checkIfNecessaryToDownloadElectronNewVersion(user:User, setting:BisualSetting) {
    const version_required_from_date = moment(setting.updated_at);
    const version_required = setting.value.substring(1); // elimina la 'v'
    const current_version = environment.version;

    const needsUpgrade:boolean = this.needsUpgrade(current_version, version_required);

    if(this.platform.isElectronApp() && needsUpgrade) {
      let block:boolean = false;
      if(version_required_from_date.diff(moment(), 'days') > 1) {
        // bloquegem el dialog
        block = true;
      }

      this.dialog.open(DownloadNewVersionElectronAppDialogComponent, {
        data: {
          new_version: version_required,
          current_version: current_version,
          uploaded_at: version_required_from_date,
          block: block
        },
        width: '600px',
        disableClose: !block
      });
    }

  }

  private needsUpgrade(current:string, required:string) {
    // Función auxiliar para parsear la versión en sus componentes
    const parseVersion = (version:string) => {
        const [main, suffix] = version.split('-');
        const [major, minor, patch] = main.split('.').map(Number);
        return { major, minor, patch, suffix };
    };

    const currentParsed = parseVersion(current);
    const requiredParsed = parseVersion(required);

    // Comparar major, minor, patch en orden
    if (currentParsed.major < requiredParsed.major) return true;
    if (currentParsed.major > requiredParsed.major) return false;

    if (currentParsed.minor < requiredParsed.minor) return true;
    if (currentParsed.minor > requiredParsed.minor) return false;

    if (currentParsed.patch < requiredParsed.patch) return true;
    if (currentParsed.patch > requiredParsed.patch) return false;

    // Si las versiones principales son iguales, considerar el sufijo
    if (requiredParsed.suffix && !currentParsed.suffix) return true; // required es dev/beta, current es estable
    if (currentParsed.suffix && !requiredParsed.suffix) return false; // current es dev/beta, required es estable

    // Si ambos tienen sufijos, comparar lexicográficamente ("beta" > "dev")
    if (currentParsed.suffix && requiredParsed.suffix) {
        return currentParsed.suffix < requiredParsed.suffix;
    }

    return false;
  }

  private loadRequiredActions(user:User) {
    this.apiService.getMyActionsRequired().subscribe(
      data => {
        if(data['has_to_set_employee_calendar_registers'] != null && data['has_to_set_employee_calendar_registers'] === true) {
          this.openAssignationCalendar(user);
        }
      }
    );
  }

  private loadTodaysTasks(user:User) {
    let today = new Date();
    this.apiService.getEmployeeCalendarRegistersForUserSerialized(user.id.toString(), new Date(today.setHours(0,0,0)), new Date(today.setHours(23,59,59))).subscribe(
      (data: any) => {
        this.todaysTasks = data.map((item: EmployeeCalendarRegisterWithRelationsSerialized): CalendarRegisterLocalItem => {
          let title:string = "";
          if(item.project_id != null) {
            title = item.project_title + " - " + (item.custom_title ?? item.ecr_wtc_name);
            if(item.project_phase_name != null) title += " (" + item.project_phase_name + ")"
          }
          else title = item.wtc_name as string;

          return {
            id: item.id,
            title: title,
            start: new Date(item.from_date),
            end: new Date(item.to_date),
            color:  item.project_color ?? item.ecr_wtc_color ?? item.wtc_color ?? '#ad2121',
            call_link: item.call_link,
            project_id: item.project_id
          };
        }).sort((a:CalendarRegisterLocalItem,b:CalendarRegisterLocalItem) => a.start < b.start);

        this.updateCurrentTask();
      }
    );
  }

  private async updateCurrentTask() {
    // Inicialitzem
    let duoCurrentTasks = this.currentTaskSubject.value;
    if(duoCurrentTasks == null || duoCurrentTasks == undefined) { // si no han estat inicialitzats... Això mai hauria de passar
      duoCurrentTasks = { current: null as any, next: null as any };
    }

    if(this.todaysTasks.length > 0) {
      const current_task_index = this.getCurrentTaskIndex();
      if(current_task_index != null) { // Si està en una tasca actualment, obtenim tasca
        duoCurrentTasks.current = this.todaysTasks[current_task_index];
        const nextIndex = current_task_index + 1;
        duoCurrentTasks.next = nextIndex < this.todaysTasks.length ? this.todaysTasks[nextIndex] : null as any;
      }
      else if(this.isWorkingTimeNow()) { // Està en hora lliure, i després li vindrà una altra tasca
        duoCurrentTasks.current = {
          id: null as any,
          title: 'Hora libre',
          start: null as any,
          end: null as any,
          color: null as any,
          call_link: undefined,
          project_id: undefined
        };
        duoCurrentTasks.next = null as any;
      }
      else { // Si no, que no mostri res
        duoCurrentTasks = {
          current: null as any,
          next: null as any
        };
      }

    }

    this.currentTaskSubject.next(duoCurrentTasks);
    if(duoCurrentTasks.current && duoCurrentTasks.current.id && duoCurrentTasks.current.project_id && duoCurrentTasks.current.call_link && !this.isEcrIdOnMeetingsAlreadyStoppedFromMeetingMode(duoCurrentTasks.current.id)) {
      this.startMeetingMode(+duoCurrentTasks.current.project_id);
    }

    const sleep_ms = duoCurrentTasks.current!=null && duoCurrentTasks.current.end!=null ? Math.max(duoCurrentTasks.current.end.getTime() - new Date().getTime(), 1000) : 30000;
    await this.utils.sleep(Math.min(sleep_ms, 60000));

    this.updateCurrentTask();
  }

  private getCurrentTaskIndex() {
    const now = new Date();
    let i:number = 0;
    let trobat:boolean = false;
    let fi:boolean = false;
    let res_index:number = null as any;

    // Ordenem per data d'inici
    this.todaysTasks.sort((a: CalendarRegisterLocalItem, b: CalendarRegisterLocalItem) => {
      return a.start.getTime() - b.start.getTime();
    });

    while(!trobat && !fi) {
      if(this.todaysTasks[i].start <= now && this.todaysTasks[i].end > now) {
        trobat = true;
        res_index = i;
      }
      else if(this.todaysTasks[i].start > now) {
        trobat = true; // no és ben bé trobat... Vol dir que ens trobem en un buit entre tasques...
      }

      i++;
      fi = i>=this.todaysTasks.length;
    }

    return res_index;
  }

  private loadCurrentWorkingContract(user:User) {
    this.apiService.getActiveWorkingContract(user.id).subscribe(
      data => {
        this.currentWorkingContractActiveSubject.next(data);
        this.loadTodaysTasks(user);
      }
    );
  }

  private loadCurrentGuardianStatus() {
    this.apiService.getMyGuardianStatus().subscribe(
      data => {
        this.currentGuardStatusSubject.next(data);
      }
    );
  }

  private loadCurrentActiveProjects(id:number) {
    this.apiService.getUserProjectsActiveRoles(id).subscribe(
      data => {
        this.active_projects = data;
      }
    );
  }

  private isWorkingTimeNow() {
    const now = moment();
    const hour_with_decimal:number = now.hours() + (now.minutes()/60);

    type WorkingContractKey = keyof typeof this.currentWorkingContractActiveSubject.value;
    const morning_entry_time:WorkingContractKey = `${now.format('dddd').toLowerCase()}_morning_entry_time` as WorkingContractKey;
    const morning_exit_time:WorkingContractKey = `${now.format('dddd').toLowerCase()}_morning_exit_time` as WorkingContractKey;
    const afternoon_entry_time:WorkingContractKey = `${now.format('dddd').toLowerCase()}_afternoon_entry_time` as WorkingContractKey;
    const afternoon_exit_time:WorkingContractKey = `${now.format('dddd').toLowerCase()}_afternoon_exit_time` as WorkingContractKey;

    const wc = this.currentWorkingContractActiveSubject.value;

    return ( // si està entre les hores de mati
      wc[morning_entry_time]!=null && (wc[morning_entry_time] as any) <= hour_with_decimal &&
      wc[morning_exit_time]!=null && (wc[morning_exit_time] as any) > hour_with_decimal
    ) || ( // o bé si està entre les hores de tarda
      wc[afternoon_entry_time]!=null && (wc[afternoon_entry_time] as any) <= hour_with_decimal &&
      wc[afternoon_exit_time]!=null && (wc[afternoon_exit_time] as any) > hour_with_decimal
    );
  }

  private openAssignationCalendar(user:User) {
    const dialogRef = this.dialog.open(CalendarAssignmentDialogComponent, {
      width: '90%',
      disableClose: true,
      data: {
        from: moment().startOf('week').toDate(),
        to: moment().endOf('week').toDate(),
        user_id: user.id
      }
    });

  }

}

interface CalendarRegisterLocalItem {
  id:number;
  title:string;
  start:Date;
  end:Date;
  color:string;
  call_link:string|undefined;
  project_id:string|undefined;
}
