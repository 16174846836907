<div id="content" [formGroup]="filterForm">

  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="page-title">
          <h1>Resumen de trabajo</h1>

          <mat-form-field appearance="outline" style="width: 180px;">
            <span matPrefix><mat-icon>grid_view</mat-icon></span>
            <input [formControl]="projectAutocompleteControl" type="text" placeholder="Buscar proyecto..." matInput [matAutocomplete]="autoProject">
            <mat-autocomplete #autoProject="matAutocomplete" [displayWith]="displayFnProject" (optionSelected)="onProjectAutocompleteSelected($event)">
              @for (project of projectsFiltered; track project) {
                <mat-option [value]="project">{{project.title}}</mat-option>
              }
            </mat-autocomplete>
            @if (projectControl.value!=null) {
              <button matSuffix mat-icon-button (click)="resetProject()"><mat-icon>clear</mat-icon></button>
            }
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 180px;">
            <span matPrefix><mat-icon>person</mat-icon></span>
            <input [formControl]="userAutocompleteControl" type="text" placeholder="Buscar usuario..." matInput [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFnUser" (optionSelected)="onUserAutocompleteSelected($event)">
              @for (user of usersFiltered; track user) {
                <mat-option [value]="user">{{user.name + ' ' + user.surnames}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field appearance="outline" style="width: 100px;">
            <mat-label>Período</mat-label>
            <mat-select formControlName="period">
              @for (period of periods; track period) {
                <mat-option [value]="period">{{ period }}</mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>

  @if (chartData != null && chartData['resume'] != null) {
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-4">
          <div class="card">
            <h2>Trabajo total</h2>
            <p class="subtitle">Durante {{ filterForm.value['period'] }}</p>
            <p class="price">{{ (chartData['resume']['total_hours'] | number:'1.0-2') + 'h' }}</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card">
            <h2>Trabajo semanal</h2>
            <p class="subtitle">De media</p>
            <p class="price">{{ (chartData['resume']['weeks'] | number:'1.0-2') + 'h' }}</p>
          </div>
        </div>
        <div class="col-12 col-md-4">
          <div class="card">
            <h2>Horas extras</h2>
            <p class="subtitle">Horas extra contractuales realizadas</p>
            <p class="price">{{ (chartData['resume']['extra_hours'] | number:'1.0-2') + 'h' }}</p>
          </div>
        </div>
      </div>
    </div>
  }
  @if (chartData != null && chartData['days'] != null) {
    <div class="container" style="margin-bottom: unset;" #chartContainer>
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="card" [style.height]="(mainChartView[1]+40) + 'px'">
            <!-- <ngx-charts-line-chart
            [view]="mainChartView"
            [results]="chartData['days']"
            [gradient]="false"
            [scheme]="colorScheme"
            [xAxis]="true"
            [yAxis]="true"
            [legend]="false"
            [animations]="true">
          </ngx-charts-line-chart> -->
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <div class="card" [style.height]="(mainChartView[1]+40) + 'px'">
          <h2>Resumen diario</h2>
          <p class="subtitle">Comparación entre trabajo realizado y trabajo fichado</p>
          <div id="daily-stat">
            <div>
              <span class="value">{{ (chartData.today.done | number:'1.0-2') + 'h' }}</span>
              <span class="description">registradas</span>
            </div>
            <div>
              <span class="value">{{ (chartData.today.clock_registers | number:'1.0-2') + 'h' }}</span>
              <span class="description">fichadas</span>
            </div>
            <div>
              <p class="m-0"><b>{{ ((chartData.today.clock_registers-chartData.today.done)*3600 ) | secondsToStringTime }}</b> sin registrar.</p>
              <p>De <b>{{ (chartData.today.expected | number:'1.0-2') + 'h' }}</b> que deberías hacer hoy.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

@if (chartData!=null) {
  <div class="container">
    <div class="row">
      @if (chartData['categories']!=null) {
        <div class="col-12 col-md-6">
          <div class="card" style="overflow: auto;">
            <div>
              <h2>Categorías</h2>
              <!-- <ngx-charts-pie-chart
              [view]="pieChartsView"
              [results]="chartData['categories']"
              [gradient]="false"
              [scheme]="colorScheme"
              [legend]="true"
              [legendPosition]="pieChartsLegendPosition"
              legendTitle=""
              [labels]="false"
              [doughnut]="false">
            </ngx-charts-pie-chart> -->
          </div>
        </div>
      </div>
    }
    @if (chartData['projects']!=null) {
      <div class="col-12 col-md-6">
        <div class="card" style="overflow: auto;">
          <div>
            <h2>Proyectos</h2>
            <!-- <ngx-charts-pie-chart
            [view]="pieChartsView"
            [results]="chartData['projects']"
            [gradient]="false"
            [scheme]="colorScheme"
            [legend]="true"
            [legendPosition]="pieChartsLegendPosition"
            legendTitle=""
            [labels]="false"
            [doughnut]="false">
          </ngx-charts-pie-chart> -->
        </div>
      </div>
    </div>
  }
</div>
</div>
}

</div>
