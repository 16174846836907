@if (loading) {
  <mat-progress-bar id="main-progress-bar" mode="indeterminate" color="accent"></mat-progress-bar>
}

@if(employee.forceDownloadElectronApp | async) {
  <div id="forceDownloadElectronApp">
    <img width="200" src="assets/img/logo/logo-bisual-black.png" alt="bisual" style="margin-bottom: 50px;" />
    <p>Para seguir usando Bisual Intranet, debes descargar la app para escritorio.</p>

    <div style="display: flex; align-items: center;">
      <a mat-stroked-button [href]="desktopAppUrlDownload" target="_blank" color="accent">
        <mat-icon>download</mat-icon>
        Descargar App
      </a>

      @if(linkOnApp) {
        <a mat-flat-button [href]="linkOnApp" color="accent" style="margin-left: 10px;">
          <mat-icon>open_in_new</mat-icon>
          Abrir en la app
        </a>
      }

      <button mat-icon-button (click)="logout()" style="margin-left: 10px;" [matTooltip]="'Cerrar sesión'">
        <mat-icon>logout</mat-icon>
      </button>
    </div>
  </div>
}

<router-outlet></router-outlet>
