import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Computer } from 'src/app/models/computer.model';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { PermissionsService } from 'src/app/services/permissions.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';
import { CreditComputerDialogComponent } from './credit-computer-dialog/credit-computer-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-computers',
  templateUrl: './computers.component.html',
  styleUrl: './computers.component.css'
})
export class ComputersComponent implements OnInit {
  displayedColumns: string[] = ["model","buyed_at","chip","memory","active_user","actions"];
  dataSource:MatTableDataSource<Computer> = new MatTableDataSource<Computer>();

  activeWorkers:User[] = [];

  // dades per el paginator
  length:number = 0;
  pageSize:number = 10;
  pageSizeOptions:number[] = [5,10,20,50];
  pageIndex:number = 1;

  filterForm:UntypedFormGroup;
  private formPersistence:any;

  constructor(private fb:UntypedFormBuilder,
    private activatedRoute:ActivatedRoute,
    private api:ApiService,
    private router:Router,
    private utils:UtilsService,
    private dialog:MatDialog,
    public permissions:PermissionsService) {
      this.filterForm = this.fb.group({
        search: [this.activatedRoute.snapshot.queryParamMap.get('search')!=null ? this.activatedRoute.snapshot.queryParamMap.get('search') : '', Validators.compose([Validators.minLength(3)])],
        per_page: [this.activatedRoute.snapshot.queryParamMap.get('per_page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('per_page') as any) : this.pageSize, Validators.required],
        page: [this.activatedRoute.snapshot.queryParamMap.get('page')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('page') as any) : this.pageIndex, Validators.required],
        total: [this.activatedRoute.snapshot.queryParamMap.get('total')!=null ? +(this.activatedRoute.snapshot.queryParamMap.get('total') as any) : this.length],
      });
  }

  ngOnInit(): void {
    this.fetchWorkers();
    this.initFilterFormListener();
    this.listenQueryParameters();
  }

  changePage(event:PageEvent) {
    this.filterForm.patchValue({
      page: event.pageIndex,
      per_page: event.pageSize
    });
  }

  onOwnerChange(idx:number, user_id:number|undefined) {
    if(user_id != null) {
      this.assignNewOwner(idx, user_id);
    }
    else {
      this.dettachOwner(idx);
    }
  }

  navigateToHolded(computer:Computer) {
    window.open(`https://app.holded.com/accounting/assets/${computer.holded_asset_id}`, '_blank');
  }

  openDialog(computer:Computer|undefined = undefined) {
    const dialogRef = this.dialog.open(CreditComputerDialogComponent, {
      data: { computer: computer },
      width: '700px'
    });

    dialogRef.afterClosed().subscribe(
      data => {
        if(data != undefined) {
          this.fetchData();
        }
      }
    );
  }

  private dettachOwner(idx:number) {
    if(
      this.dataSource.data[idx].active_user != undefined &&
      confirm("¿Estás seguro que quieres desasignar este usuario de este ordenador?")
    ) {
      this.dataSource.data[idx].active_user = undefined;
      this.api.dettachUserFromComputer(this.dataSource.data[idx].id).subscribe(
        data => {

        }
      );
    }
  }

  private assignNewOwner(idx:number, user_id:number) {
    if(
      (this.dataSource.data[idx].active_user == undefined || this.dataSource.data[idx].active_user?.id != user_id) &&
      confirm("¿Estás seguro que quieres cambiar el que usará este portátil a partir de ahora?")
    ) {

      const user:User = this.activeWorkers.find((u:User) => u.id == user_id) as User;
      this.dataSource.data[idx].active_user = user;

      this.api.attachUserToComputer(this.dataSource.data[idx].id, user.id).subscribe(
        data => {}
      );
    }
  }

  private fetchData() {
    const body:any = {...this.filterForm.value, ...{ append: 'active_user', order_by: 'buyed_at', order_by_direction: 'desc' }}
    this.api.getComputers(body).subscribe(
      data => {
        this.dataSource.data = data.data;
        this.length = +data.meta.total;
        this.pageIndex = +data.meta.current_page-1;
        this.pageSize = +data.meta.per_page;
      }
    );
  }

  private initFilterFormListener() {
    this.filterForm.valueChanges.subscribe(
      data => {
        data.per_page = +data.per_page;
        if(this.formPersistence==null || JSON.stringify(this.formPersistence)!=JSON.stringify(data)) {
          if(this.formPersistence.per_page!=data.per_page) data.page = 0;
          this.router.navigate(['/employees', 'rrhh', 'computers'], { queryParams: data });
        }
      }
    );
  }

  private listenQueryParameters() {
    this.activatedRoute.queryParams.subscribe(
      params => {
        if(JSON.stringify(params)!==JSON.stringify(this.filterForm.value)) { //si no ve de filtre s'ha de setejar el form
          let params_temp = this.utils.cloneObj(params); //params es read_only
          Object.keys(params_temp).forEach(param_key => {
            if(params_temp[param_key]!=null && params_temp[param_key]!="" && !isNaN(+params_temp[param_key])) params_temp[param_key] = +params_temp[param_key]; // si es numero, el transformem
          });
          this.filterForm.patchValue(params_temp, { emitEvent: false });
        }
        this.formPersistence = params;
        this.fetchData();
      }
    );
  }

  private fetchWorkers() {
    this.api.getActiveEmployees().subscribe(
      data => {
        this.activeWorkers = data;
      }
    );
  }

}
