<h1 mat-dialog-title>Configura el Modo No Molestar</h1>

<mat-dialog-content>
  <p>
    Debes configurar tu Mac para tenerlo preparado para cuando hagas reuniones y compartas pantalla. Queremos evitar que se vean las notificaciones y otros proyectos mientras estás en una reunión o sprint review con clientes. Por favor sigue los siguientes pasos: <br>
  </p>

  <mat-stepper orientation="vertical" #stepper [linear]="true">
    <mat-step>
      <ng-template matStepLabel>Abre la aplicación <b>Atajos</b> en tu Mac</ng-template>
      <p>Abre la aplicación de Atajos.</p>
      <img src="assets/img/dnd/dnd_mode_setup_open_app.png" alt="Abrir aplicación atajos" />
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Añade dos nuevos Atajo</ng-template>
      <p>Deberás crear 2 atajos: Uno con nombre "Activar No Molestar" y otro "Desactivar No Molestar".</p>
      <img src="assets/img/dnd/dnd_mode_setup_empty.png" alt="Añadir nuevo atajo" />
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Configura el Atajo</ng-template>
      <p>Por cada uno de los 2 atajos que crees, haz lo siguiente:</p>
      <img src="assets/img/dnd/dnd_mode_setup_add_1.png" alt="Añadir nuevo atajo" />
      <img src="assets/img/dnd/dnd_mode_setup_add_2.png" alt="Añadir nuevo atajo" />
    </mat-step>

    <mat-step>
      <ng-template matStepLabel>Finaliza</ng-template>
      <p>Debería quedarte así:</p>
      <img src="assets/img/dnd/dnd_mode_setup_completed.png" alt="Configuración atajos" />
    </mat-step>

  </mat-stepper>
</mat-dialog-content>

<mat-dialog-actions align="end">
  @if(selectedIndex > 0) {
    <button mat-stroked-button color="accent" (click)="previous()">Ir atrás</button>
  }

  @if(selectedIndex < 3) {
    <button mat-stroked-button color="accent" (click)="next()">Siguiente paso</button>
  }
  @else {
    <button mat-flat-button color="accent" (click)="recheck()">Volver a comprobar</button>
  }
</mat-dialog-actions>
