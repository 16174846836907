import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as moment from 'moment';
import { AddNewWorkingTimeDialogComponent } from 'src/app/components/main/employees/my-zone/working-times-history/add-new-working-time-dialog/add-new-working-time-dialog.component';
import { User } from 'src/app/models/user.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { UtilsService } from 'src/app/services/utils.service';

@Component({
  selector: 'app-project-report-dialog',
  templateUrl: './project-report-dialog.component.html',
  styleUrls: ['./project-report-dialog.component.css'],
})
export class ProjectReportDialogComponent implements OnInit {
  form: UntypedFormGroup;
  users: User[] = [];

  constructor(
    public dialogRef: MatDialogRef<AddNewWorkingTimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private user: UserService,
    private fb: UntypedFormBuilder,
    private api: ApiService,
    private utils: UtilsService,
  ) {
    this.form = this.fb.group({
      usersSelected: [''],
      from: [moment().subtract(1, 'month').startOf('month').toDate()],
      to: [moment().subtract(1, 'month').endOf('month').toDate()],
      project_id: [this.data.project.id],
      use_time_invested: [false],
      multiplier: [null],
    });
  }

  ngOnInit(): void {
    this.fetchWorkers();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fetchWorkers() {
    const params: any = { role_id: 1 };
    this.api.getUsers(params).subscribe((users) => {
      this.users = users.data;
    });
  }

  onSubmitCsv() {
    var datePipe = new DatePipe('es-ES');
    this.form.patchValue({
      from: datePipe.transform(this.form.value.from, "yyyy-MM-dd"),
      to: datePipe.transform(this.form.value.to, "yyyy-MM-dd"),
    });
    this.api.getWorkingTimeCSV(this.form.value).subscribe((data) => {
      this.utils.downloadCsvFromBlobResponse(data);
      this.dialogRef.close();
    });
  }

  onSubmitPdf() {
    var datePipe = new DatePipe('es-ES');
    this.api.getWorkingTimeReportBusinessPartnerPdf(this.form.value['project_id'], datePipe.transform(this.form.value.from, "yyyy-MM-dd") as string, datePipe.transform(this.form.value.to, "yyyy-MM-dd") as string, this.form.value.multiplier, this.form.value.use_time_invested).subscribe(
      data => {
        this.utils.downloadPdfFromBlobResponse(data)
      }
    )
  }
}
