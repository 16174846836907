import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'departmentCategoryToColorPipe'
})
export class DepartmentCategoryToColorPipe implements PipeTransform {

  transform(dc:string|null|undefined): string {
    switch (dc) {
      case 'assistant-manager':
        return '#3498db';
      case 'cco':
        return '#3498db';
      case 'ceo':
        return '#3498db';
      case 'cfo':
        return '#3498db';
      case 'chro':
        return '#3498db';
      case 'cmo':
        return '#3498db';
      case 'coo':
        return '#3498db';
      case 'cso':
        return '#3498db';
      case 'cto':
        return '#3498db';
      case 'junior-developer-a':
        return '#3498db';
      case 'junior-developer-b':
        return '#3498db';
      case 'junior-developer-c':
        return '#3498db';
      case 'mid-developer-a':
        return '#3498db';
      case 'mid-developer-b':
        return '#3498db';
      case 'mid-developer-c':
        return '#3498db';
      case 'prcticas':
        return '#3498db';
      case 'product-designer-a':
        return '#8e44ad';
      case 'product-designer-b':
        return '#8e44ad';
      case 'product-designer-c':
        return '#8e44ad';
      case 'product-manager-a':
        return '#3498db';
      case 'product-manager-b':
        return '#3498db';
      case 'product-manager-c':
        return '#3498db';
      case 'tech-lead-a':
        return '#3498db';
      case 'tech-lead-b':
        return '#3498db';
      case 'tech-lead-c':
        return '#3498db';
      case 'senior-developer-a':
        return '#3498db';
      case 'senior-developer-b':
        return '#3498db';
      case 'senior-developer-c':
        return '#3498db';
      case 'student-developer':
        return '#3498db';
      default:
        return '#C1C1C1';
    }

  }
}
