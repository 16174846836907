import { Component } from '@angular/core';
import { UtilsService } from './services/utils.service';

import * as moment from 'node_modules/moment';
import 'moment/min/locales';
import { PlatformService } from './services/platform.service';
import { EmployeeService } from './services/employee.service';
import { AuthService } from './services/auth.service';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { environment } from 'src/environments/environment';
import { BisualSettingService } from './services/bisual-setting.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'bisual-workers';
  public loading:boolean = false;
  public desktopAppUrlDownload:string;
  public linkOnApp:string|undefined;

  constructor(private utils:UtilsService,
              private platform:PlatformService,
              public employee:EmployeeService,
              private auth:AuthService,
              private router:Router,
              private bisual_setting:BisualSettingService,
              private activatedRoute:ActivatedRoute
  ) {
    this.startListeningLoadingStatus();
    this.initMomentLocale();
    this.initDesktopPermissions();
    this.listenRouterEvents();
    this.listenBisualSettings();
  }

  logout() {
    this.auth.logout();
    this.router.navigate(['/auth']);
  }

  private startListeningLoadingStatus() {
    this.utils.loadingObservable.subscribe(
      data => {
        this.loading = data;
      }
    );
  }

  private initMomentLocale() {
    moment().locale('es');
  }

  private initDesktopPermissions() {
    this.platform.requestAccessibilityPermissions();
  }

  private listenRouterEvents() {
    this.activatedRoute.url.subscribe(
      (data:UrlSegment[]) => {
        let url:string = "bisualintranet://";
        data.forEach(segment => {
          url += "/" + segment.path
        });
        this.linkOnApp = url;
      }
    );
  }

  private listenBisualSettings() {
    this.bisual_setting.bisualSettingObservable.subscribe(
      data => {
        if(data) {
          const version = this.bisual_setting.get('frontend_version_required');
          this.desktopAppUrlDownload = `${environment.laravel_url}/storage/intranet/dist_electron/BisualIntranetApp-${version.value}-mac.zip`;
        }
      }
    );
  }
}
