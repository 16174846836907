import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexNonAxisChartSeries, ApexOptions, ApexPlotOptions, ApexResponsive, ApexStroke, ApexTitleSubtitle, ApexTooltip, ApexXAxis, ChartComponent } from 'ng-apexcharts';
import { WorkingContract } from 'src/app/models/working_contract.model';
import { ApiService } from 'src/app/services/api.service';
import { UserService } from 'src/app/services/user.service';
import { HtmlDialogComponent } from '../../../shared/html-dialog/html-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { PermissionsService } from 'src/app/services/permissions.service';
import * as moment from 'moment';
import { WorkingContractCareerPlanYear } from 'src/app/models/working_contract_career_plan_year.model';

interface CustomApexOptions extends ApexOptions {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  labels: string[];
  colors: any[];
  plotOptions: ApexPlotOptions;
  dataLabels: ApexDataLabels;
  tooltip: ApexTooltip;
}

@Component({
  selector: 'app-my-payrolls',
  templateUrl: './my-payrolls.component.html',
  styleUrls: ['./my-payrolls.component.css']
})
export class MyPayrollsComponent implements OnInit {

  contract:WorkingContract = null as any;
  @ViewChild("chart") chart: ChartComponent;
  paqueteRetributivoChartOptions: CustomApexOptions;
  next_upgrade:undefined|{days_remaining:number; salary:number; health_insurance:boolean; ret_flexible:number; date:Date; category_name:string; };

  constructor(private api:ApiService,
              private user:UserService,
              private dialog:MatDialog,
              private sanitizer:DomSanitizer,
              private currency:CurrencyPipe,
              private permissions:PermissionsService) { }

  ngOnInit(): void {
    this.fetchActiveContract();
    this.fetchActiveContractNextSalarialUpgrade();
  }

  seeHealthInsuranceCard() {
    this.dialog.open(HtmlDialogComponent, {
      width: '600px',
      data: {
        dialog_close_button: 'Cerrar',
        title: 'Mi tarjeta de póliza',
        html: this.sanitizer.bypassSecurityTrustHtml(`
          <p>
            Frontal:
            <img src="${this.contract.current_working_contract_variable_condition.health_insurance_contract.active_health_insurance_contract_card.front_card_image_url}" style="display: block; width: 100%;" />
          </p>
          <p>
            Trasera:
            <img src="${this.contract.current_working_contract_variable_condition.health_insurance_contract.active_health_insurance_contract_card.back_card_image_url}" style="display: block; width: 100%;" />
          </p>
        `)
      }
    });
  }

  openRetribucionFlexible() {
    this.dialog.open(HtmlDialogComponent, {
      width: '600px',
      data: {
        dialog_close_button: 'Cerrar',
        title: '¿Como usar Payflow?',
        html: this.sanitizer.bypassSecurityTrustHtml(`
          <p>
            <b>¿Cómo funciona la tarjeta de comida para restaurantes?</b><br>
            Recibirás una tarjeta que podrás utilizar en restaurantes y cafeterías, con un saldo asignado para gastar cada mes. Este saldo estará disponible desde el día 1 hasta el día 27 de cada mes.
          </p>
          <p>
            <b>¿Cómo se recarga el saldo?</b><br>
            El saldo se recargará diariamente con un máximo de 18 euros por día, hasta que alcances el límite mensual que te corresponda. Veamos un ejemplo:
          </p>
          <p>
            <b>Si tu límite mensual es de 50 €:</b><br>
            <ul>
              <li><b>Día 1</b>: No recibirás saldo.</li>
              <li><b>Día 2</b>: Se te añadirán 18 €.</li>
              <li><b>Día 3</b>: Se te añadirán otros 18 €, acumulando un total de 36 €.</li>
              <li><b>Día 4</b>: Se te añadirán 14 €, alcanzando tu límite de 50 €.</li>
              <li><b>Día 5 y posteriores</b>: No recibirás más saldo, ya que habrás alcanzado el tope del mes.</li>
            </ul>
          </p>
          <p>
            <b>¿Qué sucede si no gastas todo el saldo?</b><br>
            El saldo que no utilices en la tarjeta de comida no se perderá. Cualquier cantidad que quede sin gastar al final del mes se sumará a tu nómina como un "extra". Sin embargo, este importe adicional tributará como parte de tu salario en el IRPF, lo que significa que estará sujeto a las retenciones fiscales correspondientes.
          </p>
        `)
      }
    });
  }

  private fetchActiveContract() {
    this.api.getActiveWorkingContract(this.user.getCurrentUser().id, { refresh_cache: true, with: 'current_working_contract_variable_condition..health_insurance_contract..health_insurance_plan..health_insurance_company,current_working_contract_variable_condition..health_insurance_contract..active_health_insurance_contract_variable_condition,current_working_contract_variable_condition..health_insurance_contract..active_health_insurance_contract_card,current_working_contract_variable_condition..department_category' }).subscribe(
      data => {
        this.contract = data;
        this.mountChart();
      }
    );
  }

  private mountChart() {
    const self = this;
    const series = [
      // Salario base
      this.contract.current_working_contract_variable_condition.brute_anual_base_salary/12,
    ];

    const labels = ["Salario base"];
    const colors = ['#008FFB'];

    // Plus Antigüedad
    if(this.contract.current_working_contract_variable_condition.current_triennium_anual_salary > 0) {
      series.push(this.contract.current_working_contract_variable_condition.current_triennium_anual_salary/12)
      labels.push('Plus Antigüedad');
      colors.push('#E7E3D6');
    }

    // Seguro médico
    if(this.contract.current_working_contract_variable_condition.health_insurance_contract != null) {
      series.push(this.contract.current_working_contract_variable_condition.health_insurance_contract.active_health_insurance_contract_variable_condition.monthly_price);
      labels.push("Seguro médico");
      colors.push('#D62787')
    }

    // Tarjeta Payflow
    if(this.contract.current_working_contract_variable_condition.flexible_remuneration_monthly_quantity != null && this.contract.current_working_contract_variable_condition.flexible_remuneration_monthly_quantity > 0) {
      series.push(this.contract.current_working_contract_variable_condition.flexible_remuneration_monthly_quantity);
      labels.push("Tarjeta Payflow");
      colors.push('#00E396');
    }

    const sum = series.reduce((prev,curr) => prev+curr);

    this.paqueteRetributivoChartOptions = {
      series: series, // Representación porcentual de cada parte
      chart: {
        type: "donut", // Tipo de gráfico de pastel
        width: '300px', // Ancho en porcentaje, ajustará el gráfico al 80% del contenedor
      },
      labels: labels, // Las etiquetas del gráfico
      colors: colors, // Colores para cada parte
      plotOptions: {
        pie: {
          donut: {
            size: '70%',
            labels: {
              show: true,
              total: {
                show: true,
                label: 'Total',
                formatter: function (w) {
                  return self.currency.transform(sum) as string;
                },
                fontSize: '12px',
                fontWeight: 'bold',
                showAlways: true
              }
            }
          }
        }
      },
      dataLabels: {
        enabled: false // Desactiva la visualización de los porcentajes
      },
      tooltip: {
        y: {
          formatter: function (val, opts) {
            return self.currency.transform(val) as string;
          }
        }
      },
    };
  }

  private fetchActiveContractNextSalarialUpgrade() {
    this.api.getActiveWorkingContractNextSalarialUpgrade(this.user.getCurrentUser().id, { with: '' }).subscribe(
      data => {
        if(data.data) {
          this.next_upgrade = {
            date: data.data.start_date,
            days_remaining: -moment().diff(moment(data.data.start_date), 'days'),
            salary: data.data.brute_anual_base_salary + data.data.current_triennium_anual_salary,
            health_insurance: data.data.health_insurance_contract_id != null,
            ret_flexible: data.data.flexible_remuneration_monthly_quantity ?? 0,
            category_name: data.data.department_category.name
          }
        }
      }
    );
  }

}
